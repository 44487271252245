import React from 'react';
import './index.scss';

const Help = () => (
  <div id="terms-content-wrap-text">
    <div className="terms-subtitle-text">HELP:</div>

    <div className="terms-text-block">
      <div className="terms-first-text help-page-text">
        If you have issues or questions regarding the usage of LARSA,
        you can contact the Lelystad Airport Operations Department:
        </div>
      <div className="terms-first-text help-page-text">
        e-mail: <a href="mailto:operations@lelystad-airport.nl" data-rel="external" className="terms-second-text help-page-second-text">operations@lelystad-airport.nl</a>
      </div>
      <div className="terms-first-text help-page-text">
        Phone:  <a href="tel:+31 320 284782" data-rel="external" className="terms-second-text help-page-second-text">+31 320 284782</a>
        <span > (between 07:00 and 22:00 local time)</span>
      </div>
    </div>
  </div>
);

export default Help;
