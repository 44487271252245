import React from 'react';

import './index.scss';

const Input = ({ className = '', fullWidth = false, ...props }) => (
  <input
    className={`custom-input ${fullWidth ? 'full-width' : ''} ${className}`}
    {...props}
  />
);

export default Input;
