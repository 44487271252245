import React, { Component } from 'react';
import moment from 'moment'

import Grid from '@material-ui/core/Grid';
import ErrorPopup from '../../../components/common/modals/ErrorPopup'
import Button from '../../../components/common/Button';
import Reservations from '../../../components/common/Reservations';
import Calendar from '../../../components/Calendar'
import reservationsService from '../../../services/reservations'
import Modal from '../../../components/common/Modal'
import ReservationsInfo from './ReservationsInfo';

import './index.scss'

class Dashboard extends Component {
  state = {
    selectedDay: null,
    reservations: [],
    isConfirmModalShow: false,
    lastEvaluatedKey: null,
    reservationsCount: null,
    isLoading: true,
    isReservationsLoading: true,
    order: null,
    statuses: [],
    types: [],
    bookingAsc: true
  }

  componentDidMount() {
    this.fetchReservations(moment())
    this.getReservationsCount(moment())
    this.setState({ selectedDay: moment() })
  }

  getReservationsCount = async (day) => {
    const { result } = await reservationsService.countsOfReservations(moment(day).format('YYYY-MM-DD'))
    this.setState({ reservationsCount: result, isLoading: false })
    console.log('result', result)
  }

  fetchReservations = async (day) => {
    const { order, statuses, types, bookingAsc } = this.state
    try {
      const { result } = await reservationsService.getDayReservations(moment(day).format('YYYY-MM-DD'), { order, statuses, types, bookingAsc })
      this.setState({ reservations: result.reservations, lastEvaluatedKey: result.last_evaluated_key, isLoading: false, isReservationsLoading: false })

    } catch (error) {
      console.log(error)
      this.setState({ isConfirmModalShow: true })
    }
  }

  loadMoreReservations = async () => {
    const { selectedDay, reservations, lastEvaluatedKey, order, statuses, types, bookingAsc } = this.state

    try {
      const { result } = await reservationsService.getDayReservations(moment(selectedDay).format('YYYY-MM-DD'), { lastEvaluatedKey, order, statuses, types, bookingAsc })
      this.setState({ reservations: [...reservations, ...result.reservations], lastEvaluatedKey: result.last_evaluated_key })

    } catch (error) {
      console.log(error)
      this.setState({ isConfirmModalShow: true })
    }
  }

  onDayClick = async (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({ selectedDay: day, statuses: [], types: [], order: null, isLoading: true, isReservationsLoading: true }, () => this.fetchReservations(day))

    this.getReservationsCount(day)
  }

  closeModal = () => {
    this.setState({ isConfirmModalShow: false })
  }

  onStatusChange = async (statuses) => {
    const { selectedDay } = this.state

    this.setState({ isReservationsLoading: true, statuses }, () => this.fetchReservations(selectedDay))
  }

  onTimeChange = async (item) => {
    const { selectedDay } = this.state

    this.setState({ isReservationsLoading: true, order: item }, () => this.fetchReservations(selectedDay))
  }

  onTypeChange = async (types) => {
    const { selectedDay } = this.state

    this.setState({ isReservationsLoading: true, types }, () => this.fetchReservations(selectedDay))
  }

  render() {
    const {
      onDayClick,
      closeModal,
      loadMoreReservations,
      onStatusChange,
      onTypeChange,
      onTimeChange,
      state: {
        selectedDay,
        isLoading,
        reservations,
        isConfirmModalShow,
        lastEvaluatedKey,
        reservationsCount,
        isReservationsLoading
      }
    } = this;

    const handleCloseRunway = () => {
      console.log('handleCloseRunway')
    };

    return (
      <div className="dashboard-container">
        <Grid container spacing={8}>
          <Grid item xs={12} lg={8}>
            <div className="dashboard-overview">
              <div className="overview-heading">
                <p className="text">OVERVIEW:</p>
                <p className="date">
                  {
                    moment(selectedDay)
                      .format('D MMMM YYYY')
                      .toUpperCase()
                  }
                </p>
              </div>

              <Button
                invert
                onClick={handleCloseRunway}
              >
                CLOSE RUNWAY
              </Button>
            </div>

            <div className="calendar-container">
              <Calendar handleDayClick={onDayClick} selectedDay={selectedDay} />
            </div>

            {reservationsCount !== null && (
              <Grid item md={9}>
                <ReservationsInfo isLoading={isLoading} data={reservationsCount} />
              </Grid>
            )
            }
          </Grid>

          {isConfirmModalShow && (

            <Modal
              withCloseBtn={false}
              onClose={() => closeModal()}
            >
              <div className="popup-wrapper" >
                <ErrorPopup click={() => onDayClick(selectedDay)} />
              </div>
            </Modal>

          )
          }

          <Grid item xs={12} lg={4}>
            <Reservations
              withBookingTimes={true}
              onStatusChange={onStatusChange}
              onTypeChange={onTypeChange}
              onTimeChange={onTimeChange}
              fromAdmin={true}
              isLoading={isReservationsLoading}
              astEvaluatedKey={lastEvaluatedKey}
              loadMore={loadMoreReservations}
              reservations={reservations}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
