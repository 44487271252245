import { combineReducers } from 'redux';

import account from './account';
import main from './main'
import modal from './modal'
import reservation from './reservation'

const rootReducer = combineReducers({
  account,
  main,
  modal,
  reservation
});

export default rootReducer;
