import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import classnames from 'classnames'

import { Grid } from '@material-ui/core';

import TimePicker from '../../TimePicker';
import Loader from '../../Loader';

import ArrowNumber from '../ArrowNumber';
import reservationActions from '../../../../redux/actions/reservation';

import reservationService from '../../../../services/reservations';

import { TYPES, getLandingAreaId, closeOperations, closeTitlesTabs } from '../../../../helpers/reservations';
import { isBetween } from '../../../../helpers/common';

import { getUserRoleFromCookies } from '../../../../helpers/auth';

import './index.scss';

class ChoseTime extends Component {
  state = {
    userRole: null,
    time: null
  }

  componentDidMount() {
    const userRole = getUserRoleFromCookies()

    this.setState({ userRole }, () => this.handleTimeSelect('06:00'))

    const {
      props: {
        getUtilizationData,
        closeRunway,
        selectedAreas,
        closeWholeDayVal,
        setSelectedCloseTab
      }
    } = this;

    if (!closeRunway) {
      getUtilizationData();

    } else if (!closeWholeDayVal) {
      const landingId = selectedAreas.map(area => {
        if (area === "close_helex") return getLandingAreaId("helex");
        if (area === "close_fato") return getLandingAreaId("fato");

        return getLandingAreaId(area);
      })[0];

      setSelectedCloseTab({
        title: closeTitlesTabs[selectedAreas[0]],
        landingId
      })

      getUtilizationData(landingId);

    }
  }

  handleTimeSelect = time => {
    const {
      props: {
        utilization,
        customDuration,
        selectedOperation,
        setSelectedTime,
        closeRunway,
        setSelectedCloseTime,
        closeDuration,
        selectedCloseOperationTab,
        setAverageLevel,
      }
    } = this;
    this.setState({ time })

    let duration = selectedOperation !== null && [TYPES.helex, TYPES.fato].includes(selectedOperation.id)
      ? customDuration * 60
      : utilization.operation_duration;

    if (closeRunway) {
      duration = [TYPES.helex, TYPES.fato].includes(selectedCloseOperationTab.title.toLowerCase())
        ? closeDuration[selectedCloseOperationTab.title]
        : utilization.operation_duration;
    }

    const firstIndex = utilization.timeline.findIndex(t => t.slice(0, 5) === time);

    const durationIndexesAmount = Math.ceil(duration / utilization.time_step);

    const lastIndex = firstIndex + durationIndexesAmount;

    const averageLevel = utilization.utilization
      .slice(firstIndex, lastIndex)
      .reduce((sum, value) => sum + value, 0) / (lastIndex - firstIndex);

    setAverageLevel(averageLevel);

    if (closeRunway) {
      setSelectedCloseTime({ [selectedCloseOperationTab.title]: time });

    } else {
      setSelectedTime(time);

    }
  }

  handleSelectTab = (tab) => {
    const { getUtilizationData, setSelectedCloseTab } = this.props

    setSelectedCloseTab(tab)

    getUtilizationData(tab.landingId)
  }


  render() {
    const {
      handleTimeSelect,
      handleSelectTab,
      state: {
        userRole
      },
      props: {
        selectedDay,
        overlapDisabled,
        selectedOperation,
        customDuration,
        utilization,
        selectedTime,
        closeRunway,
        averageLevel,
        closeDuration,
        isUtilizationLoading,
        selectedCloseTime,
        decreaseCloseDuration,
        selectedCloseOperationTab,
        increaseCloseDuration,
        selectedAreas,
        previousStep
      }
    } = this;

    let utilizationDuration = utilization.operation_duration;

    if (closeRunway) {
      utilizationDuration = closeDuration[selectedCloseOperationTab.title] * 3600

    } else {
      if (selectedOperation !== null && [TYPES.fato, TYPES.helex].includes(selectedOperation.id)) {
        utilizationDuration = customDuration * 60;
      }
    }

    let vLabel = utilization.value_labels.find(vl => isBetween(vl.from, vl.to)(averageLevel));
    let label = overlapDisabled ? utilization.value_labels.find(vl => vl.on_maintenance).on_maintenance : (vLabel ? vLabel.long_label : '');

    const closeOperationsTabs = selectedAreas.map(area => {
      if (area === "close_helex") return {
        title: 'HELEX',
        landingId: getLandingAreaId("helex")
      };
      if (area === "close_fato") return {
        title: 'FATO',
        landingId: getLandingAreaId("fato")
      };

      return { title: 'RUNWAY', landingId: getLandingAreaId(area) };
    })


    return (
      <div id="chose-day-container">
        <div className="operations-body">
          <div className="operation-title-block">
            <div className="operation-title chose-day-title">
              <div className="back-arrow" onClick={previousStep}></div>
              <div className="chose-day-title-text">Choose your start time!</div>
            </div>
            <div className="chose-day-subtitle"><h3>UTC time (ZULU time)</h3></div>
          </div>
        </div>

        {
          isUtilizationLoading ? (<Loader />) : (
            <>
              <div className="picker-container">
                <TimePicker
                  times={utilization.timeline}
                  levels={utilization.utilization}
                  timeStep={utilization.time_step}
                  duration={utilizationDuration}
                  disabledTimes={utilization.maintenance_times}
                  onTimeSelect={handleTimeSelect}
                  customDuration={customDuration}
                  selectedDay={selectedDay}
                  utilization={utilization}
                  selectedOperation={selectedOperation}
                />
              </div>

              {userRole === 'airport' && (
                <Grid container item xs={12} justify="center" className="chose-day-info-block">

                  <div className="close-operations-tabs">
                    {
                      selectedCloseOperationTab && closeOperationsTabs.map((o, index) => (<p key={index} className={classnames({ 'close-operations-tab': true, selected: o.title === selectedCloseOperationTab.title })} onClick={() => closeOperationsTabs.length > 1 && handleSelectTab(o)}>{o.title}</p>))
                    }
                  </div>
                </Grid>

              )}

              <Grid container item xs={12} justify="center" className="chose-day-info-block">
                <ul>
                  {
                    userRole === 'pilot' && !!label && (
                      <li className="text">{label}</li>
                    )
                  }
                  <li className="text">Operation: {(selectedOperation && selectedOperation.text) || (closeRunway ? selectedAreas.map(a => closeOperations[a]).join(', ') : '')}</li>
                  <li className="text">Day: {moment(selectedDay).format('MMMM Do YYYY')}</li>

                  {
                    closeRunway && !selectedOperation && (
                      <ArrowNumber
                        readOnly
                        text="DURATION OF OPERATION (hours):"
                        min={0.5}
                        max={24}
                        value={closeRunway && closeDuration[selectedCloseOperationTab.title]}
                        decrease={decreaseCloseDuration}
                        increase={increaseCloseDuration}
                      />
                    )
                  }

                  {
                    selectedTime && (
                      <li className="text-bold"> Selected start time: {!closeRunway ? selectedTime : selectedCloseTime[selectedCloseOperationTab.title]}</li>
                    )
                  }
                </ul>
              </Grid>
            </>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({
  reservation: {
    selectedOperation,
    selectedDay,
    numberOfTouchAndGo,
    customDuration,
    customCloseDuration,
    utilization,
    selectedTime,
    closeDuration,
    closeWholeDayVal,
    averageLevel,
    selectedAreas,
    selectedCloseTime,
    closeRunway,
    selectedCloseOperationTab,
    isUtilizationLoading
  }
}) => ({
  selectedOperation,
  selectedDay,
  numberOfTouchAndGo,
  customDuration,
  customCloseDuration,
  closeDuration,
  utilization,
  closeWholeDayVal,
  selectedCloseTime,
  selectedTime,
  selectedCloseOperationTab,
  selectedAreas,
  averageLevel,
  closeRunway,
  isUtilizationLoading
});

const mapDispatchToProps = dispatch => ({
  previousStep: () => dispatch(reservationActions.previousStep()),
  getUtilizationData: (...props) => dispatch(reservationService.getUtilizationData(...props)),
  setSelectedTime: time => dispatch(reservationActions.setSelectedTime(time)),
  setAverageLevel: level => dispatch(reservationActions.setAverageLevel(level)),
  setActiveStep: step => dispatch(reservationActions.setActiveStep(step)),
  setSelectedCloseTab: selectedTab => dispatch(reservationActions.setSelectedCloseTab(selectedTab)),
  setSelectedCloseTime: obj => dispatch(reservationActions.setSelectedCloseTime(obj)),
  decreaseCloseDuration: () => dispatch(reservationActions.decreaseCloseDuration()),
  increaseCloseDuration: () => dispatch(reservationActions.increaseCloseDuration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoseTime);