const shuffle = array => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const isBetween = (start, end) => v => (v >= start && v <= end);

export {
  shuffle,
  isBetween
};

export default {
  shuffle,
  isBetween
};
