import React, { Component } from 'react';
import queryString from 'query-string';

import {
  setAccessTokenToCookies,
  setRefreshTokenToCookies,
  setAccessTokenToAxios,
  setUserRoleToCookies
} from '../../../helpers/auth';

import accountService from '../../../services/account';


class Exchange extends Component {
  state = {
    error: null,
  };

  async componentDidMount() {
    const {
      props: {
        history,
        location: {
          search
        }
      }
    } = this;

    const { token } = queryString.parse(search);

    try {
      const { access, refresh } = await accountService.exchangeToken(token);

      setAccessTokenToCookies(access);
      setRefreshTokenToCookies(refresh);

      setAccessTokenToAxios(access);

      setUserRoleToCookies('pilot');

      history.push('/pilot/dashboard');
    } catch (err) {
      this.setState({
        error: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      })
    }
  }

  render() {
    const {
      state: {
        error
      }
    } = this;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        {
          error || 'We do magic stuff, please wait...'
        }
      </div>
    )
  }
}

export default Exchange;
