import React from 'react';
import { func, node, string } from 'prop-types';

import Modal from '../../Modal';
import Button from '../../Button';

import './index.scss';

const ConfirmModal = ({
  title,
  text,
  onAccept,
  onReject,
  onClose,
  acceptButtonText,
  rejectButtonText,
  children,
  clsTitle,
  clsBtns,
  clsBtnAccept,
  clsBtnReject
}) => (
    <Modal className="confirm-modal" withCloseBtn={false} onClose={onClose}>
      <h6 className={`title ${clsTitle ? clsTitle : ''}`}>{title}</h6>

      {text && <p className="text">{text}</p>}


      {children}


      <div className={`action-buttons ${clsBtns ? clsBtns : ''}`}>
        {
          !!acceptButtonText && (
            <Button
              className={`button ${clsBtnAccept ? clsBtnAccept : ''}`}
              invert
              onClick={onAccept}
            >{acceptButtonText}
            </Button>
          )
        }

        {
          !!rejectButtonText && (
            <Button
              className={`button ${clsBtnReject ? clsBtnReject : ''}`}
              onClick={onReject}
            >{rejectButtonText}
            </Button>
          )
        }
      </div>
    </Modal>
  );

ConfirmModal.propTypes = {
  title: string,
  text: string,
  acceptButtonText: node,
  rejectButtonText: node,
  onAccept: func,
  onReject: func,
  onClose: func.isRequired,
};

ConfirmModal.defaultProps = {
  title: 'Are you sure?',
  text: '',
  acceptButtonText: 'YES',
  rejectButtonText: 'NO',
  onAccept: () => false,
  onReject: () => false,
}

export default ConfirmModal;