import React from 'react';

import Loader from '../../../components/common/Loader';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';

import accountService from '../../../services/account';

import notifications from '../../../utils/notifications';

import {
  setAccessTokenToCookies,
  setAccessTokenToAxios,
  setRefreshTokenToCookies,
  setUserRoleToCookies
} from '../../../helpers/auth';

// TODO styles
import './index.scss';

class AirportLogin extends React.Component {
  state = {
    email: '',
    password: '',
    isLoading: false
  }

  handleChange = key => e => this.setState({ [key]: e.target.value });

  handleSubmit = async e => {
    e.preventDefault();

    const {
      props: {
        history,
      },
      state: {
        email,
        password,
        isLoading
      }
    } = this;

    if (isLoading) return;

    this.setState({ isLoading: true });

    try {
      const { access, refresh } = await accountService.adminLogIn({ email, password });

      setAccessTokenToCookies(access);
      setRefreshTokenToCookies(refresh);

      setAccessTokenToAxios(access);

      setUserRoleToCookies('airport');

      history.push('/airport/dashboard');
    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      handleChange,
      handleSubmit,
      state: {
        email,
        password,
        isLoading
      }
    } = this;


    return (
      <div className="login-container">
        <div className="items-block">
          <div className="welcome-text">Welcome!</div>

          <form className="form-block">
            <Input
              className="input"
              placeholder="EMAIL"
              value={email}
              onChange={handleChange('email')}
              fullWidth
            />

            <Input
              className="input"
              placeholder="PASSWORD"
              type="password"
              value={password}
              onChange={handleChange('password')}
              fullWidth
            />

            <div>
              <Button
                className="send-button"
                onClick={handleSubmit}
              >
                {
                  isLoading ? (<Loader />) : 'SEND'
                }
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}


export default AirportLogin;
