import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Link, withRouter } from 'react-router-dom';

import './index.scss';

const Menu = ({ tabs, location }) => (
  <ul className="sidebar-menu">
    {tabs.map(tab => (
      <li key={tab.label} className={location.pathname.includes(tab.link) ? 'active' : ''}>
        <Link to={tab.link}>
          <img className="image" src={tab.icon} alt="icon"/>
          <p className="label">{tab.label}</p>
        </Link>
      </li>
    ))}
  </ul>
);

Menu.propTypes = {
  tabs: arrayOf(shape({
    link: string,
    label: string,
    icon: string
  })),
  location: shape({
    pathname: string
  })
}

export default withRouter(Menu);
