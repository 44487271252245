import { toast } from 'react-toastify';

const success = ({ msg }) => toast.success(msg);

const error = ({ msg }) => toast.error(msg);

const warning = ({ msg }) => toast.warning(msg);

export default {
  success,
  error,
  warning
};
