import axios from 'axios';

import {
  makeApiWithQuery
} from '../helpers/services';

const getUsers = async ({
  order = 'ASC',
  limit = 10,
  offset = 0
} = {}) => {
  const {
    data
  } = await axios.get(makeApiWithQuery('/users/', {
    order,
    limit,
    offset
  }));

  return data;
}

const edit = async (model = {}) => {
  const res = await axios.put(`/users/${model.id}/`, model);

  return res.data;
};

const remove = async (id) => {
  const res = await axios.delete(`/users/${id}/`);

  return res;
};

const invite = async data => {
  const res = await axios.post('/users/invite/', data);

  return res.data;
};

export {
  getUsers,
  edit,
  remove,
  invite
};

export default {
  getUsers,
  edit,
  remove,
  invite
};