import axios from 'axios';
import moment from 'moment';

import reservationActions from '../redux/actions/reservation';

import { TYPES, IDS, getLandingAreaId } from '../helpers/reservations';

const cancelReservation = async id => {
  const res = await axios.post(`/reservations/${id}/cancel/`);

  return res.data;
};

const cancelReservationByAdmin = async id => {
  const res = await axios.post(`/reservations/${id}/cancel-by-admin/`);

  return res.data;
};

const getReservation = async id => {
  const res = await axios.get(`reservations/${id}/`);

  return res.data;
};

const confirmReservation = async (id, data) => {
  const res = await axios.post(`/reservations/${id}/confirm/`, data);

  return res.data;
};

const getUserReservations = async (
  id,
  {
    when = 'all',
    page_size = 10,
    lastEvaluatedKey = null,
    statuses,
    order,
    types,
  } = {},
) => {
  const last_evaluated_key =
    lastEvaluatedKey === null
      ? lastEvaluatedKey
      : encodeURIComponent(JSON.stringify(lastEvaluatedKey));

  statuses =
    statuses.length > 0 ? statuses : ['accepted', 'confirmed', 'canceled'];

  const statusesStr = statuses.map(s => `statuses=${s}&`).join('');

  const typesStr =
    types.length > 0 ? types.map(t => `operation_ids=${t}&`).join('') : '';

  let asc = true;

  if (order !== null) {
    asc = order.value;
  }

  const res = await axios.get(
    `users/${id}/reservations/?asc=${asc}&${statusesStr}${typesStr}when=${when}&page_size=${page_size}&last_evaluated_key=${last_evaluated_key}`,
  );

  return res.data;
};

const getAccountReservations = async ({
  when = 'all',
  page_size = 10,
  lastEvaluatedKey = null,
  statuses,
  types,
  order,
} = {}) => {
  const last_evaluated_key =
    lastEvaluatedKey === null
      ? lastEvaluatedKey
      : encodeURIComponent(JSON.stringify(lastEvaluatedKey));

  statuses =
    statuses.length > 0 ? statuses : ['accepted', 'confirmed', 'canceled'];

  const statusesStr = statuses.map(s => `statuses=${s}&`).join('');

  const typesStr =
    types.length > 0 ? types.map(t => `operation_ids=${t}&`).join('') : '';

  let asc = true;

  if (order !== null) {
    asc = order.value;
  }
  const res = await axios.get(
    `users/profile/reservations/?asc=${asc}&${statusesStr}${typesStr}when=${when}&page_size=${page_size}&last_evaluated_key=${last_evaluated_key}`,
  );

  return res.data;
};

const getDayReservations = async (
  date,
  {
    statuses = ['accepted', 'confirmed'],
    order = null,
    pageSize = 10,
    lastEvaluatedKey = null,
    types,
  } = {},
) => {
  const last_evaluated_key =
    lastEvaluatedKey === null
      ? lastEvaluatedKey
      : encodeURIComponent(JSON.stringify(lastEvaluatedKey));

  statuses = statuses.length > 0 ? statuses : ['accepted', 'confirmed'];

  const statusesStr = statuses.map(s => `statuses=${s}&`).join('');

  const typesStr =
    types.length > 0 ? types.map(t => `operation_ids=${t}&`).join('') : '';

  let asc = true;
  let sortKey = '';

  if (order !== null) {
    asc = order.value;

    console.log(order);
    if (order.label === 'Reservation Time') {
      sortKey = '&sort_key=reserved_time';
    } else {
      sortKey = '&sort_key=booking_timestamp';
    }
  }

  const res = await axios.get(
    `/reservations/?date=${moment(date).format(
      'YYYY-MM-DD',
    )}&${statusesStr}${typesStr}asc=${asc}${sortKey}&page_size=${pageSize}&last_evaluated_key=${last_evaluated_key}`,
  );

  return res.data;
};

const getClosedDays = ({
  landingAreaIds = [],
  from_date = moment(),
  to_date = moment().add(3, 'month'),
} = {}) => async dispatch => {
  dispatch(reservationActions.setClosedDaysLoadingStatus(true));

  const landingAreaIdsStr = landingAreaIds
    .map(a => `landing_area_id=${a}&`)
    .join('');
  const {
    data: {
      result: { closed_days },
    },
  } = await axios.get(
    `/reservations/closed-days/?${landingAreaIdsStr}from_date=${moment(
      from_date,
    ).format('YYYY-MM-DD')}&to_date=${moment(to_date).format('YYYY-MM-DD')}`,
  );

  dispatch(reservationActions.updateClosedDays(closed_days));
  dispatch(reservationActions.setClosedDaysLoadingStatus(false));

  return closed_days;
};

const getUtilizationData = landingId => async (dispatch, getState) => {
  const {
    reservation: {
      selectedOperation,
      selectedDay,
      numberOfTouchAndGo,
      closeRunway,
      customDuration,
    },
  } = getState();

  let landingAreaId, operationId;

  if (!closeRunway) {
    landingAreaId = getLandingAreaId(selectedOperation.id);
    operationId = IDS[selectedOperation.id];
  } else {
    landingAreaId = landingId;

    operationId = 'larsa-op-9';
  }

  dispatch(reservationActions.setUtilizationDataLodingStatus(true));

  const body = {
    landing_area_id: landingAreaId,
    date: moment(selectedDay).format('YYYY-MM-DD'),
    operation_id: operationId,
  };

  if (closeRunway) {
    body.duration = 1;
  }

  if ([TYPES.vfr_circuit, TYPES.ifr_training].includes(selectedOperation.id)) {
    body.nr_repeats = numberOfTouchAndGo;
  }

  if ([TYPES.helex, TYPES.fato].includes(selectedOperation.id)) {
    body.duration = customDuration * 60;
  }

  const {
    data: { error, result },
  } = await axios.post('/reservations/utilization/', body);

  if (error) {
    console.error(error);
    return null;
  }

  dispatch(reservationActions.setSelectedTime(result.timeline[0].slice(0, 5)));
  dispatch(reservationActions.setUtilizationData(result));
  dispatch(reservationActions.setUtilizationDataLodingStatus(false));
};

const createReservation = async ({
  rescheduleReservationId,
  datetime,
  landingAreaId,
  duration,
  operationId,
  numberOfTouchAndGo,
  description,
} = {}) => {
  const res = await axios.post('/reservations/', {
    reschedule_reservation_id: rescheduleReservationId,
    eta_datetime: moment(datetime).format('YYYY-MM-DD HH:mm'),
    landing_area_id: landingAreaId,
    duration,
    operation_id: IDS[operationId],
    nr_repeats: numberOfTouchAndGo,
    description,
  });

  return res;
};

const closeLandingAreas = async ({ date, landingAreaIds } = {}) => {
  const res = await axios.post('/reservations/close-landing-areas/', {
    landing_area_ids: landingAreaIds,
    date,
  });

  return res;
};

const countsOfReservations = async date => {
  const res = await axios.get(
    `reservations/counts/?date=${moment(date).format('YYYY-MM-DD')}`,
  );

  return res.data;
};

export {
  cancelReservation,
  getReservation,
  getDayReservations,
  getUserReservations,
  confirmReservation,
  getAccountReservations,
  getClosedDays,
  getUtilizationData,
  createReservation,
  closeLandingAreas,
  cancelReservationByAdmin,
  countsOfReservations,
};

export default {
  cancelReservation,
  getReservation,
  getDayReservations,
  getUserReservations,
  confirmReservation,
  getAccountReservations,
  getClosedDays,
  getUtilizationData,
  createReservation,
  closeLandingAreas,
  cancelReservationByAdmin,
  countsOfReservations,
};
