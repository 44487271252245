import React from 'react';

import './index.scss';

const TextArea = ({ className = '', fullWidth = false, ...props }) => (
  <textarea
    className={`custom-textarea ${fullWidth ? 'full-width' : ''} ${className}`}
    {...props}
  />
);

export default TextArea;
