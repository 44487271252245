const SET_RESCHEDULE_RESERVATION_ID =
  "RESERVATION/SET_RESCHEDULE_RESERVATION_ID";

const SET_ACTIVE_STEP = "RESERVATION/SET_ACTIVE_STEP";

const SET_SELECTED_OPERATION = "RESERVATION/SET_SELECTED_OPERATION";

const SET_SELECTED_CLOSE_TAB = "RESERVATION/SET_SELECTED_CLOSE_TAB";

const PREVIOUS_STEP = "RESERVATION/PREVIOUS_STEP";
const NEXT_STEP = "RESERVATION/NEXT_STEP";
const DROP_ACTIVE_STEP = "RESERVATION/DROP_ACTIVE_STEP";

const SET_TOUCH_AND_GO = "RESERVATION/SET_TOUCH_AND_GO";
const INCREASE_TOUCH_AND_GO = "RESERVATION/INCREASE_TOUCH_AND_GO";
const DECREASE_TOUCH_AND_GO = "RESERVATION/DECREASE_TOUCH_AND_GO";

const SET_SELECTED_DAY = "RESERVATION/SET_SELECTED_DAY";

const INCREASE_CUSTOM_DURATION = "RESERVATION/INCREASE_CUSTOM_DURATION";
const DECREASE_CUSTOM_DURATION = "RESERVATION/DECREASE_CUSTOM_DURATION";

const INCREASE_CLOSE_DURATION = "RESERVATION/INCREASE_CLOSE_DURATION";
const DECREASE_CLOSE_DURATION = "RESERVATION/DECREASE_CLOSE_DURATION";

const UPDATE_CLOSED_DAYS = "RESERVATION/UPDATE_CLOSED_DAYS";
const SET_CLOSED_DAYS_LOADING_STATUS =
  "RESERVATION/SET_CLOSED_DAYS_LOADING_STATUS";

const SET_UTILIZATION_DATA = "RESERVATION/SET_UTILIZATION_DATA";
const SET_UTILIZATION_DATA_LODING_STATUS =
  "RESERVATION/SET_UTILIZATION_DATA_LODING_STATUS";

const SET_SELECTED_TIME = "RESERVATION/SET_SELECTED_TIME";

const SET_SELECTED_CLOSE_TIME = "RESERVATION/SET_SELECTED_CLOSE_TIME";

const SET_DESCRIPTION = "RESERVATION/SET_DESCRIPTION";
const SET_SELECTED_AREAS = "RESERVATIONS/SET_SELECTED_AREAS";
const CLOSE_RUNWAY = "RESERVATION/CLOSE_RUNWAY";
const CLOSE_WHOLE_DAY = "RESERVATION/CLOSE_WHOLE_DAY";

const SET_AVERAGE_LEVEL = "RESERVATION/SET_AVERAGE_LEVEL";

const setRescheduleReservationId = id => ({
  type: SET_RESCHEDULE_RESERVATION_ID,
  id
});

const setActiveStep = step => ({ type: SET_ACTIVE_STEP, step });

const setSelectedOperation = operation => ({
  type: SET_SELECTED_OPERATION,
  operation
});

const setSelectedCloseTab = selectedTab => ({
  type: SET_SELECTED_CLOSE_TAB,
  selectedTab
});

const previousStep = () => ({ type: PREVIOUS_STEP });
const nextStep = () => ({ type: NEXT_STEP });
const dropActiveStep = () => ({ type: DROP_ACTIVE_STEP });

const setTouchAndGo = numberOfTouchAndGo => ({
  type: SET_TOUCH_AND_GO,
  numberOfTouchAndGo
});
const decreaseTouchAndGo = () => ({ type: DECREASE_TOUCH_AND_GO });
const increaseTouchAndGo = () => ({ type: INCREASE_TOUCH_AND_GO });

const setSelectedDay = day => ({ type: SET_SELECTED_DAY, day });

const increaseCustomDuration = () => ({ type: INCREASE_CUSTOM_DURATION });
const decreaseCustomDuration = () => ({ type: DECREASE_CUSTOM_DURATION });

const increaseCloseDuration = () => ({ type: INCREASE_CLOSE_DURATION });
const decreaseCloseDuration = () => ({ type: DECREASE_CLOSE_DURATION });

const updateClosedDays = days => ({ type: UPDATE_CLOSED_DAYS, days });
const setClosedDaysLoadingStatus = status => ({
  type: SET_CLOSED_DAYS_LOADING_STATUS,
  status
});

const setUtilizationData = data => ({ type: SET_UTILIZATION_DATA, data });
const setUtilizationDataLodingStatus = status => ({
  type: SET_UTILIZATION_DATA_LODING_STATUS,
  status
});

const setSelectedTime = time => ({ type: SET_SELECTED_TIME, time });

const setSelectedCloseTime = obj => ({ type: SET_SELECTED_CLOSE_TIME, obj });

const setDescription = description => ({ type: SET_DESCRIPTION, description });

const closeRunway = value => ({ type: CLOSE_RUNWAY, value });
const closeWholeDay = value => ({ type: CLOSE_WHOLE_DAY, value });
const setSelectedAreas = areas => ({ type: SET_SELECTED_AREAS, areas });

const setAverageLevel = level => ({ type: SET_AVERAGE_LEVEL, level });

export {
  SET_RESCHEDULE_RESERVATION_ID,
  SET_ACTIVE_STEP,
  SET_SELECTED_OPERATION,
  SET_SELECTED_CLOSE_TAB,
  PREVIOUS_STEP,
  NEXT_STEP,
  DROP_ACTIVE_STEP,
  DECREASE_TOUCH_AND_GO,
  INCREASE_TOUCH_AND_GO,
  SET_SELECTED_DAY,
  INCREASE_CLOSE_DURATION,
  DECREASE_CLOSE_DURATION,
  SET_TOUCH_AND_GO,
  INCREASE_CUSTOM_DURATION,
  DECREASE_CUSTOM_DURATION,
  UPDATE_CLOSED_DAYS,
  SET_CLOSED_DAYS_LOADING_STATUS,
  SET_UTILIZATION_DATA,
  SET_UTILIZATION_DATA_LODING_STATUS,
  SET_SELECTED_TIME,
  SET_SELECTED_CLOSE_TIME,
  SET_DESCRIPTION,
  CLOSE_RUNWAY,
  SET_SELECTED_AREAS,
  CLOSE_WHOLE_DAY,
  SET_AVERAGE_LEVEL,
  setRescheduleReservationId,
  setActiveStep,
  setSelectedOperation,
  increaseCloseDuration,
  decreaseCloseDuration,
  setSelectedCloseTab,
  previousStep,
  nextStep,
  dropActiveStep,
  setTouchAndGo,
  decreaseTouchAndGo,
  increaseTouchAndGo,
  setSelectedDay,
  increaseCustomDuration,
  decreaseCustomDuration,
  updateClosedDays,
  setClosedDaysLoadingStatus,
  setUtilizationData,
  setUtilizationDataLodingStatus,
  setSelectedTime,
  setSelectedCloseTime,
  setDescription,
  closeRunway,
  setSelectedAreas,
  closeWholeDay,
  setAverageLevel
};

export default {
  SET_RESCHEDULE_RESERVATION_ID,
  SET_ACTIVE_STEP,
  SET_SELECTED_OPERATION,
  SET_SELECTED_CLOSE_TAB,
  PREVIOUS_STEP,
  NEXT_STEP,
  DROP_ACTIVE_STEP,
  DECREASE_TOUCH_AND_GO,
  INCREASE_TOUCH_AND_GO,
  SET_SELECTED_DAY,
  INCREASE_CLOSE_DURATION,
  DECREASE_CLOSE_DURATION,
  SET_TOUCH_AND_GO,
  INCREASE_CUSTOM_DURATION,
  DECREASE_CUSTOM_DURATION,
  UPDATE_CLOSED_DAYS,
  SET_CLOSED_DAYS_LOADING_STATUS,
  SET_UTILIZATION_DATA,
  SET_UTILIZATION_DATA_LODING_STATUS,
  SET_SELECTED_TIME,
  SET_SELECTED_CLOSE_TIME,
  SET_DESCRIPTION,
  CLOSE_RUNWAY,
  SET_SELECTED_AREAS,
  CLOSE_WHOLE_DAY,
  SET_AVERAGE_LEVEL,
  setRescheduleReservationId,
  setActiveStep,
  setSelectedOperation,
  setSelectedCloseTab,
  increaseCloseDuration,
  decreaseCloseDuration,
  previousStep,
  nextStep,
  dropActiveStep,
  setTouchAndGo,
  decreaseTouchAndGo,
  increaseTouchAndGo,
  setSelectedDay,
  increaseCustomDuration,
  decreaseCustomDuration,
  updateClosedDays,
  setClosedDaysLoadingStatus,
  setUtilizationData,
  setUtilizationDataLodingStatus,
  setSelectedTime,
  setSelectedCloseTime,
  setDescription,
  closeRunway,
  setSelectedAreas,
  closeWholeDay,
  setAverageLevel
};
