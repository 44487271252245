import React, { Component } from 'react';

import Input from '../../../../components/common/Input';

import './index.scss';

class SignUpForm extends Component {
  render() {
    const {
      props: {
        handleChange,
        model
      }
    } = this;

    return (
      <div className="signup-form">
        <div className="signup-welcome-text">Welcome!</div>

        <div className="signup-info-text">Please fill out the form below!</div>

        <form className="form-block">
          <div className="signup-input-block">
            <label className="label">First Name:</label>
            <Input
              value={model.first_name}
              onChange={handleChange('first_name')}
              fullWidth
            />
          </div>

          <div className="signup-input-block">
            <label className="label">Last Name:</label>
            <Input
              value={model.last_name}
              onChange={handleChange('last_name')}
              fullWidth
            />
          </div>

          <div className="signup-input-block">
            <label className="label">Telephone:</label>
            <Input
              value={model.telephone}
              onChange={handleChange('telephone')}
              fullWidth
            />
          </div>

          <div className="signup-input-block">
            <label className="label">Email:</label>
            <Input
              value={model.email}
              onChange={handleChange('email')}
              fullWidth
            />
          </div>

          <div className="signup-input-block">
            <label className="label">Company (if applicable):</label>
            <Input
              value={model.company_name}
              onChange={handleChange('company_name')}
              fullWidth
            />
          </div>

          {
            !!model.company_name && (
              <div className="company-block">
                <div className="signup-input-block company-address">
                  <label className="label">Company address:</label>
                </div>

                <div className="signup-input-block">
                  <label className="sub-label">Street:</label>
                  <Input
                    value={model.company_street}
                    onChange={handleChange('company_street')}
                    fullWidth
                  />
                </div>

                <div className="signup-input-block">
                  <label className="sub-label">Postal Code:</label>

                  <Input
                    value={model.company_postalcode}
                    onChange={handleChange('company_postalcode')}
                    fullWidth
                  />
                </div>

                <div className="signup-input-block">
                  <label className="sub-label">City:</label>
                  <Input
                    value={model.company_city}
                    onChange={handleChange('company_city')}
                    fullWidth
                  />
                </div>
              </div>
            )
          }
        </form>
      </div>
    )
  }
}

export default SignUpForm;
