import {
  SET_CURRENT_USER,
  SET_RESERVATIONS,
  SET_IS_RESERVATIONS_LOADING
} from '../actions/account';

const initialState = {
  currentUser: null,
  reservations: [],
  isReservationsLoading: false
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.user
        }
      };
    case SET_RESERVATIONS:
      return {
        ...state,
        reservations: action.reservations
      };

    case SET_IS_RESERVATIONS_LOADING:
      return {
        ...state,
        isReservationsLoading: action.isLoading
      };
    default:
      return state;
  }
};

export default accountReducer;
