import React, { Component } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';

import Button from '../Button';
import Loader from '../Loader';
import Input from '../Input';
import ConfirmModal from '../modals/ConfirmModal';

import userService from '../../../services/user';

import notifications from '../../../utils/notifications';

import './index.scss';

const UserFieldBlock =
  ({ field, label, model, user, isEditMode, changeUser, ...props }) => (
    <div className="block" {...props}>
      <p className="head">{label}:</p>
      {
        isEditMode ? (
          <Input
            className="foot-edit"
            value={model[field]}
            onChange={changeUser(field)}
          />
        ) : (
            <p className="foot">{user[field] || '-'}</p>
          )
      }
    </div>
  );

class UserInfo extends Component {
  state = {
    model: { ...this.props.user },
    isEditMode: false,
    isSubmitting: false,
    isRemoving: false,
    isRemoveModalShow: false
  };

  toggleEditMode = (isEdit = null) =>
    this.setState(prev => ({ isEditMode: isEdit === null ? !prev.isEditMode : isEdit }));

  changeUser = key => e => {
    e.persist();

    this.setState(prev => ({
      model: {
        ...prev.model,
        [key]: e.target.value
      }
    }));
  }

  deleteUser = async () => {
    const {
      props: {
        user,
        close,
        onSuccessRemove
      }
    } = this;

    try {
      this.setState({ isRemoving: true });

      await userService.remove(user.id);

      await close();

      onSuccessRemove(user.id);

      const showName = (user.first_name || user.last_name) ? `${user.first_name} ${user.last_name}` : user.email;

      notifications.success({ msg: `User ${showName} was successfully removed.` });
    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });
    }
  };

  onSaveButtonClick = async () => {
    const {
      props: {
        edit,
        onSuccessEdit
      },
      state: {
        model
      }
    } = this;

    this.setState({ isSubmitting: true });

    const save = edit || userService.edit;

    try {
      const editedUser = await save(model);

      this.setState({
        model: editedUser,
        isEditMode: false,
        isSubmitting: false
      }, () => onSuccessEdit(editedUser));
    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });

      this.setState({ isSubmitting: false });
    }
  }

  openRemoveModal = () => this.setState({ isRemoveModalShow: true });
  closeRemoveModal = () => this.setState({ isRemoveModalShow: false });

  onEditCancel = () => this.setState({
    isEditMode: false,
    model: this.props.user,
  });

  render() {
    const {
      toggleEditMode,
      changeUser,
      deleteUser,
      onSaveButtonClick,
      onEditCancel,
      openRemoveModal,
      closeRemoveModal,
      props: {
        wideMode,
        user,
        withEdit,
        withRemove,
        withTitle
      },
      state: {
        model,
        isEditMode,
        isSubmitting,
        isRemoving,
        isRemoveModalShow
      }
    } = this;

    return (
      <div
        className={
          classnames({
            'user-info': true,
            wide: wideMode && !!model.company_name,
            large: !!model.company_name
          })
        }
      >
        {
          !!withTitle && (
            <p className="user-info-title">USER DETAILS</p>
          )
        }

        <div className="user-info-main">
          <Grid container spacing={(wideMode && model.company_name) ? 6 : 2}>
            <Grid item xs={(wideMode && model.company_name) ? 6 : 12}>
              {
                [
                  { field: 'first_name', label: 'FIRST NAME' },
                  { field: 'last_name', label: 'LAST NAME' },
                  { field: 'telephone', label: 'TELEPHONE' },
                  { field: 'email', label: 'EMAIL' },
                  { field: 'company_name', label: 'COMPANY' }

                ].map(({ field, label }) => (
                  <UserFieldBlock
                    key={field}
                    field={field}
                    label={label}
                    model={model}
                    user={user}
                    changeUser={changeUser}
                    isEditMode={isEditMode}
                  />
                ))
              }
            </Grid>

            {
              model.company_name && (
                <Grid item xs={(wideMode && model.company_name) ? 6 : 12}>
                  {
                    [
                      { field: 'company_street', label: 'COMPANY STREET' },
                      { field: 'company_postalcode', label: 'POSTAL CODE' },
                      { field: 'company_city', label: 'CITY' },
                    ].map(({ field, label }) => (
                      <UserFieldBlock
                        field={field}
                        label={label}
                        model={model}
                        user={user}
                        changeUser={changeUser}
                        isEditMode={isEditMode}
                      />
                    ))
                  }
                </Grid>
              )
            }
          </Grid>
        </div>

        <div className={
          classnames({
            "user-info-buttons": true,
            'space-around': wideMode && !!model.company_name
          })}>
          {
            !!withEdit && (
              isEditMode ? (
                <>
                  <Button fullWidth onClick={onSaveButtonClick}>
                    {isSubmitting ? <Loader /> : 'SAVE'}
                  </Button>

                  <Button fullWidth onClick={onEditCancel}>
                    CANCEL
                  </Button>
                </>
              ) : (
                  <Button oneThird onClick={() => toggleEditMode()}>EDIT</Button>
                )
            )
          }

          {
            !!withRemove && !isEditMode && (
              <Button halfWidth className={
                classnames({
                  "delete-user-btn": true,
                  thin: wideMode && !!model.company_name
                })
              } invert onClick={openRemoveModal}>
                DELETE USER
              </Button>
            )
          }
        </div>

        {
          isRemoveModalShow && (
            <ConfirmModal
              acceptButtonText={isRemoving ? <Loader /> : 'DELETE'}
              rejectButtonText="CANCEL"
              onAccept={() => isRemoving ? false : deleteUser()}
              onReject={closeRemoveModal}
              onClose={closeRemoveModal}
            />
          )
        }
      </div>
    )
  }
}

UserInfo.propTypes = {
  user: shape({
    id: number,
    first_name: string,
    last_name: string,
    telephone: string,
    email: string,
    company_name: string,
    company_street: string
  }).isRequired,
  withEdit: bool,
  withRemove: bool,
  withTitle: bool,
  edit: func,
  close: func,
  onSuccessEdit: func,
  onSuccessRemove: func,
};

UserInfo.defaultProps = {
  wideMode: false,
  withEdit: true,
  withRemove: true,
  withTitle: true,
  edit: null,
  close: () => false,
  onSuccessEdit: () => false,
  onSuccessRemove: () => false,
  user: null
};

export default UserInfo;
