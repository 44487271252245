import React, { useState } from 'react';
import { number, shape, string, bool } from 'prop-types';
import moment from 'moment'

import { secondsToMinutes } from '../../../../helpers/time';
import Button from '../../Button';

import reservationsService from '../../../../services/reservations'

import notificationsUtils from '../../../../utils/notifications'


import './index.scss';
import classnames from 'classnames';


const ReservationDetails = ({ reservation, withTitle, withType, subtitle, handleCancel, withStatus, onClose, withCancelBtn }) => {
  const [isCanceling, setIsCanceling] = useState(false)

  const onCancel = async () => {

    if (handleCancel) {
      handleCancel()
      return
    }
    setIsCanceling(true)

    const data = await reservationsService.cancelReservationByAdmin(reservation.res_id)

    if (data.status === 'ok') {
      reservation.status = 'canceled'
      notificationsUtils.success({ msg: `Reservation  was successfully cancelled.` })
    } else {
      notificationsUtils.error({
        msg: data.error
      })
    }
    setIsCanceling(false)

    onClose()

  }

  return (
    <div className="reservations-details">
      {
        withTitle && <h2 className="title">RESERVATIONS DETAILS</h2>
      }
      {withType && <h3 className="operation-type">{reservation.operation_type}</h3>}

      <div className="subtitle">{subtitle ? subtitle : reservation.title}</div>

      <div className="info">
        <p className="p-one-line">Date: {reservation.eta_dt ? moment(reservation.eta_dt).format("MMMM Do YYYY") : '-'}</p>
        <p className="p-one-line">Time: {reservation.eta_dt ? `${moment(reservation.eta_dt).utc().format("kk:mm")} (UTC Time)` : '-'}</p>
        {
          !['larsa-op-1', 'larsa-op-2', 'larsa-op-4', 'larsa-op-5'].includes(reservation.operation_id) && reservation.expected_duration &&
          <p> Duration:  {`${secondsToMinutes(reservation.expected_duration)} min`}</p>

        }
        {
          reservation.nr_repeats &&
          <p>T&G: {reservation.nr_repeats}</p>

        }
        <br />

        <p className="p-one-line">Reservation ID: {reservation.res_id || '-'}</p>
        <p className="p-one-line">Date of reservation: {moment(reservation.created_datetime).format("D MMM, YYYY")}</p>
        <br />
        {withStatus && <p>Status: <span className={classnames({ "status": true, 'canceled': reservation.status === 'canceled', 'accepted': reservation.status === 'accepted' })}>{reservation.status || '-'}</span></p>}
      </div>
      {
        withCancelBtn && <Button className="reservations-button" onClick={() => onCancel()} invert fullWidth>CANCEL RESERVATION</Button>
      }
    </div>
  )
}



ReservationDetails.propTypes = {
  reservation: shape({
    id: string,
    title: string,
    date: string,
    time: string,
    tg: number
  }),
  withTitle: bool,
  subtitle: string,
  withCancelBtn: bool,
  withStatus: bool,
  withType: bool
};

ReservationDetails.defaultProps = {
  reservation: null,
  withTitle: true,
  subtitle: '',
  withCancelBtn: true,
  withStatus: true,
  withType: true
}

export default ReservationDetails;
