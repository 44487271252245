import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Reservations from '../../../components/common/Reservations';
import ReservationDetails from '../../../components/common/Reservations/ReservationDetails';
import NotificationListItem from '../../../components/common/NotificationsList/NotificationListItem';
import ConfirmModal from '../../../components/common/modals/ConfirmModal'
import WeatherBlock from '../../../components/pilot/WeatherBlock'
import Button from '../../../components/common/Button'

import reservationsService from '../../../services/reservations'
import reservationActions from '../../../redux/actions/reservation';
import notificationsUtils from '../../../utils/notifications'
import { IDS, RESERVATIONS_TYPES } from '../../../helpers/reservations';

import arrow from '../../../images/ic_arrow_tutorial.svg'

import './index.scss';

class Dashboard extends React.Component {
  state = {
    reservations: [],
    selectedReservation: null,
    notifications: null,
    isCancelling: false,
    isReservationsLoading: false,
    scrolling: false,
    totalPagesNumber: null,
    lastEvaluatedKey: null,
    isConfirmModalShow: false,
    isFiltered: false,
    types: [],
    statuses: [],
    order: null
  }

  async componentDidMount() {
    const reservations = await this.getUpcomingReservations()
    this.setState({ reservations })
  }


  onReservationClick = (reservation) => {
    this.setState({ selectedReservation: reservation, notifications: reservation.notifications })
  }

  loadMoreReservations = () => {
    this.setState({
      scrolling: true
    }, this.getUpcomingReservations)
  }

  getUpcomingReservations = async () => {
    const { reservations, lastEvaluatedKey, statuses, types, order } = this.state

    try {
      const { result } = await reservationsService.getAccountReservations({ when: 'future', statuses, types, order, lastEvaluatedKey });

      this.setState({
        isReservationsLoading: false,
        lastEvaluatedKey: result.last_evaluated_key,
        reservations: [...reservations, ...result.reservations],
        scrolling: false
      }, () => Promise.resolve());

      return result.reservations
    } catch (error) {
      console.log(error)
      return []
    }
  }

  getFilteredReservations = async (filters) => {
    try {
      const { result } = await reservationsService.getAccountReservations({ when: 'future', ...filters })
      this.setState({
        reservations: result.reservations,
        lastEvaluatedKey: result.last_evaluated_key,
        scrolling: false,
        isFiltered: true,
        ...filters,
      })
    } catch (error) {
      console.log(error)
    }
    this.setState({ isReservationsLoading: false })
  }


  onStatusChange = async (statuses) => {
    const { order, types } = this.state

    this.setState({ isReservationsLoading: true })
    this.getFilteredReservations({ statuses, order, types })
  }

  onTimeChange = async (item) => {
    const { types, statuses } = this.state

    this.setState({ isReservationsLoading: true })
    this.getFilteredReservations({ statuses, order: item, types })
  }

  onTypeChange = async (types) => {
    const { order, statuses } = this.state

    this.setState({ isReservationsLoading: true })
    this.getFilteredReservations({ statuses, order, types })
  }

  handleCancel = async (confirm) => {
    const { selectedReservation } = this.state
    if (!confirm) {
      this.setState({
        isConfirmModalShow: true
      })
    } else {
      this.setState({ isCanceling: true })

      const data = await reservationsService.cancelReservation(selectedReservation.res_id)

      if (data.status === 'ok') {
        this.setState(prev => ({
          reservations: prev.reservations.filter(r => r.res_id !== selectedReservation.res_id)
        }))
        notificationsUtils.success({ msg: `Reservation  was successfully ${selectedReservation.status === 'canceled' ? 'deleted' : 'canceled'}.` })
      } else {
        notificationsUtils.error({
          msg: data.error
        })
      }

      this.closeModal()
      this.setState({ selectedReservation: null })
    }
  }

  closeModal = () => {
    this.setState({ isConfirmModalShow: false })
  }

  handleConfirm = async (reservId, ntfId, aircraftNumber) => {
    const { selectedReservation } = this.state

    const data = await reservationsService.confirmReservation(reservId, { aircraft_registration: aircraftNumber })

    if (data.status === 'ok') {
      selectedReservation.status = 'confirmed'
      selectedReservation.notifications = selectedReservation.notifications.filter(n => n.id !== ntfId)

      this.setState(prev => ({
        notifications: [...prev.notifications.filter(n => n.id !== ntfId)]
      }))
      notificationsUtils.success({ msg: `Reservation  was successfully confirmed.` })
    } else {
      notificationsUtils.error({
        msg: data.error
      })
    }
  }

  handleNavigate = (route) => {
    const {
      state: {
        selectedReservation,

      },
      props: {
        history,
        setActiveStep,
        setSelectedOperation,
        setRescheduleReservationId
      }
    } = this

    let operation = null;

    if (selectedReservation !== null) {
      const operationIdArr = Object.entries(IDS).find(e => e[1] === selectedReservation.operation_id)

      operation = {
        id: operationIdArr[0],
        text: RESERVATIONS_TYPES[selectedReservation.operation_id]
      }

      setActiveStep(2)
      setSelectedOperation(operation)
      setRescheduleReservationId(selectedReservation.res_id)
    }


    history.push(route)
  }

  render() {
    const {
      onReservationClick,
      loadMoreReservations,
      handleNavigate,
      onStatusChange,
      onTypeChange,
      onTimeChange,
      state: {
        reservations,
        selectedReservation,
        notifications,
        lastEvaluatedKey,
        isConfirmModalShow,
        isFiltered,
        isReservationsLoading,
      }
    } = this;

    const emptyReservations = (
      <Grid item xs={12} lg={8}>
        <div className="empty-reservations">
          <p className="placeholder">You will see your reservations here</p>
          <p className="secondPlaceholder">Book a timeslot for your next operation! Start a reservation now:</p>
          <Button className="start-btn" onClick={() => handleNavigate('/pilot/reserve')} >
            START YOUR FIRST RESERVATION
          </Button>
        </div>
      </Grid>
    )

    return (
      <div className="dashboard-container">
        <Grid container justify="center">
          <WeatherBlock />

          {reservations.length === 0 && !isFiltered ? (emptyReservations) :
            (
              <div className="reservations-container">

                {selectedReservation === null && !isFiltered && (<div className="emptySelected">
                  <h2 className="placeholderSelect">Select a reservation!</h2>
                  <div className="placeHolderSelectText">
                    Here you can see the details, notifications, and edit or cancel your reservations.
                </div>
                  <img src={arrow} alt="arrow" />
                </div>
                )
                }
                <Grid lg={11} md={11} container item justify="center" alignItems="flex-start" className="dashboard-reserv-body">
                  <Grid item xs={4} container justify="center" lg={4} className="upcoming-reserv-block">
                    <div className="header">UPCOMING RESERVATIONS:</div>
                    <Reservations
                      withBookingTimes={false}
                      onStatusChange={onStatusChange}
                      onTypeChange={onTypeChange}
                      onTimeChange={onTimeChange}
                      isLoading={isReservationsLoading}
                      withTitle={false}
                      openModal={false}
                      parentReservation={selectedReservation}
                      handleClick={onReservationClick}
                      loadMore={loadMoreReservations}
                      lastEvaluatedKey={lastEvaluatedKey}
                      reservations={reservations}
                    />
                  </Grid>

                  <Grid item xs={12} justify="center" lg={4}>
                    <h2 className="header">RESERVATION DETAILS:</h2>
                    {selectedReservation !== null &&
                      <div className="reservation-details">
                        <div className="reservation-details-content">

                          <ReservationDetails
                            reservation={selectedReservation}
                            subtitle={`Operation: ${selectedReservation.operation_type}`}
                            withTitle={false}
                            withCancelBtn={false}
                            handleCancel={() => this.handleCancel(true)}
                            withType={false}
                          />
                        </div>
                        {selectedReservation.status !== 'deleted' &&
                          (<div >
                            <Button className="edit-button" onClick={() => handleNavigate('/pilot/reserve')}>{selectedReservation.status === 'canceled' ? 'RESCHEDULE' : 'EDIT RESERVATION'}</Button>
                            <br />
                            <Button className="cancel-button" invert onClick={() => selectedReservation.status === 'canceled' ? this.handleCancel(true) : this.handleCancel(false)}>{selectedReservation.status === 'canceled' ? 'DELETE' : 'CANCEL'}</Button>
                          </div>)
                        }
                      </div>
                    }
                  </Grid>

                  {isConfirmModalShow &&
                    <ConfirmModal
                      title='Are you sure you want to cancel this reservation?'
                      acceptButtonText='Cancel Reservation'
                      rejectButtonText='Keep Reservation'
                      clsTitle='title-cancelReserv'
                      clsBtns='btns-cancelReserv'
                      clsBtnAccept='accBtn'
                      clsBtnReject='rejBtn'
                      onClose={() => this.closeModal()}
                      onAccept={() => this.handleCancel(true)}
                      onReject={() => this.closeModal()}
                    >
                      <div style={{ textAlign: 'left' }}>
                        <ReservationDetails
                          reservation={selectedReservation}
                          subtitle={`Operation: ${selectedReservation.operation_type}`}
                          withTitle={false}
                          withCancelBtn={false}
                          withStatus={false}
                          withType={false}
                        />
                      </div>

                    </ConfirmModal>

                  }

                  <Grid item xs={12} justify="center" lg={4}>
                    <h2 className="header">NOTIFICATIONS:</h2>
                    {
                      selectedReservation !== null && notifications &&
                      notifications.map(item => (
                        <NotificationListItem
                          onConfirm={(aircraftNumber) => this.handleConfirm(item.reservation_id, item.id, aircraftNumber)}
                          notification={item}
                          confirmation={!['canceled', 'deleted'].includes(selectedReservation.status)}
                          simpleNtf={true}
                        />
                      ))
                    }
                  </Grid>
                </Grid>
              </div>
            )
          }
        </Grid>
      </div >
    )
  }
}

const mapStateToProps = ({
  reservation: {
    activeStep,
    selectedOperation,
    customDuration,
    isClosedDaysLoading,
    selectedDay,
    selectedTime,
    numberOfTouchAndGo,
    utilization,
    description
  }
}) => ({
  activeStep,
  selectedOperation,
  customDuration,
  isClosedDaysLoading,
  selectedDay,
  selectedTime,
  numberOfTouchAndGo,
  utilization,
  description
});

const mapDispatchToProps = dispatch => ({
  setActiveStep: step => dispatch(reservationActions.setActiveStep(step)),
  setSelectedOperation: operation => dispatch(reservationActions.setSelectedOperation(operation)),
  setSelectedDay: day => dispatch(reservationActions.setSelectedDay(day)),
  setTouchAndGo: numberOfTouchAndGo => dispatch(reservationActions.setTouchAndGo(numberOfTouchAndGo)),
  setRescheduleReservationId: id => dispatch(reservationActions.setRescheduleReservationId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard))
