import React, { Component } from 'react';
import './index.scss'
import { Grid } from '@material-ui/core';

import Input from '../../../components/common/Input';
import TextArea from '../../../components/common/TextArea';

import notificationsService from '../../../services/notifications';
import notifications from '../../../utils/notifications';
import NotificationsList from '../../../components/common/NotificationsList'


const fakeItem = [
  {
    id: 1,
    title: 'Confirm your reservation!',
    body: '[Operation type], tomorrow at [hh:mm]'
  },
  {
    id: 2,
    title: 'Confirm your reservation!',
    body: 'This reservation is coming up. Please confirm this reservation before 18:00.'
  },
  {
    id: 3,
    title: 'Your reservation has been canceled',
    body: 'Due to operational reasons, your [Operation type] on [date] at [time] has been canceled. You can reschedule this reservation to another time.'
  }
]


class Notifications extends Component {

  state = {
    data: {
      title: '',
      body: '',
    },
    notifications: [],
    offset: 0,
    next: null,
    isCurrentUserLoading: true
  };

  async componentDidMount() {
    const notifications = await this.getNotifications();
    this.setState({ isUsersLoading: false, notifications });
  }

  getNotifications = async () => {
    const { offset, notifications } = this.state

    const { next, results: newNotifications } = await notificationsService.getAirportNotification({ limit: 10, offset });

    this.setState({ isUsersLoading: false, next, scrolling: false, notifications: [...notifications, ...newNotifications] });

    return newNotifications

  }

  loadMoreNotifications = () => {
    this.setState(prevState => ({
      offset: prevState.offset + 10,
      scrolling: true
    }), this.getNotifications)
  }

  handleChange = key => e => {
    e.persist();

    this.setState(prev => ({
      data: {
        ...prev.data,
        [key]: e.target.value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    const {
      state: {
        data: {
          title,
          body
        },
        isLoading
      }
    } = this;

    if (isLoading) return;

    this.setState({ isLoading: true });

    try {
      const addNotification = await notificationsService.addNotifications({ title, body });
      this.setState(prev => ({
        notifications: [addNotification, ...prev.notifications],
      }))

    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });

    } finally {
      this.setState({ isLoading: false, data: { title: '', body: '' } });
    }

  }

  handleDeleteNtf = async id => {
    try {
      await notificationsService.removeNotification(id);
      this.setState(prev => ({
        notifications: prev.notifications.filter(i => i.id !== id),
      }))

    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });

    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSendNtf = id => {
    try {
      notificationsService.sendNotification(id);
      notifications.success({ msg: `The notification was successfully sent.` })

    } catch (err) {
      notifications.error({
        msg: 'An error occurs. Please try again later.'
      });

    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      state: {
        data,
        notifications,
        isUserInfoShowing,
        next,
      },
      loadMoreNotifications,
      handleChange,
      handleSendNtf,
      handleDeleteNtf
    } = this;
    return (
      <Grid container className="ntf-container">
        <Grid item xs={6} sm={6} className="ntf-left-container">
          <div className="ntf-title">DEFAULT NOTIFICATIONS:</div>
          {
            !isUserInfoShowing &&

            <NotificationsList
              loadMore={null}
              next={null}
              notifications={fakeItem}
              withFilters={false}
              withTitle={false} />

          }
        </Grid>

        <Grid item xs={6} sm={6}>
          <Grid container item xs={12} sm={12}>
            <form className="ntf-form" onSubmit={this.handleSubmit}>
              <div className="ntf-title">CREATE CUSTOM NOTIFICATION:</div>

              <Input
                placeholder="SUBJECT"
                name="title"
                value={data.title}
                onChange={handleChange('title')}
                fullWidth
              />

              <TextArea
                className="ntf-textarea"
                placeholder="MESSAGE..."
                name="body"
                value={data.body}
                onChange={handleChange('body')}
                fullWidth
              />

              <input type="submit" value="create" className="ntf-action-btn ntf-create-btn" />
            </form>
          </Grid>

          <Grid container item xs={12} sm={12}>
            {this.state.notifications.length ? <div className="ntf-title custom-ntf">CUSTOM NOTIFICATIONS:</div> : null}
            {
              !isUserInfoShowing ?

                (<Grid style={{ minWidth: '100%' }}>
                  <NotificationsList
                    onSend={handleSendNtf}
                    onDelete={handleDeleteNtf}
                    notifications={notifications}
                    withBtns={true}
                    withTitle={false}
                    withFilters={false}
                    loadMore={loadMoreNotifications}
                    next={next}
                  />
                </Grid>)
                : ''
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
export default Notifications;
