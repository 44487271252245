const SET_DAY = 'SET_DAY';

const setDay = day => ({
  type: SET_DAY,
  day
});

export {
  SET_DAY,
  setDay
};

export default {
  SET_DAY,
  setDay
};