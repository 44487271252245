import React from 'react'
import { Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component'
import classnames from 'classnames';

import { bool, arrayOf, shape, string, number, object, func } from 'prop-types';
import Filters from '../Filters';
import NotificationListItem from './NotificationListItem';

import './index.scss'


// const fakeItems = [{
//   id: 52,
//   res_id: 56,
//   title: 'Confirm your reservation',
//   type: 'confirm',
//   body: 'Test body',
//   created_at: 'January 27th 15:35',
//   is_read: false
// },
// {
//   id: 53,
//   res_id: 21,
//   title: 'Confirm your reservation',
//   type: 'confirm',
//   body: 'Test body',
//   created_at: 'January 27th 15:35',
//   is_read: false
// },
// {
//   id: 22,
//   res_id: 99,
//   title: 'Confirm your reservation',
//   type: 'confirm',
//   body: 'Test body',
//   created_at: 'January 27th 15:35',
//   is_read: false
// }]

class Notificationslist extends React.Component {
  state = {
    selectedNotification: null,
  }

  onNotificationClick = (notification) => {
    if (this.props.handleClick) {
      this.props.handleClick(notification)
    } else {
      this.setState({
        selectedNotification: notification,
      });
    }
  }
  render() {
    const {
      onNotificationClick,
      props: {
        notifications,
        withTitle,
        withFilters,
        withBtns,
        onSend,
        onDelete,
        parentNotification,
        loadMore,
        next
      },

      state: {
        selectedNotification
      }
    } = this;

    const activeNotification = parentNotification ? parentNotification : selectedNotification


    return (
      <div className="ntf-list">
        {withTitle && <div className="ntf-list-title">DEFAULT NOTIFICATIONS:</div>
        }

        {
          notifications.length > 1 && withFilters && (
            <div className="top-block">
              <Filters withBookingTime={false} />
            </div>
          )
        }

        <div className="ntf-list-container">

          {notifications !== null &&

            <InfiniteScroll
              dataLength={notifications.length}
              next={loadMore}
              height={'80vh'}
              hasMore={next !== null}
              style={{
                paddingRight: 20
              }}
            >
              {
                notifications.map(item =>
                  <div className={
                    classnames({
                      "ntf-list-content": true,
                      "custom-ntf": withBtns,
                    })
                  }
                    key={item.id}>
                    <NotificationListItem
                      notification={item}
                      isActive={activeNotification && activeNotification.id === item.id}
                      onClick={() => onNotificationClick(item)}
                    />
                    {
                      withBtns &&
                      (<Grid container item xs={12} sm={12} justify="flex-end" className="ntf-action-btn-block">
                        <input
                          type="submit"
                          value="delete"
                          className="ntf-action-btn ntf-delete-btn"
                          onClick={() => onDelete(item.id)}
                        />
                        <input
                          type="submit"
                          value="send"
                          className="ntf-action-btn ntf-send-btn"
                          onClick={() => onSend(item.id)}
                        />
                      </Grid>
                      )
                    }

                  </div>
                )
              }
            </InfiniteScroll>
          }

        </div>
      </div >
    )
  }
}


Notificationslist.propTypes = {
  notifications: arrayOf(shape({
    id: number,
    res_id: number,
    title: string,
    type: string,
    body: string,
    created_at: string,
    is_read: bool
  })),
  withTitle: bool,
  handleClick: func,
  withFilters: bool,
  withBtns: bool,
  onSend: func,
  onDelete: func,
  parentNotification: object,
  loadMore: func,
  next: string
};

Notificationslist.defaultProps = {
  notifications: null,
  withTitle: true,
  handleClick: null,
  withFilters: true,
  withBtns: false,
  onSend: null,
  onDelete: null,
  parentNotification: null,
  loadMore: null,
  next: null
};
export default Notificationslist