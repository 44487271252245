import React from 'react';

import classnames from 'classnames';

import './index.scss';

const Button = ({
  children,
  className = '',
  halfWidth = false,
  oneThird = false,
  fullWidth = false,
  invert = false,
  disabled = '',
  ...rest
}) => (
    <button disabled={disabled} className={
      classnames({
        button: true,
        [className]: true,
        'half-width': !!halfWidth,
        'full-width': !!fullWidth,
        'one-third': !!oneThird,
        invert
      })}
      {...rest}
    >
      {children}
      {disabled}
    </button>
  );

export default Button;
