import React, { Component } from 'react';
import { arrayOf, shape, bool, object, func, string, array, number } from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import Modal from '../Modal';

import ReservationCard from './ReservationCard';
import ReservationDetails from './ReservationDetails';

import UserInfo from '../UserInfo';
import Filters from '../Filters';
import Loader from '../Loader';

import './index.scss';

class Reservations extends Component {
  state = {
    selectedReservation: null,
    isDetailsShowing: false,
  };

  onReservationClick = reservation => () => {
    if (this.props.handleClick) {
      this.props.handleClick(reservation)
    } else {
      this.setState({
        selectedReservation: reservation,
        isDetailsShowing: true
      });
    }
  }


  onDetailsClose = () => {
    this.setState({
      isDetailsShowing: false,
      selectedReservation: null
    });
  }


  render() {
    const {
      onReservationClick,
      onDetailsClose,
      props: {
        reservations,
        onTimeChange,
        onStatusChange,
        onTypeChange,
        isLoading,
        withBookingTimes,
        openModal,
        withTitle,
        parentReservation,
        withCancelBtn,
        loadMore,
        lastEvaluatedKey,
        fromAdmin
      },
      state: {
        selectedReservation,
        isDetailsShowing,
      }
    } = this;

    const activeReservation = parentReservation ? parentReservation : selectedReservation

    return (
      <div className="reservations">
        {
          !!isDetailsShowing && openModal && (
            <Modal onClose={onDetailsClose}>
              <div className="reservation-details-container">
                <ReservationDetails withCancelBtn={withCancelBtn} onClose={onDetailsClose} reservation={selectedReservation} />
                {
                  selectedReservation && selectedReservation.user && (
                    <UserInfo
                      user={selectedReservation.user}
                      withEdit={false}
                      withRemove={false}
                    />
                  )
                }
              </div>
            </Modal>
          )
        }

        {
          withTitle && reservations !== null && (<h2 className="block-title">
            Reservations:
        </h2>)
        }

        {
          reservations !== null && (
            <div className="top-block">
              <Filters
                onTimeChange={onTimeChange}
                onTypeChange={onTypeChange}
                onStatusChange={onStatusChange}
                withBookingTimes={withBookingTimes} />
            </div>
          )
        }

        <div className="reservations-block">
          {
            isLoading && (<Loader />)
          }

          {

            !isLoading && reservations !== null && (
              <InfiniteScroll
                dataLength={reservations.length}
                next={loadMore}
                height={600}
                endMessage={reservations.length === 0 &&
                  (<p style={{ textAlign: 'center' }}>
                    <b>Nothing more!</b>
                  </p>)
                }
                hasMore={lastEvaluatedKey !== null}
                style={{
                  paddingRight: 20
                }}
              >
                {fromAdmin ?
                  reservations.map(reservation => reservation.status !== 'canceled' && (
                    <ReservationCard
                      key={reservation.res_id}
                      reservation={reservation}
                      isActive={activeReservation && activeReservation.res_id === reservation.res_id}
                      onClick={onReservationClick(reservation)}
                    />
                  ))
                  :
                  reservations.map(reservation => (
                    <ReservationCard
                      key={reservation.res_id}
                      reservation={reservation}
                      isActive={activeReservation && activeReservation.res_id === reservation.res_id}
                      onClick={onReservationClick(reservation)}
                    />
                  ))
                }
              </InfiniteScroll>
            )
          }
        </div>
      </div>
    )
  }
}

Reservations.propTypes = {
  reservations: arrayOf(shape({
    id: string,
    title: string,
    status: string,
    date: string,
    notifications: array
  })),
  isLoading: bool,
  withTitle: bool,
  openModal: bool,
  handleClick: func,
  parentReservation: object,
  page: number,
  loadMore: func,
  totalPagesNumber: number,
  fromAdmin: bool,
  onTimeChange: func,
  onStatusChange: func,
  onTypeChange: func
};

Reservations.defaultProps = {
  reservations: null,
  isLoading: false,
  withTitle: true,
  openModal: true,
  handleClick: null,
  parentReservation: null,
  page: 1,
  loadMore: null,
  totalPagesNumber: 0,
  fromAdmin: false,
  onTimeChange: () => false,
  onStatusChange: () => false,
  onTypeChange: () => false
};

export default Reservations;