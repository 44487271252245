import React, { useState } from 'react'
import { bool, func, shape, string, number } from 'prop-types';
import classnames from 'classnames';
import Button from '../../../../components/common/Button'

import ArrowIcon from '../../../../images/chevron-right_menu.svg';
import ArrowWhiteIcon from '../../../../images/chevron-right_white.svg';

import './index.scss'


const NotificationListItem = ({ notification, confirmation, onConfirm, onClick, simpleNtf, isActive }) => {

  const [aircraftNumber, setAircraftNumber] = useState('')

  return (
    <div
      key={notification.id}
      onClick={onClick}
      className={classnames({ 'card-container': true, 'active': isActive })}
    >
      <div className="card-title">{notification.title}
        {
          notification.hasOwnProperty('is_read') && !notification.is_read && <div className="read-status"></div>
        }
      </div>
      <div className="card-body">{notification.body}</div>

      {confirmation && (
        <div className="confirmation-form">
          <h2 className="confirmation-header">Aircraft registration:</h2>
          <input className="confirmation-input" onChange={(event) => setAircraftNumber(event.target.value)} />

          <Button className="confirm-button" onClick={() => onConfirm(aircraftNumber)}>CONFIRM</Button>
        </div>
      )}

      {notification.reservation_id !== null && !simpleNtf &&
        (
          <div className="notification-icon" onClick={onClick}>
            <img src={isActive ? ArrowWhiteIcon : ArrowIcon} alt="arrow" />
          </div>
        )
      }

    </div>
  )
}


NotificationListItem.propTypes = {
  confirmation: bool,
  notification: shape({
    id: number,
    res_id: number,
    title: string,
    type: string,
    body: string,
    created_at: string,
    is_read: bool
  }),
  isActive: bool,
  handleClick: func,
  onConfirm: func,
  simpleNtf: bool
};

NotificationListItem.defaultProps = {
  confirmation: false,
  notification: null,
  isActive: false,
  handleClick: () => false,
  onConfirm: null,
  simpleNtf: false

}


export default NotificationListItem;