import React from 'react';
import { connect } from 'react-redux';

import Button from '../../../common/Button'
import CloseRunway from './CloseRunway';
import ArrowNumber from '../ArrowNumber';

import { Grid } from '@material-ui/core';

import reservationActions from '../../../../redux/actions/reservation';

import { TYPES } from '../../../../helpers/reservations';

import './index.scss'

const data = [
  {
    id: 'vfr',
    title: 'VFR',
    data: [
      {
        id: TYPES.vfr_circuit,
        text: 'VFR Circuit',
        btn_text: 'CIRCUIT',
      },
      {
        id: TYPES.vfr_arrival,
        text: 'VFR Arrival',
        btn_text: 'ARRIVAL'
      },
      {
        id: TYPES.vfr_departure,
        text: 'VFR Departure',
        btn_text: 'DEPARTURE'
      }
    ]
  },
  {
    id: 'ifr',
    title: 'IFR',
    data: [
      {
        id: TYPES.ifr_training,
        text: 'IFR Training',
        btn_text: 'TRAINING'
      },
      {
        id: TYPES.ifr_arrival,
        text: 'IFR Arrival',
        btn_text: 'ARRIVAL'
      },
      {
        id: TYPES.ifr_departure,
        text: 'IFR Departure',
        btn_text: 'DEPARTURE'
      }
    ]
  },
  {
    id: 'heli',
    title: 'HELI',
    data: [
      {
        id: TYPES.helex,
        text: 'HELI Helex',
        btn_text: 'HELEX'
      },
      {
        id: TYPES.fato,
        text: 'HELI Fato',
        btn_text: 'FATO'
      }
    ]
  }
];

const Operation = ({
  selectedOperation,
  numberOfTouchAndGo,
  currentUser,
  customDuration,
  setSelectedOperation,
  decreaseTouchAndGo,
  increaseTouchAndGo,
  decreaseCustomDuration,
  increaseCustomDuration,
  handleCloseRunway,
  closeRunway
}) => {
  return (
    <div className="operations-body">
      <div className="operation-title-block">
        <div className="operation-title">Choose your operation!</div>

        {
          currentUser && currentUser.role === 'airport' && (
            <Button
              className="operation-close-button"
              invert={!closeRunway}
              onClick={() => handleCloseRunway(true)}
            >
              CLOSE RUNWAY
            </Button>
          )
        }
      </div>

      {currentUser && currentUser.role !== 'airport' &&
        <Grid container item justify="center" xs={12} className="operation-cards">
          {
            data.map(item => (
              <div key={item.id} className="operation-btn-block">
                <div className="operation-card-title">{item.title}</div>

                {
                  item.data.map(item => (
                    <Button
                      key={item.id}
                      className="operation-card-btn"
                      invert={!selectedOperation || (item.id !== selectedOperation.id)}
                      onClick={() => setSelectedOperation(item)}
                    >
                      {item.btn_text}
                    </Button>
                  ))
                }
              </div>
            ))
          }
        </Grid>}

      {
        selectedOperation !== null && [TYPES.vfr_circuit, TYPES.ifr_training].includes(selectedOperation.id) && (
          <ArrowNumber
            readOnly
            text="NUMBER OF TOUCH-AND-GO’S:"
            min={1}
            value={numberOfTouchAndGo}
            decrease={decreaseTouchAndGo}
            increase={increaseTouchAndGo}
          />
        )
      }

      {
        selectedOperation !== null && [TYPES.helex, TYPES.fato].includes(selectedOperation.id) && (
          <ArrowNumber
            readOnly
            text="DURATION OF OPERATION (MINUTES):"
            min={5}
            max={120}
            value={customDuration}
            decrease={decreaseCustomDuration}
            increase={increaseCustomDuration}
          />
        )
      }

      {
        closeRunway && !selectedOperation && (
          <CloseRunway />
        )
      }
    </div>
  );
}

const mapStateToProps = ({
  reservation: {
    selectedOperation,
    numberOfTouchAndGo,
    customDuration,
    closeRunway
  },
  account: { currentUser }
}) => ({
  selectedOperation,
  numberOfTouchAndGo,
  currentUser,
  customDuration,
  closeRunway
});

const mapDispatchToProps = dispatch => ({
  setSelectedOperation: operation => dispatch(reservationActions.setSelectedOperation(operation)),
  decreaseTouchAndGo: () => dispatch(reservationActions.decreaseTouchAndGo()),
  increaseTouchAndGo: () => dispatch(reservationActions.increaseTouchAndGo()),
  decreaseCustomDuration: () => dispatch(reservationActions.decreaseCustomDuration()),
  increaseCustomDuration: () => dispatch(reservationActions.increaseCustomDuration()),
  handleCloseRunway: value => dispatch(reservationActions.closeRunway(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Operation);
