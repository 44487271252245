import {
  SET_IS_MAIN_CONTENT_BLURRED
} from '../actions/modal';

const initialState = {
  isMainContentBlurred: false
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MAIN_CONTENT_BLURRED:
      return {
        ...state,
        isMainContentBlurred: action.isMainContentBlurred
      };
    default:
      return state;
  }
};

export default modalReducer;