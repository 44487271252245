import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { node, string, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import modalActions from '../../../redux/actions/modal';

import CloseIcon from '../../../images/chevron-right-x.svg';

import './index.scss';

class Modal extends Component {
  componentDidMount() {
    this.props.setIsMainContentBlurred(true);
  }

  componentWillUnmount() {
    this.props.setIsMainContentBlurred(false);
  }

  render() {
    const {
      props: {
        children,
        onClose,
        className,
        withCloseBtn
      }
    } = this;

    return ReactDOM.createPortal((
      <div className="modal-container" onClick={onClose} >
        <div
          className={
            classnames({
              modal: true,
              [className]: !!className
            })
          }
          onClick={e => e.stopPropagation()}
        >
          {children}

          {
            withCloseBtn && (
              <div className="close-button" onClick={onClose}>
                <img src={CloseIcon} alt="close-icon" />
              </div>
            )
          }
        </div>
      </div >
    ),
      document.getElementById('root')
    );
  }
}

Modal.propTypes = {
  children: node.isRequired,
  className: string,
  onClose: func.isRequired,
  withCloseBtn: bool
};

Modal.defaultProps = {
  className: '',
  withCloseBtn: true
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setIsMainContentBlurred: modalActions.setIsMainContentBlurred }, dispatch);

export default connect(null, mapDispatchToProps)(Modal);
