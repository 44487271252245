import React, { Component } from 'react';
import { arrayOf, func, number, shape, string, date, object } from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { isBetween } from '../../../helpers/common';

import ArrowLeftIcon from '../../../images/user-chevron-left.svg';
import ArrowRightIcon from '../../../images/user-chevron-right.svg';

import { getUserRoleFromCookies } from '../../../helpers/auth';

import { TYPES } from '../../../helpers/reservations';

import './index.scss';

let COLUMN_HEIGHT = 200;

const PER_PAGE = 30;

const partlySelectedContainerStyle = percentage => ({
  background: `linear-gradient(to right, rgba(81, 36, 121, 0.2) ${percentage}%, rgba(216, 216, 216, 0.4) ${percentage}%`,
});

const partlySelectedInnerStyle = percentage => ({
  background: `linear-gradient(to right, #512479 ${percentage}%, rgba(28, 100, 95, 0.9) ${percentage}%`
});

class TimePicker extends Component {
  state = {
    userRole: null,
    times: this.props.times,
    levels: this.props.levels,
    skip: 0,
    selectedTime: this.props.times.length ? this.props.times[0].slice(0, 5) : null,
    lastVisibleTime: null
  };

  componentDidMount() {
    const userRole = getUserRoleFromCookies()

    const width = window.innerWidth

    COLUMN_HEIGHT = width <= 1366 ? 150 : 200

    this.setState(prev => ({ times: prev.times.map(t => t.slice(0, 5)), userRole }))
  }

  previousPage = () =>
    this.setState(prev => prev.skip === 0 ? null : ({ skip: prev.skip - 12 }));

  nextPage = () => {
    const { times, skip } = this.state
    const visibleTimes = times.slice(skip, skip + PER_PAGE);

    this.setState({ lastVisibleTime: visibleTimes.slice(-1)[0] })
    this.setState(prev =>
      prev.lastVisibleTime === prev.times.slice(-1)[0] ? null : ({ skip: prev.skip + 12 })
    );
  }


  selectTime = time => {
    const { customDuration, utilization, selectedOperation } = this.props

    const withCustomDuration = selectedOperation !== null && [TYPES.helex, TYPES.fato].includes(selectedOperation.id);

    let duration = withCustomDuration ? customDuration : Math.ceil(utilization.operation_duration / 60)

    let [hours, mins] = time.slice(0, 5).split(':')

    mins = parseInt(mins) + (parseInt(hours) * 60)

    let endReservationMinutes = parseInt(mins) + parseInt(duration)

    let endHours = Math.floor(endReservationMinutes / 60);
    let endMinutes = endReservationMinutes % 60;

    const closeTime = moment(utilization.closing_time, 'HH:mm').format('kk:mm')
    let endReservationTime = moment().hours(endHours).minutes(endMinutes).format('kk:mm')

    if (moment(endReservationTime, 'HH:mm').isAfter(moment(closeTime, 'HH:mm'))) {
      let [closeHours, closeMinutes] = closeTime.split(':')
      let closeMinsTime = parseInt(closeMinutes) + (parseInt(closeHours) * 60)

      let startMinutesTime = parseInt(closeMinsTime) - parseInt(duration)

      let remainder = startMinutesTime % 5

      startMinutesTime -= remainder

      let startHours = Math.floor(startMinutesTime / 60);
      let startMinutes = startMinutesTime % 60;

      time = `${startHours}:${startMinutes}`
    }

    this.setState({ selectedTime: time }, () => this.props.onTimeSelect(time))
  };


  render() {
    const {
      previousPage,
      nextPage,
      selectTime,
      props: {
        timeStep,
        duration,
        disabledTimes
      },
      state: {
        levels,
        times,
        skip,
        selectedTime
      },
    } = this;


    const visibleLevels = levels.slice(skip, skip + PER_PAGE);
    const visibleTimes = times.slice(skip, skip + PER_PAGE);

    const startIndex = times.findIndex(t => t === selectedTime);

    const fullPaintedBlocksLength = Math.floor(duration / timeStep);
    const fullPaintedEndIndex = startIndex + fullPaintedBlocksLength - 1;
    const partlyPaintedPercentage = ((duration % timeStep) / timeStep) * 100;

    const getFullIndex = partIndex => skip + partIndex;

    return (
      <div className="time-picker-container">
        <div className="time-picker-header">
          <img
            src={ArrowLeftIcon}
            alt="left"
            className={classnames({ arrow: true, disabled: skip === 0 })}
            onClick={previousPage}
          />

          <p className="selected-time">{selectedTime}</p>

          <img
            src={ArrowRightIcon}
            alt="right"
            className={classnames({ arrow: true, disabled: visibleTimes.slice(-1)[0] === times.slice(-1)[0] })}
            onClick={nextPage}
          />
        </div>

        <div className="time-picker" style={{ height: COLUMN_HEIGHT }}>
          {
            visibleLevels.map((level, index, arr) => {
              const isDisabled = index => disabledTimes
                .some(t => {
                  if (visibleTimes[index] === undefined) {
                    return false
                  }
                  const [hours, mins] = visibleTimes[index].slice(0, 5).split(':')

                  const startTime = moment(t.from).utc()
                  const endTime = moment(t.to).utc()
                  const time = moment(startTime).hours(hours).minutes(mins)

                  return moment(time).isSame(startTime) || moment(time).isBetween(startTime, endTime)
                });


              const isColumnDisabled = isDisabled(index);

              // const isLastDurationColumn = isDisabled(index - 1 + Math.ceil(duration / timeStep));

              const isPartlySelected = (fullPaintedEndIndex + 1 === getFullIndex(index)) && !!partlyPaintedPercentage;

              const isRoundRightCorner = index !== arr.length - 1 && arr[index + 1] < level;

              const isRoundLeftCorner = index !== arr.length + 1 && arr[index - 1] < level;

              return (
                <div
                  key={index}
                  className={
                    classnames({
                      disabled: isColumnDisabled,
                      selected: isBetween(startIndex, fullPaintedEndIndex)(getFullIndex(index)),
                      'partly-selected': !isColumnDisabled && isPartlySelected,
                      'time-picker-column-container': true,
                    })
                  }
                  style={!isColumnDisabled && isPartlySelected ? partlySelectedContainerStyle(partlyPaintedPercentage) : {}}
                  title={`${visibleTimes[index]}: ${isColumnDisabled ? 'closed' : `${level * 100}%`}`}
                  onClick={() => selectTime(visibleTimes[index])}
                >
                  <div
                    className="time-picker-column"
                    style={{ height: COLUMN_HEIGHT }}
                  >
                    <div
                      className={classnames({ "time-picker-column-inner": true, 'rounded-right': isRoundRightCorner, 'rounded-left': isRoundLeftCorner })}
                      style={{
                        height: isColumnDisabled ? 0 : COLUMN_HEIGHT * level,
                        ...(
                          isPartlySelected
                            ? partlySelectedInnerStyle(partlyPaintedPercentage)
                            : {}
                        ),
                      }}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="time-line-container">
          <div className="time-line" />
          <div className="label-lines">
            {
              visibleTimes.map((time, index) => (
                <div key={time} className="label-container">
                  <div className="label-line" />
                  <div className="label">{index % 3 === 0 ? time : ''}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

TimePicker.propTypes = {
  times: arrayOf(string),
  levels: arrayOf(number),
  timeStep: number,
  duration: number,
  disabledTimes: arrayOf(shape({ from: string, to: string })),
  onTimeSelecT: func,
  customDuration: number,
  selectedDay: date,
  utilization: object,
  selectedOperation: object
};

TimePicker.defaultProps = {
  times: [],
  levels: [],
  timeStep: 300,
  duration: 0,
  disabledTimes: [],
  onTimeSelect: () => false,
  customDuration: '',
  selectedDay: '',
  utilization: null,
  selectedOperation: null
};

export default TimePicker;
