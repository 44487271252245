import React from 'react';

import ThankYouImage from '../../../images/thank-you.png';

import './index.scss';

const ThankYou = ({title, subtitle, text}) => (
  <div className="thank-you-container">
    <div className="inner-container">
      <div className="head-text">
        {title || 'THANK YOU!'}
      </div>
      <div className="description">
        {subtitle || 'We will send you a magic link to your email address'}
      </div>
      <div className="additional-text">{text || ''}</div>
      <div className="image-container">
        <img src={ThankYouImage} alt='thank'/>
      </div>
    </div>
  </div>
);

export default ThankYou;
