import React from 'react';

import ThankYou from '../../../components/common/ThankYou';
import Loader from '../../../components/common/Loader';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';

import accountService from '../../../services/account';

import notifications from '../../../utils/notifications';

import './index.scss';

class PilotLogIn extends React.Component {
  state = {
    email: '',
    isLinkWasSent: false,
    isLinkSending: false
  }

  handleChange = key => e => this.setState({ [key]: e.target.value });

  handleSubmit = async e => {
    e.preventDefault();

    const {
      state: {
        email,
        isLinkSending
      }
    } = this;

    if (isLinkSending) return;

    this.setState({ isLinkSending: true });

    try {
      await accountService.logIn({ email });
      this.setState({ isLinkWasSent: true });
    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });
    } finally {
      this.setState({ isLinkSending: false });
    }
  }

  render() {
    const {
      handleChange,
      handleSubmit,
      state: {
        email,
        isLinkWasSent,
        isLinkSending
      }
    } = this;

    if (isLinkWasSent) return (<ThankYou />);

    return (
      <div className="pilot-login-container">
        <div className="items-block">
          <div className="welcome-text">Welcome!</div>
          <div className="info-text">We will send a magic link to your email address</div>

          <form className="form-block">
            <div>
              <Input
                placeholder="EMAIL"
                type="text"
                name="email"
                value={email}
                onChange={handleChange('email')}
                fullWidth
              />
            </div>
            <div>
              <Button
                className="send-button"
                onClick={handleSubmit}
              >
                {
                  isLinkSending ? (<Loader />) : 'SEND'
                }
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}


export default PilotLogIn;
