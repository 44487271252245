import React from 'react';
import { reservationTypes } from '../../../../helpers/reservations'
import Loader from '../../../../components/common/Loader';

import Grid from '@material-ui/core/Grid';

import './index.scss';

const ReservationsInfoColumn = ({ data }) => (
  <Grid item xs={4}>
    {
      data.map(({ key, value }) => (
        <Grid container spacing={2} key={key}>
          <Grid item xs={10} className="key">{key}</Grid>
          <Grid item xs={2} className="value">{value}</Grid>
        </Grid>
      ))
    }
  </Grid>
)

const ReservationsInfo = ({ isLoading, data }) => {
  console.log(isLoading)
  let vfrOperations = []
  let ifrOperations = []
  let otherOperations = []

  for (const operationId in data) {
    if (['larsa-op-1', 'larsa-op-2', 'larsa-op-3'].includes(operationId)) {
      vfrOperations.push({ key: reservationTypes[operationId], value: data[operationId] })
    }
    if (['larsa-op-4', 'larsa-op-5', 'larsa-op-6'].includes(operationId)) {
      ifrOperations.push({ key: reservationTypes[operationId], value: data[operationId] })
    }
    if (['larsa-op-7', 'larsa-op-8', 'larsa-op-9'].includes(operationId)) {
      otherOperations.push({ key: reservationTypes[operationId], value: data[operationId] })
    }
  }
  return (

    <div className="reservations-info" >
      {!isLoading && (<h6 className="title">
        {`total number of reservations: ${data.total_count}`}
      </h6>)}

      {isLoading && <Loader />}

      {!isLoading && (<Grid container spacing={2} className="info">
        <ReservationsInfoColumn data={vfrOperations} />
        <ReservationsInfoColumn data={ifrOperations} />
        <ReservationsInfoColumn data={otherOperations} />
      </Grid>
      )}
    </div >
  )
};

export default ReservationsInfo;
