import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import './index.scss'

const MyCustomSwitch = withStyles({
  root: {
    height: '42px',
    width: '62px',
    borderRadius: '20px',
    padding: 0,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  switchBase: {
    color: 'background-color: rgba(0,0,0,0.2);',
    padding: 1,
    '&$checked': {
      color: '#512479',
    },
    '&$checked + $track': {
      backgroundColor: '#512479',
    },
  },
  checked: {},
  track: {},
  thumb: {
    boxShadow: '1px 1px 2px 0 rgba(0,0,0,0.5)',
    height: '37px',
    borderRadius: '18.5px',
    width: '37px',
    padding: 0,
  },

})(Switch);

const MySwitch = ({
  onChange,
  checked,
  value
}) => {

  return (
    <div className="switch-close-runway-container">
      <div className="close-runway-switch-block">
        <FormControlLabel
          control={
            <MyCustomSwitch
                checked={checked}
                onChange={onChange}
                value={value}
            />
            }
            label={value}
            labelPlacement="start"
          />
      </div>
    </div>
  )}

export default MySwitch;
