import axios from 'axios';

const getWeather = async () => {
  const {
    data
  } = await axios.get('/weather/');

  return data;
}

export default {
  getWeather
};