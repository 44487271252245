import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';

import Calendar from '../../../Calendar';
import MySwitch from '../../../common/MySwitch';
import Loader from '../../../common/Loader';

import reservationActions from '../../../../redux/actions/reservation';
import reservationServices from '../../../../services/reservations';
import { getLandingAreaId } from '../../../../helpers/reservations';
import { closeOperations } from '../../../../helpers/reservations';


import './index.scss'

class ChosenDay extends Component {
  state = {
    landingAreaIds: [],
  }

  componentDidMount() {
    // TODO add next years support
    const {
      props: {
        selectedOperation,
        getClosedDays,
        selectedAreas,
        closeRunway
      }
    } = this;

    let landingAreaIds = [];
    if (!closeRunway) {
      landingAreaIds.push(getLandingAreaId(selectedOperation.id))
    } else {

      landingAreaIds = selectedAreas.map(area => {
        if (area === 'close_helex') return getLandingAreaId('helex')
        if (area === 'close_fato') return getLandingAreaId('fato');

        return getLandingAreaId(area);
      });

    }
    this.setState({ landingAreaIds })

    getClosedDays({
      landingAreaIds: [...landingAreaIds],
      from_date: moment(),
      to_date: moment().add(1, 'year')
    });
  }

  componentWillUnmount() {
    const {
      props: {
        closeWholeDayVal,
        setActiveStep,
      }
    } = this
    if (closeWholeDayVal) {
      setActiveStep(4)
    }
  }


  render() {
    const {
      state: {
        landingAreaIds
      },
      props: {
        selectedDay,
        selectedOperation,
        closedDays,
        selectedAreas,
        isClosedDaysLoading,
        closeWholeDay,
        setSelectedDay,
        previousStep,
        closeRunway,
        closeWholeDayVal
      }
    } = this;

    return (
      <div id="chose-day-container">
        <div className="operations-body">
          <div className="operation-title-block">
            <div className="operation-title chose-day-title">
              <div className="back-arrow" onClick={previousStep}></div>
              <div className="chose-day-title-text">Choose your day!</div>
            </div>
            <div className="chose-day-subtitle"><h3>UTC time (ZULU time)</h3></div>
          </div>
        </div>

        {
          isClosedDaysLoading ? (<Loader />) : (
            <>
              <Grid container item xs={9} justify="center" className="chose-day-calendar">
                <Calendar
                  handleDayClick={(day, { disabled }) => !disabled && setSelectedDay(day)}
                  selectedDay={selectedDay}
                  disabledDays={closedDays.map(d => landingAreaIds.includes(d.landing_area_id) && moment(d.date).toDate())}
                />
              </Grid>

              {
                closeRunway && (
                  <Grid container item xs={12} justify="center">
                    <MySwitch checked={closeWholeDayVal} onChange={() => closeWholeDay()} value='Close whole day' />
                  </Grid>
                )
              }

              <Grid container item xs={12} justify="center" className="chose-day-info-block">
                <ul>
                  <li className="text">Operation: {(selectedOperation && selectedOperation.text) || (closeRunway ? selectedAreas.map(a => closeOperations[a]).join(', ') : '')}</li>
                  <li className="text-bold">Selected Day: {moment(selectedDay).format('MMMM Do YYYY')}</li>
                </ul>
              </Grid>
            </>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({
  reservation: {
    selectedDay,
    selectedOperation,
    closedDays,
    isClosedDaysLoading,
    closeRunway,
    selectedAreas,
    closeWholeDayVal
  }
}) => ({
  selectedDay,
  selectedOperation,
  closedDays,
  selectedAreas,
  isClosedDaysLoading,
  closeRunway,
  closeWholeDayVal
});

const mapDispatchToProps = dispatch => ({
  setSelectedDay: day => dispatch(reservationActions.setSelectedDay(day)),
  previousStep: () => dispatch(reservationActions.previousStep()),
  getClosedDays: (...props) => dispatch(reservationServices.getClosedDays(...props)),
  closeWholeDay: () => dispatch(reservationActions.closeWholeDay()),
  setActiveStep: step => dispatch(reservationActions.setActiveStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChosenDay);