const icons = {
  'clear sky': 'web_sunny_small.png',
  'few clouds': 'web_sunny_small.png',
  'scattered clouds': 'web_stormy_small.png',
  'broken clouds': 'web_stormy_small.png',
  'shower rain': 'weather_web_rain.png',
  'rain': 'weather_web_rain.png',
  'thunderstorm': 'weather_web_stormy.png',
  'snow': 'weather_web_snow.png',
  'mist': 'weather_web_fog.png',
  'light rain': 'weather_web_rain.png',
  'overcast clouds': 'web_sunny_small.png',
  'moderate rain': 'weather_web_rain.png'
};

const iconsHandler = {
  get: function (target, prop) {
    return target.hasOwnProperty(prop) ? target[prop] : 'web_sunny_small.png'
  }
}

const ICONS_TYPES = new Proxy(icons, iconsHandler);

export {
  ICONS_TYPES
};

export default {
  ICONS_TYPES
};