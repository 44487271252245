const makeApiWithQuery = (api, queryConfig) => { // or makeApiWithQuery(config)
  if (!queryConfig) {
    queryConfig = api;
    api = '';
  }
  const keys = Object.keys(queryConfig);
  if (!keys.length) return api || '';
  return keys
    .reduce(
      (url, key, i) => queryConfig[key] === undefined
        ? url
        : `${url}${i === 0 ? '' : '&'}${key}=${queryConfig[key]}`,
      `${api}?`
    );
};

export {
  makeApiWithQuery
};

export default {
  makeApiWithQuery
};
