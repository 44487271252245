import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import Reservations from '../../../components/common/Reservations';
import UserInfo from '../../../components/common/UserInfo';
import ConfirmModal from '../../../components/common/modals/ConfirmModal';
import Loader from '../../../components/common/Loader';

import accountActions from '../../../redux/actions/account';

import accountService from '../../../services/account';
import reservationsService from '../../../services/reservations'

import notifications from '../../../utils/notifications'

import TwoArrowsIcons from '../../../images/chevron-right-chevrons-right.svg';

import './index.scss';

class Account extends React.Component {
  state = {
    isConfirmModalShow: false,
    isDeleting: false,
    scrolling: false,
    lastEvaluatedKey: null,
    isReservationsLoading: false,
    reservationsHistory: [],
    types: [],
    statuses: [],
    order: null
  }

  async componentDidMount() {
    const reservations = await this.getReservationsHistory()

    this.setState({ reservationsHistory: reservations })
    console.log(this.state.isConfirmModalShow)
  }

  deleteProfile = async () => {
    const { setCurrentUser, history } = this.props

    this.setState({ isDeleting: true })

    try {
      await accountService.deleteProfile();

      setCurrentUser(null);

      history.push('/pilot/login/')
    } catch (err) {
      notifications.error(err.message);

      this.setState({ isDeleting: false })
    }
  };

  getReservationsHistory = async () => {
    const { statuses, types, order, lastEvaluatedKey, reservationsHistory } = this.state

    try {
      const { result } = await reservationsService.getAccountReservations({ when: 'past', statuses, types, order, lastEvaluatedKey })

      this.setState({
        isReservationsLoading: false,
        reservationsHistory: [...reservationsHistory, ...result.reservations],
        lastEvaluatedKey: result.last_evaluated_key,
        scrolling: false
      })
      return result.reservations
    } catch (error) {
      console.log(error)
      return []
    }

  }

  loadMoreReservations = () => {
    this.setState({ scrolling: true }, this.getReservationsHistory)
  }

  getFilteredReservations = async (filters) => {
    try {
      const { result } = await reservationsService.getAccountReservations({ when: 'past', ...filters })
      this.setState({
        reservationsHistory: result.reservations,
        lastEvaluatedKey: result.last_evaluated_key,
        scrolling: false,
        ...filters
      })
    } catch (error) {
      console.log(error)
    }
    this.setState({ isReservationsLoading: false })
  }

  onStatusChange = async (statuses) => {
    const { order, types } = this.state

    this.setState({ isReservationsLoading: true })
    this.getFilteredReservations({ statuses, order, types })
  }

  onTimeChange = async (item) => {
    const { types, statuses } = this.state

    this.setState({ isReservationsLoading: true })
    this.getFilteredReservations({ statuses, order: item, types })
  }

  onTypeChange = async (types) => {
    const { order, statuses } = this.state

    this.setState({ isReservationsLoading: true })
    this.getFilteredReservations({ statuses, order, types })
  }


  render() {
    const {
      onTimeChange,
      onTypeChange,
      onStatusChange,
      loadMoreReservations,
      deleteProfile,
      state: {
        isConfirmModalShow,
        isReservationsLoading,
        lastEvaluatedKey,
        reservationsHistory,
        isDeleting
      },
      props: {
        currentUser,
        setCurrentUser
      }
    } = this

    return (
      <div>
        <Grid container item sm={9} md={9} lg={9} className="account-container" >
          <Grid item xs={12} md={5} lg={6}>
            <h2 className="title">PERSON DETAILS:</h2>

            <div className="details-block">
              <UserInfo
                user={currentUser}
                withRemove={false}
                withTitle={false}
                edit={accountService.editProfile}
                onSuccessEdit={user => setCurrentUser(user)}
              />
            </div>

            <div className="footer">
              <p className="delete-button" onClick={() => this.setState({ isConfirmModalShow: true })}>
                Delete account <img alt="arrows" className="arrows-icon" src={TwoArrowsIcons} />
              </p>
            </div>
          </Grid>

          <Grid item md={1} />

          <Grid item md={5}>
            <h2 className="title">RESERVATIONS HISTORY:</h2>

            <div className="reservations-block">

              <Reservations
                withBookingTimes={false}
                lastEvaluatedKey={lastEvaluatedKey}
                onStatusChange={onStatusChange}
                isLoading={isReservationsLoading}
                onTimeChange={onTimeChange}
                onTypeChange={onTypeChange}
                withTitle={false}
                loadMore={loadMoreReservations}
                reservations={reservationsHistory}
                withCancelBtn={false}
              />

            </div>
          </Grid>
        </Grid>

        {
          isConfirmModalShow && (
            <ConfirmModal
              text="This operation cannot be undone"
              onAccept={isDeleting ? false : deleteProfile}
              onClose={() => this.setState({ isConfirmModalShow: false })}
              onReject={() => this.setState({ isConfirmModalShow: false })}
              acceptButtonText={isDeleting ? (<Loader />) : 'YES, DELETE MY ACCOUNT'}
              rejectButtonText="NO, KEEP IT"
            />
          )
        }
      </div>
    );
  }

}

const mapStateToProps = ({
  account: {
    currentUser,
    reservations,
    isReservationsLoading
  } }) => ({
    currentUser,
    reservations,
    isReservationsLoading
  });

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(accountActions.setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
