import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Grid from '@material-ui/core/Grid'

import Button from '../../../components/common/Button';
import Filters from '../../../components/common/Filters';
import Loader from '../../../components/common/Loader';
import Modal from '../../../components/common/Modal';
import Reservations from '../../../components/common/Reservations';
import reservationsService from '../../../services/reservations'

import UserInfo from '../../../components/common/UserInfo';
import InviteUser from './InviteUser';

import SettingsIcon from '../../../images/ic_settings.svg';
import SettingsWhiteIcon from '../../../images/ic_settings-white.svg';
import ArrowIcon from '../../../images/chevron-right_menu.svg'
import ArrowWhiteIcon from '../../../images/chevron-right_white.svg'

import userService from '../../../services/user';

import './index.scss';

// const getRandomNumber = (min, max) =>
//   Math.floor(Math.random() * (max - min + 1) + min);

// const getFakeReservations = () => new Promise(resolve => {
//   setTimeout(() => resolve([]), getRandomNumber(100, 1200));
// });

class Users extends Component {
  state = {
    users: [],
    reservations: [],
    selectedUserId: null,
    isUsersLoading: true,
    isReservationsLoading: false,
    isUserInfoShowing: false,
    isInviteMode: false,
    offset: 0,
    next: null,
    lastEvaluatedKey: null,
    scrolling: false,
    order: null,
    statuses: [],
    types: [],
  };

  async componentDidMount() {
    const users = await this.getUsers()

    this.setState({ isUsersLoading: false, users });
  }


  getUsers = async () => {
    const { offset, users } = this.state
    const { next, results } = await userService.getUsers({ order: 'ASC', limit: 10, offset });

    this.setState({ isUsersLoading: false, next, scrolling: false, users: [...users, ...results] });

    return results
  }

  loadMoreUsers = () => {
    this.setState(prevState => ({
      offset: prevState.offset + 10,
      scrolling: true
    }), this.getUsers)
  }

  loadMoreReservations = async () => {
    const { selectedUserId, lastEvaluatedKey, reservations, order, statuses, types } = this.state

    this.setState({
      scrolling: true
    })

    try {
      const { result } = await reservationsService.getUserReservations(selectedUserId, { lastEvaluatedKey, order, statuses, types });

      this.setState({
        isReservationsLoading: false,
        scrolling: false,
        lastEvaluatedKey: result.last_evaluated_key,
        reservations: [...reservations, ...result.reservations]
      }, () => Promise.resolve());
    } catch (error) {
      console.log(error)
    }
  }

  getUserReservations = async (id) => {
    const { order, statuses, types } = this.state

    try {
      const { result } = await reservationsService.getUserReservations(id, { order, statuses, types });

      this.setState({ reservations: result.reservations, lastEvaluatedKey: result.last_evaluated_key, isLoading: false, isReservationsLoading: false })

      return result.reservations
    } catch (error) {
      console.log(error)
    }
  }

  select = async id => {
    this.setState({ isReservationsLoading: true, scrolling: true, selectedUserId: id });
    this.getUserReservations(id);
  }

  onUserSettingsClick = id => async e => {
    e.stopPropagation();

    this.select(id);

    this.setState({ selectedUserId: id, isUserInfoShowing: true });
  }

  closeUserInfo = () => new Promise(resolve =>
    this.setState({ isUserInfoShowing: false }, () => resolve())
  );

  onSuccessEdit = editedUser =>
    this.setState(prev => ({
      users: prev.users.map(user => user.id === editedUser.id ? editedUser : user)
    }));

  onSuccessRemove = id => this.setState(prev => ({
    users: prev.users.filter(user => user.id !== id)
  }));

  toggleInviteMode = (status = null) =>
    this.setState(prev => ({ isInviteMode: status === null ? !prev.isInviteMode : status }));

  onStatusChange = async (statuses) => {
    const { selectedUserId } = this.state

    this.setState({ isReservationsLoading: true, statuses }, () => this.getUserReservations(selectedUserId))
  }

  onTimeChange = async (item) => {
    const { selectedUserId } = this.state

    this.setState({ isReservationsLoading: true, order: item }, () => this.getUserReservations(selectedUserId))
  }

  onTypeChange = async (types) => {
    const { selectedUserId } = this.state

    this.setState({ isReservationsLoading: true, types }, () => this.getUserReservations(selectedUserId))
  }

  render() {
    const {
      select,
      onUserSettingsClick,
      onStatusChange,
      onTypeChange,
      onTimeChange,
      closeUserInfo,
      onSuccessEdit,
      onSuccessRemove,
      toggleInviteMode,
      loadMoreUsers,
      loadMoreReservations,
      state: {
        users,
        reservations,
        selectedUserId,
        isUsersLoading,
        isReservationsLoading,
        isUserInfoShowing,
        isInviteMode,
        next,
        lastEvaluatedKey
      }
    } = this;

    return (
      <div className="users-container">
        {
          isInviteMode && (
            <Modal onClose={() => toggleInviteMode(false)}>
              <InviteUser onSuccessInvite={() => toggleInviteMode(false)} />
            </Modal>
          )
        }

        {
          isUserInfoShowing && (
            <Modal onClose={closeUserInfo}>
              <div className="user-info-container">
                <UserInfo
                  wideMode
                  user={users.find(u => u.id === selectedUserId)}
                  close={closeUserInfo}
                  onSuccessEdit={onSuccessEdit}
                  onSuccessRemove={onSuccessRemove}
                />
              </div>
            </Modal>
          )
        }

        <Grid container spacing={8}>
          <Grid item sm={12} md={6}>
            <h2 className="block-title">
              Users
            </h2>

            <div className="top-block">
              <Button className="add-user-btn" invert onClick={toggleInviteMode}>ADD USER</Button>
              {/* 
              {
                !isUsersLoading && !!users.length && (<Filters />)
              } */}
            </div>

            <div className="users-block">
              {
                isUsersLoading && (<Loader />)
              }

              {

                !isUsersLoading &&
                <InfiniteScroll
                  dataLength={users.length}
                  next={loadMoreUsers}
                  height={'70vh'}
                  hasMore={next !== null}
                  style={{
                    paddingRight: 20
                  }}
                >
                  {
                    users.map(user => (
                      <div
                        key={user.id}
                        className={`user-card ${user.id === selectedUserId ? 'active' : ''}`}
                        onClick={() => select(user.id)}
                      >
                        <div className="description-block">
                          <p className="username">
                            {
                              (user.first_name || user.last_name)
                                ? `${user.first_name} ${user.last_name}`
                                : 'Anonymous User'
                            }
                          </p>
                          <p className="role">general user</p>
                        </div>
                        <div className="actions-block">
                          <div className="settings-icon" onClick={onUserSettingsClick(user.id)}>
                            {
                              user.id === selectedUserId ? (
                                <img src={SettingsWhiteIcon} />
                              ) : (
                                  <img src={SettingsIcon} />
                                )
                            }
                          </div>
                          <div className="arrow-icon">
                            {
                              user.id === selectedUserId ? (
                                <img src={ArrowWhiteIcon} />
                              ) : (
                                  <img src={ArrowIcon} />
                                )
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </InfiniteScroll>
              }
            </div>
          </Grid>

          {
            selectedUserId && (
              <Grid item sm={12} md={6}>
                <Reservations
                  withBookingTimes={false}
                  onStatusChange={onStatusChange}
                  onTypeChange={onTypeChange}
                  onTimeChange={onTimeChange}
                  lastEvaluatedKey={lastEvaluatedKey}
                  loadMore={loadMoreReservations}
                  reservations={reservations}
                  isLoading={isReservationsLoading} />
              </Grid>
            )
          }
        </Grid>
      </div>
    );
  }
}

export default Users;
