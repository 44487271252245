const SET_CURRENT_USER = 'ACCOUNT/SET_CURRENT_USER';
const SET_RESERVATIONS = 'ACCOUNT/SET_RESERVATIONS';
const SET_IS_RESERVATIONS_LOADING = 'ACCOUNT/SET_IS_RESERVATIONS_LOADING';

const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  user
});

const setReservations = reservations => ({
  type: SET_RESERVATIONS,
  reservations
});

const setIsReservationsLoading = isLoading => ({
  type: SET_IS_RESERVATIONS_LOADING,
  isLoading
});

export {
  SET_CURRENT_USER,
  SET_RESERVATIONS,
  SET_IS_RESERVATIONS_LOADING,
  setCurrentUser,
  setReservations,
  setIsReservationsLoading
};

export default {
  SET_CURRENT_USER,
  SET_RESERVATIONS,
  SET_IS_RESERVATIONS_LOADING,
  setCurrentUser,
  setReservations,
  setIsReservationsLoading
};
