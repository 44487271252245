import axios from 'axios';

import accountActions from '../redux/actions/account';

import { removeAllTokens } from '../helpers/auth';
import { RESERVATIONS_TYPES } from '../helpers/reservations';

const logIn = async data => {
  const res = await axios.post('/users/login/', {
    ...data,
    platform: 'web'
  });

  return res.data;
}

const adminSignUp = async data => {
  const res = await axios.post('/users/register/',
    {
      ...data,
      platform: 'web'
    });

  return res.data;
}
const adminLogIn = async data => {
  const res = await axios.post('/auth/admin-login/', data);

  return res.data;
}

const exchangeToken = async token => {
  const { data } = await axios.post('/token/exchange/', { token });

  return data;
};

const getCurrentUser = async () => {
  const { data } = await axios.get('/users/profile/');

  return data;
}

const updateToken = async token => {
  const { data } = await axios.post('/token/refresh/', { refresh: token })

  return data;
}

const editProfile = async (model = {}) => {
  const res = await axios.put(`/users/profile/`, model);

  return res.data;
};

const deleteProfile = async () => {
  await axios.delete('/users/profile');

  removeAllTokens();
};


const getProfileReservationsHistory = ({page_number = 1, results_per_page=10, order="ASC"} = {}) => async dispatch => {
  dispatch(accountActions.setIsReservationsLoading(true));
  const { data: { error, data } } = await axios.get(`/users/reservations/history/?page_number=${page_number}&results_per_page=${results_per_page}&order=${order}`);

  if (error) {
    throw error;
  } else {
    const dataWithOperationTypes = data.map(reservation => ({
      ...reservation,
      operation_type: RESERVATIONS_TYPES[reservation.operation_id]
    }));

    dispatch(accountActions.setReservations(dataWithOperationTypes));
  }

  dispatch(accountActions.setIsReservationsLoading(false));
}

const getProfileReservationsUpcoming = () => {
  const { data } = axios.get(`/users/reservations/upcoming/?page_number=${1}&results_per_page=${10}&order=${"ASC"}`);
  return data;
}

export {
  adminSignUp,
  logIn,
  adminLogIn,
  exchangeToken,
  getCurrentUser,
  updateToken,
  editProfile,
  deleteProfile,
  getProfileReservationsHistory,
  getProfileReservationsUpcoming
}

export default {
  adminSignUp,
  logIn,
  adminLogIn,
  exchangeToken,
  getCurrentUser,
  updateToken,
  editProfile,
  deleteProfile,
  getProfileReservationsHistory,
  getProfileReservationsUpcoming
};
