import React, { Component } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';


import Airport from './Airport';
import AirportSplash from './Airport/Splash';

import Pilot from './Pilot';
import PilotExchange from './Pilot/Exchange';
import PilotSplash from './Pilot/Splash';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/airport/login" component={AirportSplash} />
        <Route path="/airport/sign-up" component={AirportSplash} />
        <Route path="/airport/about" component={AirportSplash} />
        <Route path="/airport" component={Airport} />

        <Route path="/pilot/exchange" component={PilotExchange} />
        <Route path="/pilot/login" component={PilotSplash} />
        <Route path="/pilot/sign_up" component={PilotSplash} />
        <Route path="/pilot/about" component={PilotSplash} />
        <Route path="/pilot" component={Pilot} /> 

        <Redirect to="/pilot" />
      </Switch>
    );
  }
}

export default App;
