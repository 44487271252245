import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Grid } from '@material-ui/core';

import TextArea from '../../TextArea';

import reservationActions from '../../../../redux/actions/reservation';

import { TYPES, closeTitlesTabs, closeOperations } from '../../../../helpers/reservations';
import { secondsToMinutes } from '../../../../helpers/time';

import { getUserRoleFromCookies } from '../../../../helpers/auth';


import './index.scss'

class ReservationInfo extends Component {
  state = {
    userRole: null
  }

  componentDidMount() {

    const userRole = getUserRoleFromCookies()

    this.setState({ userRole })
  }
  render() {
    const {
      state: {
        userRole
      },
      props: {
        selectedDay,
        selectedOperation,
        customDuration,
        selectedTime,
        closeWholeDayVal,
        utilization,
        description,
        previousStep,
        selectedAreas,
        closeRunway,
        closeDuration,
        setDescription,
      }
    } = this;

    let withDuration = selectedOperation !== null && [TYPES.helex, TYPES.fato].includes(selectedOperation.id);

    let withoutDuration = selectedOperation !== null && [TYPES.vfr_arrival, TYPES.vfr_departure, TYPES.ifr_arrival, TYPES.ifr_departure].includes(selectedOperation.id)

    return (
      <div id="chose-day-container">
        <div className="operations-body">
          <div className="operation-title-block">
            <div className="operation-title chose-day-title">
              <div className="back-arrow" onClick={previousStep}></div>
              <div className="chose-day-title-text">YOUR RESERVATION:</div>
            </div>
          </div>
        </div>

        <Grid container xs={5} justify="center" className="chose-day-calendar reserve-info-block">
          <ul className="reserve-info-list">
            {!closeRunway ? (
              <div>
                <li className="text">Operation: {selectedOperation.text}</li>
                <li className="text">
                  {withoutDuration ? '' :
                    `Duration: ${withDuration
                      ? customDuration
                      : secondsToMinutes(utilization.operation_duration)} minutes`
                  }
                </li>
              </div>
            ) :
              (<div>
                {selectedAreas.map(a => (<div><li className="text">Operation: {closeOperations[a]} </li>
                  {!closeWholeDayVal && <li className="text">
                    {
                      `Duration: ${closeDuration[closeTitlesTabs[a]]} ${closeDuration[closeTitlesTabs[a]] > 1 ? 'hours' : 'hour'}`
                    }
                  </li>}
                </div>))}
              </div>)
            }
            <li className="text">Day: {moment(selectedDay).format('MMMM Do YYYY')}</li>
            {!closeRunway && (<li className="text">{selectedTime}</li>)}
            <li className="chose-day-subtitle reserve-info-timezone"><h3>UTC time (ZULU time)</h3></li>
          </ul>
        </Grid>

        {userRole !== 'pilot' &&
          (
            <Grid container xs={5} justify="center" className="description-container">
              <TextArea
                rows={7}
                placeholder="ADD NOTE"
                value={description}
                onChange={e => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
          )
        }

        {userRole !== 'pilot' && (<div className="confirm-details-text">
          Please confirm the details of your reservation!
        </div>)}
      </div>
    )
  }
}

const mapStateToProps = ({
  reservation: {
    selectedDay,
    selectedOperation,
    customDuration,
    selectedTime,
    closeDuration,
    utilization,
    selectedAreas,
    closeWholeDayVal,
    selectedCloseOperationTab,
    closeRunway,
    description
  }
}) => ({
  selectedDay,
  selectedOperation,
  customDuration,
  selectedTime,
  closeWholeDayVal,
  closeDuration,
  selectedCloseOperationTab,
  closeRunway,
  selectedAreas,
  utilization,
  description
});

const mapDispatchToProps = dispatch => ({
  previousStep: () => dispatch(reservationActions.previousStep()),
  setDescription: description => dispatch(reservationActions.setDescription(description))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationInfo);