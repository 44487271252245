import React from 'react';
import Dropdown from '../Dropdown';
import Multiselect from '../Multiselect'
import './index.scss';

const operationTypes = [
  { value: 'larsa-op-1', label: 'VFR ARRIVAL' },
  { value: 'larsa-op-2', label: 'VFR DEPARTURE' },
  { value: 'larsa-op-3', label: 'VFR CIRCUIT' },
  { value: 'larsa-op-4', label: 'IFR ARRIVAL' },
  { value: 'larsa-op-5', label: 'IFR DEPARTURE' },
  { value: 'larsa-op-6', label: 'IFR TRAINING' },
  { value: 'larsa-op-7', label: 'FATO' },
  { value: 'larsa-op-8', label: 'HELEX' },
  { value: 'larsa-op-9', label: 'MAINTENANCE' },

];

const reservationTimes = [
  { value: true, label: 'Reservation Time' },
  { value: false, label: 'Reservation Time' },
  { value: true, label: 'Booking Time' },
  { value: false, label: 'Booking Time' }
];


const statuses = [{ value: 'accepted', label: 'Accepted' }, { value: 'confirmed', label: 'Confirmed' }, { value: 'canceled', label: 'Canceled' }]

const Filters = ({
  onTimeChange,
  onStatusChange,
  onTypeChange,
  withBookingTimes
}) => (
    <div className="filters">
      <div className="filter">
        <Multiselect
          onStatusChange={onStatusChange}
          items={statuses}
          selectedItem={'Status'}
        />
      </div>
      <div className="filter">
        <Multiselect
          onTypeChange={onTypeChange}
          items={operationTypes}
          selectedItem={'Type'}
        />
      </div>
      <div className="filter">
        <Dropdown
          onTimeChange={onTimeChange}
          selectedItem={'Order'}
          items={reservationTimes}
          withBookingTimes={withBookingTimes}
        />
      </div>
    </div>
  );


export default Filters;
