import React from 'react';
import { connect } from 'react-redux';

import MySwitch from '../../../MySwitch'

import reservationActions from '../../../../../redux/actions/reservation';

import './index.scss'

const CloseRunway = ({ 
  handleCloseRunwayChange,
  selectedAreas
}) => {

  return (
    <div className="close-runway-containers">
      <div className="runway-switch-blocssk">
        <MySwitch onChange={() => handleCloseRunwayChange('close_runway')} value='Close Runway' checked={selectedAreas.includes('close_runway')} />
        <MySwitch onChange={() => handleCloseRunwayChange('close_helex')} value='Close HELEX' checked={selectedAreas.includes('close_helex')}/>
        <MySwitch onChange={() => handleCloseRunwayChange('close_fato')} value='Close FATO' checked={selectedAreas.includes('close_fato')}/>
        </div>
    </div>
  );
}

const mapStateToProps = ({
  reservation: {
    handleCloseRunwayChange,
    selectedAreas
  },
}) => ({
  handleCloseRunwayChange,
  selectedAreas
});

const mapDispatchToProps = dispatch => ({
  handleCloseRunwayChange: area => dispatch(reservationActions.setSelectedAreas(area))
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseRunway);
