import React from 'react';

import AppStoreIcon from '../../../images/app-store-icon.svg'
import GooglePlayIcon from '../../../images/google-play-icon.svg'

import './index.scss';

const AboutUs = () => (
  <div className="pilot-about-container">
    <div className="content">
      <div className="about-text">About LARSA </div>

      <div className="text-block">

        <div className="terms-text-block">
          <div className="terms-first-text help-page-text">
            LARSA is a new platform for all pilots and representatives of pilots who are operating from Lelystad Airport (NL). All non-scheduled operations at Lelystad Airport should be
             registered through LARSA at least one hour before start-up.
        </div>
          <br />
          <p>Key benefits:</p>
          <br />
          <div className="terms-first-text help-page-text">
            Optimize your runway usage.
            Through easy and quick insight in the expected availability of capacity, you can increase the reliability of your operations whilst maintaining flexibility in your operations (this is not a slot coordination system).
            By indicating your planned movements through three easy steps, you also help the local airport community with their own flight planning.
        </div>
          <br />
          <div className="terms-first-text help-page-text">
            Stay informed.
            Through notifications, you will always be up to date with the latest operational situations and changes. If applicable, notifications will be connected to your upcoming reservations to make sure that you can always act upon them.
        </div>
          <br />
          <p>Features:</p>
          <br />
          <ul style={{ listStyle: 'none' }}>
            <li>- Secure account set-up for individual pilots and aircraft operating companies</li>
            <br />
            <li>- Easy management and planning of your flights at Lelystad Airport (NL)</li>
            <br />
            <li>- Support for IFR, VFR and Helicopter operations</li>
            <br />
            <li>- Smart capacity model developed in collaboration with LVNL</li>
            <br />
            <li>- Refer back to your reservation history at any time</li>
            <br />
          </ul>
          <br />
          <p>LARSA is an initiative of Lelystad Airport, LVNL and AirportCreators.</p>
          <br />
          <br />
          <p>LARSA is developed for Lelystad Airport by <a href="http://airportcreators.com"> AirportCreators</a>, <a href="http://globalorange.nl/"> GlobalOrange </a> and <a href="http://pixplicity.com/"> Pixplicity</a>.</p>
        </div>

      </div>

    </div>

    <footer className="footer">
      <div>
        <div className="about-footer-text">Download the LARSA app!</div>
        <div className="about-footer-text-small">Available in your app store</div>
      </div>

      <div className="about-footer-app-link">
        <a href="https://apps.apple.com/nl/app/larsa/id1488646141">
          <img src={AppStoreIcon} alt="appStore" className="about-app-store-icon" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.airportcreators.larsa">
          <img src={GooglePlayIcon} alt="googlePlay" />
        </a>
      </div>
    </footer>
  </div>
)


export default AboutUs;
