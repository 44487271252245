import React from 'react';
import './index.scss';

const Terms = () => (
  <div id="terms-content-wrap-text">
    <div className="terms-text-block">
      <div className="terms-first-text first-text-title">LARSA Terms of Use ("Terms")</div>
      <div className="terms-second-text last-update-text">Last updated: October 22,2019</div>

      <div className="terms-second-text">
        <p className="terms-text-padding inform-text">
          Please read these Terms of Use ("Terms", "Terms and Conditions") carefully before using the LARSA app or
          larsaweb.net website (the "Service") operated by Lelystad Airport ("us", "we", or "our").
          Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
           These Terms apply to all visitors, users and others who access or use the Service.
          By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of
          the terms, then you may not access the Service.
      </p>
        <div className="terms-first-text">1. Accounts</div>
        <p className="terms-text-padding">
          You are only allowed to create or access an account if you are a pilot or represent a pilot who is operating
          at Lelystad Airport.
          When you create an account with us, you must provide us information that is accurate, complete, and current
          at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination
          of your account on our Service.
          You are responsible for safeguarding the e-mail account that you use to access the Service and for any activities
          or actions under your account, whether your account information is with our Service or a third-party service.
          You agree not to disclose your access token to any third party. You must notify us immediately upon becoming
          aware of any breach of security or unauthorized use of your account.
      </p>
        <div className="terms-first-text">2. Conduct: How you should use the Service</div>
        <div className="terms-text-padding">
          You agree to use the Service (LARSA) solely in accordance with the following rules and conditions:
        <ul>
            <li className="warning-text">
              You are aware that your reservations made in the Service (LARSA) do not give you any rights or
              priorities regarding the availability of capacity for your flight operations. Local Air Traffic Control
              will always have the exclusive right to grant Runway, FATO or HELEX availability regardless of any
              information provided in the Service (LARSA);
          </li>
            <li className="warning-text">
              You are aware that all times presented in the Service (LARSA) are in UTC (ZULU) time, and you agree
              to book your reservations accordingly in UTC (ZULU) time;
          </li>
            <li>
              You are at all times responsible for safe flight operations which are fully compliant to any applicable
              laws and regulations;
          </li>
            <li>You are aware that reservations can be made up to 3 months in advance;</li>
            <li>
              You agree to make a reservation only when you intend to perform a flight operation which is completely
              aligned with the reservation details;
          </li>
            <li>
              You agree to update or cancel your reservations accordingly as soon as your intentions to perform a
              flight operation have changed.
          </li>
            <li>
              Regardless of any weather information provided in the Service, you remain fully responsible for
              performing your flight operations under appropriate and safe weather conditions.
          </li>
            <li>
              You are aware that weather information provided in the Service does not replace any other weather
              briefing and preparation procedures.
          </li>
            <li>
              You are aware that making a reservation in LARSA does NOT remove your obligation to file a flight
              plan or to perform any other formal aviation procedures.
          </li>
          </ul>
          In case of suspected misuse or abuse by You, through active disobedience to the above rules and conditions,
          We reserve the right to immediately revoke your access to the Service, and to remove your account including
          all associated information and reservations. Also, Lelystad Airport reserves the right to block you from
          opening a new user account on the Service.
      </div>
        <div className="terms-first-text">3. Links To Other Web Sites</div>
        <p className="terms-text-padding">
          Our Service may contain links to third-party web sites or services that are not owned or controlled by
          Lelystad Airport.
          Lelystad Airport has no control over, and assumes no responsibility for, the content, privacy policies,
          or practices of any third party web sites or services. You further acknowledge and agree that Lelystad
          Airport shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on any such content, goods or services
          available on or through any such web sites or services.
          We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
          services that you visit.
      </p>
        <div className="terms-first-text">4. Termination</div>
        <p className="terms-text-padding">
          We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
          All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
          We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
          Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
          All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
      </p>
        <div className="terms-first-text">5. Liability</div>
        <p className="terms-text-padding warning-text">
          We do not give any warranty as to the availability, accuracy, completeness or reliability of any content provided in the Service (LARSA) and expressly disclaim all liability for any damage or loss resulting from your use of or reliance on the content provided on the Service, to the maximum extent permitted by any applicable law.
      </p>
        <div className="terms-first-text">6. Force Majeure</div>
        <p className="terms-text-padding">
          You acknowledge that your use of, or access to, the Service may be subject to interruption or delay. We do not give you any warranty that the Service will be error free, without interruption or delay, or free from defects in design or engineering.
      </p>
        <div className="terms-first-text">7. Copyright</div>
        <p className="terms-text-padding">
          All content included in or made available through the Service, such as text, logo’s, graphics, software logic etc. is the property of Lelystad Airport or its suppliers. All rights are reserved. User is not allowed to reproduce any element of said content.
      </p>
        <div className="terms-first-text">8. Governing Law</div>
        <p className="terms-text-padding">
          These Terms shall be governed and construed in accordance with the laws of Netherlands, without regard to its conflict of law provisions.
          Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
      </p>
        <div className="terms-first-text">9. Changes</div>
        <p className="terms-text-padding">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
      </p>
        <div className="terms-first-text">10. Contact Us</div>
        <p className="terms-text-padding">If you have any questions about these Terms, please contact us:</p>
        <p className="terms-text-padding contact-info">Contact info</p>
      </div>
    </div>

    <div className="terms-first-text first-text-title">PRIVACY STATEMENT LARSA</div>
    <p className="note-text-italic">
      NOTE: This Privacy Statement is an addendum to the general Privacy Statement of Lelystad Airport, specific for LARSA. The general Privacy Statement of Lelystad Airport can be found <a href="#terms">here</a>. Both this specific addendum and the general Privacy Statement of Lelystad Airport are applicable to active users of LARSA.
    </p>
    <div className="terms-second-text">
      LARSA is a product of Lelystad Airport, part of the Royal Schiphol Group. This Privacy Statement will explain how Lelystad Airport uses the personal data which is collected from you when you use LARSA.
    </div>

    <div className="terms-second-text">
      <div className="terms-first-text">What data do we collect?</div>
      <div className="terms-text-padding">
        LARSA collects the following data:
          <ul>
          <li>Name</li>
          <li>E-mail address</li>
          <li>Phone number</li>
          <li>Data from all reservations entered by the User in LARSA, including the registration of the aircraft used.</li>
        </ul>
      </div>

      <div className="terms-first-text">How do we collect your data?</div>
      <p className="terms-text-padding">
        LARSA starts collecting your data at the moment when you agree to the terms of use and privacy statement during sign up. LARSA will store the personal details entered during the sign-up process, and subsequently store all details of reservations which are made by the User.
        </p>

      <div className="terms-first-text">How will we use your data?</div>
      <div className="terms-text-padding">
        We will only collect data which are necessary for the following reasons:
            <ul>
          <li>They are required for a correct functioning of the LARSA apps and platform</li>
          <li>They are required by Lelystad Airport to efficiently and safely manage the airport’s operations</li>
          <li>They are required to allow Lelystad Airport to seek contact by e-mail or telephone with you, for operational, information or promotion purposes.</li>
          <li>They are required for analysis, development and optimization of the functionalities of the LARSA platform.</li>
        </ul>
      </div>

      <div className="terms-first-text">Who will receive your data?</div>
      <div className="terms-text-padding">
        LARSA is a development of Lelystad Airport, and all personal information handled in LARSA is primarily processed by Lelystad Airport. However, only the following third parties will also have (limited) access to personal information stored in LARSA:
          <ul>
          <li>LVNL will receive the name and aircraft registration number of each pilot who uses LARSA actively. LVNL will only use this information for operational purposes.</li>
          <li>Supplier AirportCreators B.V. and their subcontractor GlobalOrange B.V., both registered in The Netherlands, will have access to the databases containing the personal information stored in LARSA. However, neither of these parties will collect, use or modify these personal details in any way. Access is only required for these parties to provide efficient technical support for LARSA.</li>
        </ul>
      </div>

      <div className="terms-first-text">How do we store your data?</div>
      <p className="terms-text-padding">
        All personal information collected is stored on secure and GDPR-compliant servers located within the European Union. Lelystad Airport will have the authority to download some of this personal information to their own secure local network. All collected data can be kept until the User requests full deletion by deleting their user account.
        </p>

      <div className="terms-first-text">Your rights regarding data protection:</div>

      <p><span className="terms-strong-text">The right to access –</span> You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.</p>
      <p><span className="terms-strong-text">The right to rectification – </span> You have the right to request that we correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.</p>

      <p><span className="terms-strong-text">The right to erasure – </span>Users of LARSA are fully able to request erasure of all personal information from the LARSA platform by deleting their account. This can be done via the (web)app.</p>
      <p><span className="terms-strong-text">The right to restrict processing –</span> You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
      <p><span className="terms-strong-text">The right to object to processing –</span> You have the right to object to we processing of your personal data, under certain conditions.</p>
      <p><span className="terms-strong-text">The right to data portability –</span> You have the right to request that LARSA transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
      <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us:</p>
      <p>Call us at: +31 320 284782</p>
      <p>Or write to us: <a href="mailto:operations@lelystad-airport.nl">operations@lelystad-airport.nl</a></p>
      <div className="terms-use-cookies">
        <p ><span className="terms-strong-text">Cookies</span></p>
        <p>LARSA only uses cookies for the correct technical functioning of the webapp. No personal information is collected or stored through the use of cookies.</p>
      </div>
      <p><span className="terms-strong-text">Royal Schiphol Group</span></p>
      <p><span className="terms-strong-text">Lelystad Airport</span></p>
      <p><span className="terms-strong-text">Postadres:</span></p>
      <p>Postbus 2201</p>
      <p>8203 AE Lelystad</p>
      <p>Nederland</p>
      <p><span className="terms-strong-text">Bezoekadres:</span></p>
      <p>Aviation House</p>
      <p>Emoeweg 7</p>
      <p>8218 PC Lelystad</p>
      <p>Nederland</p>
    </div>
  </div>
);

export default Terms;
