import React from 'react';
import './index.scss'
import { Switch, Route } from 'react-router-dom';

import Login from '../LogIn'
import SignUp from '../Signup'
import AboutUs from '../../../components/common/AboutUs'

import SplashSidebar from '../../../components/SplashSidebar';

import { getUserRoleFromCookies } from '../../../helpers/auth';

const tabs = [
  {
    label: 'Log In',
    link: '/pilot/login'
  },
  {
    label: 'Sign Up',
    link: '/pilot/sign_up'
  },
  {
    label: 'About Us',
    link: '/pilot/about'
  }
];

class PilotSplash extends React.Component {
  state = {
    isFull: true
  };

  componentDidMount() {
    const userRole = getUserRoleFromCookies();

    if (userRole === 'pilot') {
      return this.props.history.push('/pilot/dashboard');
    }
  }

  closeFull = () =>
    this.setState({ isFull: false })

  render() {
    const {
      closeFull,
      state: {
        isFull
      }
    } = this;

    return (
      <div className="pilot-splash">
        <SplashSidebar
          isFull={isFull}
          tabs={tabs}
          closeFull={closeFull}
          withFooter={true}
        />

        {
          !isFull && (
            <Switch>
              <Route path="/pilot/login" component={Login} />
              <Route path="/pilot/sign_up" component={SignUp} />
              <Route path="/pilot/about" component={AboutUs} />
            </Switch>
          )
        }
      </div>
    )
  }
}

export default PilotSplash;
