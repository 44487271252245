import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './index.scss';

const Loader = () => (
  <div className="loader-container"><CircularProgress /></div>
);

export default Loader;
