import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import ConfirmModal from '../../../components/common/modals/ConfirmModal'
import ReservationDetails from '../../../components/common/Reservations/ReservationDetails';
import NotificationListItem from '../../../components/common/NotificationsList/NotificationListItem';
import notificationsService from '../../../services/notifications'
import reservationsService from '../../../services/reservations'
import reservationActions from '../../../redux/actions/reservation';
import notificationsUtils from '../../../utils/notifications'
import { IDS, RESERVATIONS_TYPES } from '../../../helpers/reservations';

import Button from '../../../components/common/Button'
import NotificationsList from '../../../components/common/NotificationsList'

import './index.scss'
import moment from 'moment';

class Notifications extends React.Component {
  state = {
    notifications: [],
    selectedNotification: null,
    isConfirmModalShow: false,
    reservation: null,
    isLoading: true,
    scrolling: false,
    next: null,
    offset: 0,
  }

  async componentDidMount() {
    const notifications = await this.getNotifications()
    this.setState({ isLoading: false, notifications })
  }

  getNotifications = async () => {
    const { offset, notifications } = this.state

    const { next, results: newNotifications } = await notificationsService.getPilotNotifications({ limit: 10, offset });

    this.setState({ isUsersLoading: false, next, scrolling: false, notifications: [...notifications, ...newNotifications] });

    return newNotifications

  }

  loadMoreNotifications = () => {
    this.setState(prevState => ({
      offset: prevState.offset + 10,
      scrolling: true
    }), this.getNotifications)
  }

  selectNotification = async (notification) => {
    this.setState({ selectedNotification: notification, reservation: null })

    if (notification.hasOwnProperty('is_read') && !notification.is_read) {
      try {
        notification.is_read = true
        await notificationsService.readNotification(notification.id)
      } catch (error) {
        console.log(error)
      }
    }
    if (notification.reservation_id !== null) {
      try {
        const reservation = await reservationsService.getReservation(notification.reservation_id)

        this.setState({ reservation })
      } catch (error) {
        console.log(error)
      }
    }
  }

  handleNavigate = (route) => {
    const {
      state: {
        reservation,

      },
      props: {
        history,
        setActiveStep,
        setSelectedOperation,
        setRescheduleReservationId
      }
    } = this

    let operation = null;

    if (reservation !== null) {
      const operationIdArr = Object.entries(IDS).find(e => e[1] === reservation.operation_id)

      operation = {
        id: operationIdArr[0],
        text: RESERVATIONS_TYPES[reservation.operation_id]
      }

      setActiveStep(2)
      setSelectedOperation(operation)
      setRescheduleReservationId(reservation.res_id)
    }


    history.push(route)
  }

  closeModal = () => {
    this.setState({ isConfirmModalShow: false })
  }

  handleCancel = async (confirm) => {
    const { reservation } = this.state
    if (!confirm) {
      this.setState({
        isConfirmModalShow: true
      })
    } else {
      this.setState({ isCanceling: true })

      const data = await reservationsService.cancelReservation(reservation.res_id)

      if (data.status === 'ok') {
        notificationsUtils.success({ msg: `Reservation  was successfully ${reservation.status === 'canceled' ? 'deleted' : 'canceled'}.` })
        this.setState({ isConfirmModalShow: false, reservation: null, selectedNotification: null })
      } else {
        notificationsUtils.error({
          msg: data.error
        })
      }
      this.closeModal()
    }
  }

  handleConfirm = async (id, ntfId, aircraftNumber) => {
    const { reservation } = this.state

    const data = await reservationsService.confirmReservation(id, { aircraft_registration: aircraftNumber })

    if (data.status === 'ok') {
      reservation.status = 'confirmed'
      reservation.notifications = []

      this.setState({
        reservation
      })
      notificationsUtils.success({ msg: `Reservation  was successfully confirmed.` })

    } else {
      notificationsUtils.error({
        msg: data.error
      })
    }
  }


  render() {
    const {
      loadMoreNotifications,
      handleNavigate,
      handleConfirm,
      state: {
        reservation,
        notifications,
        isConfirmModalShow,
        next,
        selectedNotification,
      }
    } = this;

    const today = moment().format('YYYY-MM-DD')
    const date = reservation !== null && moment(reservation.eta_dt).format('YYYY-MM-DD')

    return (
      <div>
        <Grid container item xs={10} lg={10} justify="center" alignItems="flex-start" id="notifications-container">
          <Grid container item xs={4} lg={4}>
            <div className="ntf-page-header">NOTIFICATIONS:</div>
            {
              !this.state.isLoading && (
                <NotificationsList
                  withFilters={false}
                  notifications={notifications}
                  handleClick={(notification) => this.selectNotification(notification)}
                  withTitle={false}
                  parentNotification={selectedNotification}
                  loadMore={loadMoreNotifications}
                  next={next}
                />
              )
            }
          </Grid>

          <Grid container item xs={4} lg={4} justify="center">
            <div className="ntf-page-header non-bold">RESERVATION DETAILS:</div>
            {selectedNotification !== null && reservation !== null &&
              (<div className="reservation-details">
                <div className="reservation-details-content">
                  <ReservationDetails
                    reservation={reservation}
                    subtitle={`Operation: ${reservation.operation_type}`}
                    withTitle={false}
                    withCancelBtn={false}
                    withType={false}
                  />
                </div>
                {!moment(date).isBefore(moment(today)) && reservation.status !== 'deleted' &&
                  (<div>
                    <Button className="edit-button" onClick={() => handleNavigate('/pilot/reserve')}>{reservation.status === 'canceled' ? 'RESCHEDULE' : 'EDIT RESERVATION'}</Button>
                    <br />
                    <Button className="cancel-button" invert onClick={() => reservation.status === 'canceled' ? this.handleCancel(true) : this.handleCancel(false)}>{reservation.status === 'canceled' ? 'DELETE' : 'CANCEL'}</Button>
                  </div>)
                }

              </div>)
            }
          </Grid>

          {isConfirmModalShow &&
            <ConfirmModal
              title='Are you sure you want to cancel this reservation?'
              acceptButtonText='Cancel Reservation'
              rejectButtonText='Keep Reservation'
              clsTitle='title-cancelReserv'
              clsBtns='btns-cancelReserv'
              clsBtnAccept='accBtn'
              clsBtnReject='rejBtn'
              onClose={() => this.closeModal()}
              onAccept={() => this.handleCancel(true)}
              onReject={() => this.closeModal()}
            >
              <div style={{ textAlign: 'left' }}>
                <ReservationDetails
                  reservation={reservation}
                  subtitle={`Operation: ${reservation.operation_type}`}
                  withTitle={false}
                  withCancelBtn={false}
                  withStatus={false}
                />
              </div>

            </ConfirmModal>
          }

          <Grid container item xs={4} lg={4} justify="center">
            <div className="ntf-page-header non-bold">NOTIFICATIONS:</div>
            {reservation !== null && reservation.notifications &&
              reservation.notifications.map(item => (
                <NotificationListItem
                  onConfirm={(aircraftNumber) => handleConfirm(item.reservation_id, item.id, aircraftNumber)}
                  notification={item}
                  confirmation={!['canceled', 'deleted'].includes(reservation.status)}
                  simpleNtf={true}
                />
              ))}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = ({
  reservation: {
    activeStep,
    selectedOperation,
    customDuration,
    isClosedDaysLoading,
    selectedDay,
    selectedTime,
    numberOfTouchAndGo,
    utilization,
    description
  }
}) => ({
  activeStep,
  selectedOperation,
  customDuration,
  isClosedDaysLoading,
  selectedDay,
  selectedTime,
  numberOfTouchAndGo,
  utilization,
  description
});

const mapDispatchToProps = dispatch => ({
  setActiveStep: step => dispatch(reservationActions.setActiveStep(step)),
  setSelectedOperation: operation => dispatch(reservationActions.setSelectedOperation(operation)),
  setSelectedDay: day => dispatch(reservationActions.setSelectedDay(day)),
  setTouchAndGo: numberOfTouchAndGo => dispatch(reservationActions.setTouchAndGo(numberOfTouchAndGo)),
  setRescheduleReservationId: id => dispatch(reservationActions.setRescheduleReservationId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));
