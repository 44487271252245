const SET_IS_MAIN_CONTENT_BLURRED = 'MODAL/SET_IS_MAIN_CONTENT_BLURRED';

const setIsMainContentBlurred = isMainContentBlurred => ({
  type: SET_IS_MAIN_CONTENT_BLURRED,
  isMainContentBlurred
});

export {
  SET_IS_MAIN_CONTENT_BLURRED,
  setIsMainContentBlurred
};

export default {
  SET_IS_MAIN_CONTENT_BLURRED,
  setIsMainContentBlurred
};
