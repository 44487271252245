import React from 'react';

import SignUpForm from './Form';
import TermsOfUse from './TermsOfUse';

import Button from '../../../components/common/Button';
import ThankYou from '../../../components/common/ThankYou';

import accountService from '../../../services/account';

import notifications from '../../../utils/notifications';

import './index.scss';

class SignUp extends React.Component {
  state = {
    model: {
      first_name: '',
      last_name: '',
      telephone: '',
      email: '',
      company_name: '',
      company_street: '',
      company_postalcode: '',
      company_city: ''
    },
    isShowTerms: false,
    isLinkWasSent: false
  }

  handleChange = key => e => {
    e.persist();

    this.setState(prev => ({
      model: {
        ...prev.model,
        [key]: e.target.value
      }
    }));
  }

  onConfirm = async e => {
    e.preventDefault();

    const {
      state: {
        model: {
          first_name,
          last_name,
          telephone,
          email,
          company_name,
          company_street,
          company_postalcode,
          company_city
        },
        isLoading
      }
    } = this;

    if (isLoading) return;

    this.setState({ isLoading: true });

    try {
      await accountService.adminSignUp({ first_name, last_name, telephone, email, company_name, company_street, company_postalcode, company_city });

      this.setState({ isLinkWasSent: true });
    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });

      this.setState({ isLoading: false });
    }
  }

  handleNext = () => {
    this.setState({ isShowTerms: true })
  }

  render() {
    const {
      handleChange,
      onConfirm,
      handleNext,
      state: {
        model,
        isLoading,
        isLinkWasSent,
        isShowTerms
      }
    } = this;

    if (isLinkWasSent) return (<ThankYou />);

    return (
      <div className="pilot-signup-container">
        {
          isShowTerms ? (
            <TermsOfUse
              onConfirm={onConfirm}
              isLoading={isLoading}
            />
          ) : (
              <div className="signup-form-container">
                <SignUpForm
                  model={model}
                  handleChange={handleChange}
                />

                <div>
                  <Button
                    className="next-button"
                    onClick={handleNext}
                    disabled={!model.email}
                    fullWidth
                  >
                    Next
                  </Button>
                </div>
              </div>
            )
        }
      </div>
    )
  }
}

export default SignUp;
