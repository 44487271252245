import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import moment from 'moment'

import classnames from 'classnames';
import { RESERVATIONS_TYPES } from '../../../../helpers/reservations';

import ArrowIcon from '../../../../images/chevron-right_menu.svg';
import ArrowWhiteIcon from '../../../../images/chevron-right_white.svg';

import whiteBell from '../../../../images/chevron-right-bell.svg'
import grayBell from '../../../../images/notification-bell.svg'

import { getUserRoleFromCookies } from '../../../../helpers/auth';

import './index.scss';

const ReservationCard = ({ reservation, isActive, onClick, ...props }) => {
  const [userRole, setUserRole] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const userRole = getUserRoleFromCookies()

    setUserRole(userRole)
  }, []);

  const operationType = RESERVATIONS_TYPES[reservation.operation_id]

  const bellIcon = isActive ? whiteBell : grayBell

  return (
    <div
      key={reservation.res_id}
      className={classnames({ 'reservation-card': true, 'active': isActive, 'canceled': reservation.status === 'canceled' })}
      onClick={onClick}
      {...props}
    >
      <p className="reservation-title">{operationType}</p>
      {
        userRole === 'pilot' && reservation.notifications.length > 0 ?
          (<div className="notifications-bell">
            <img className="reservation-bell" src={bellIcon} alt="bell" />
            <div className="read-status"></div>
          </div>)
          :
          (<span className={classnames({ "reservation-status": true, 'accepted': reservation.status === 'accepted', 'canceled': reservation.status === 'canceled' })}>{reservation.status.toUpperCase()}</span>)
      }
      <div className="reservation-info">
        <p>{`${moment(reservation.eta_dt).utc().format("MMMM Do YYYY kk:mm")} (UTC Time)`}</p>
        <p>Reservation ID: {reservation.res_id}</p>
      </div>
      <div className="reservation-icon" onClick={onClick}>
        <img src={isActive ? ArrowWhiteIcon : ArrowIcon} alt="arrow" />
      </div>
    </div>
  )
};

ReservationCard.propTypes = {
  reservation: shape({
    res_id: string,
    operation_type: string,
    status: string,
    date_time: string
  }).isRequired,
  isActive: bool,
  onClick: func
};

ReservationCard.defaultProps = {
  isActive: false,
  onClick: () => false
};

export default ReservationCard;