import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import LogoIcon from '../../images/LA_logo.svg'
import LogoSignIcon from '../../images/larsa.png'
import AppStoreIcon from '../../images/app-store-icon.svg'
import GooglePlayIcon from '../../images/google-play-icon.svg'

import './index.scss';

const SplashSidebar = ({ isFull, withFooter, tabs, closeFull }) => (
  <div className={`splash-sidebar ${isFull ? 'slideIn' : 'slideOut'}`}>

    <div className={`logo ${isFull ? '' : 'logo-transform'}`}>
      <div className="logo-letter">
        <img src={LogoIcon} alt="logo-letter" />
      </div>

      <div className="logo-title">Lelystad Airport</div>
      <div className="logo-subtitle">presents</div>
      <div className="logo-sign-title">LARSA</div>
      <div>
        <img src={LogoSignIcon} alt="logo-sign" />
      </div>
    </div>

    <div className="menu">
      {
        tabs.map(tab => (
          <Link onClick={closeFull} to={tab.link} className="nav-text" key={tab.link}>
            <div className="nav-link">
              <span>{tab.label}</span>
            </div>
          </Link>
        ))
      }
    </div>

    {
      (isFull && withFooter) && (
        <div className="footer">
          <div>
            <div className="footer-text">Download the LARSA app!</div>
            <div className="footer-text-small">Available in your app store</div>
          </div>

          <div className="footer-app-links">
            <a href="https://apps.apple.com/nl/app/larsa/id1488646141">
              <img src={AppStoreIcon} alt="appStore" className="app-store-icon" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.airportcreators.larsa">
              <img src={GooglePlayIcon} alt="googlePlay" />
            </a>
          </div>
        </div>
      )
    }
  </div>
);

SplashSidebar.propTypes = {
  isFull: bool.isRequired,
  withFooter: bool,
  tabs: arrayOf(shape({
    label: string,
    link: string
  })).isRequired,
  closeFull: func.isRequired,
}

SplashSidebar.defaultProps = {
  withFooter: false
}

export default SplashSidebar;
