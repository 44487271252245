import {
  SET_DAY
} from '../actions/main';

const initialState = {
  selectedDay: new Date(),
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DAY:
      return { ...state, selectedDay: action.day };
    default:
      return state;
  }
};

export default mainReducer;