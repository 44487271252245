import {
  SET_RESCHEDULE_RESERVATION_ID,
  SET_ACTIVE_STEP,
  SET_SELECTED_OPERATION,
  PREVIOUS_STEP,
  NEXT_STEP,
  DROP_ACTIVE_STEP,
  SET_TOUCH_AND_GO,
  DECREASE_TOUCH_AND_GO,
  INCREASE_TOUCH_AND_GO,
  SET_SELECTED_DAY,
  DECREASE_CUSTOM_DURATION,
  INCREASE_CUSTOM_DURATION,
  INCREASE_CLOSE_DURATION,
  DECREASE_CLOSE_DURATION,
  UPDATE_CLOSED_DAYS,
  SET_CLOSED_DAYS_LOADING_STATUS,
  SET_UTILIZATION_DATA,
  SET_UTILIZATION_DATA_LODING_STATUS,
  SET_SELECTED_TIME,
  SET_SELECTED_CLOSE_TIME,
  SET_SELECTED_CLOSE_TAB,
  SET_DESCRIPTION,
  CLOSE_RUNWAY,
  SET_SELECTED_AREAS,
  CLOSE_WHOLE_DAY,
  SET_AVERAGE_LEVEL,
} from '../actions/reservation';

const initialState = {
  rescheduleReservationId: null,
  activeStep: 1,
  selectedOperation: null,
  selectedCloseOperationTab: {
    title: '',
    landingId: '',
  },
  numberOfTouchAndGo: 1,
  selectedDay: new Date(),
  customDuration: 5,
  closedDays: [],
  isClosedDaysLoading: false,
  utilization: {
    timeline: [],
    utilization: [],
    opening_time: '',
    closing_time: '',
    maintenance_times: [],
    operation_duration: 0,
    time_step: 300,
    value_labels: [],
  },
  isUtilizationLoading: false,
  selectedTime: '',
  selectedCloseTime: {
    HELEX: '06:00',
    FATO: '06:00',
    RUNWAY: '06:00',
  },
  closeDuration: {
    HELEX: 0.5,
    FATO: 0.5,
    RUNWAY: 0.5,
  },
  description: '',
  closeRunway: false,
  selectedAreas: [],
  closeWholeDayVal: false,
  averageLevel: null,
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESCHEDULE_RESERVATION_ID:
      return { ...state, rescheduleReservationId: action.id };

    case SET_ACTIVE_STEP:
      return { ...state, activeStep: +action.step };

    case SET_SELECTED_OPERATION:
      return {
        ...state,
        selectedOperation: action.operation,
        closeRunway: false,
      };

    case SET_SELECTED_CLOSE_TAB:
      return {
        ...state,
        selectedCloseOperationTab: action.selectedTab,
      };

    case PREVIOUS_STEP:
      return { ...state, activeStep: +state.activeStep - 1 };
    case NEXT_STEP:
      return { ...state, activeStep: +state.activeStep + 1 };
    case DROP_ACTIVE_STEP:
      return {
        ...state,
        activeStep: 1,
        selectedOperation: null,
        selectedAreas: [],
        selectedCloseTime: {
          HELEX: '06:00',
          FATO: '06:00',
          RUNWAY: '06:00',
        },
        closeRunway: false,
        numberOfTouchAndGo: 1,
        selectedDay: new Date(),
        selectedTime: '',
        description: '',
      };
    case SET_TOUCH_AND_GO:
      return { ...state, numberOfTouchAndGo: action.numberOfTouchAndGo };
    case DECREASE_TOUCH_AND_GO:
      return { ...state, numberOfTouchAndGo: +state.numberOfTouchAndGo - 1 };
    case INCREASE_TOUCH_AND_GO:
      return { ...state, numberOfTouchAndGo: +state.numberOfTouchAndGo + 1 };

    case SET_SELECTED_DAY:
      return { ...state, selectedDay: action.day };

    case DECREASE_CUSTOM_DURATION:
      return {
        ...state,
        customDuration: state.closeRunway
          ? +state.customDuration - 1
          : +state.customDuration - 5,
      };
    case INCREASE_CUSTOM_DURATION:
      return {
        ...state,
        customDuration: state.closeRunway
          ? +state.customDuration + 1
          : +state.customDuration + 5,
      };

    case INCREASE_CLOSE_DURATION:
      return {
        ...state,
        closeDuration: {
          ...state.closeDuration,
          [state.selectedCloseOperationTab.title]:
            state.closeDuration[state.selectedCloseOperationTab.title] + 0.5,
        },
      };

    case DECREASE_CLOSE_DURATION:
      return {
        ...state,
        closeDuration: {
          ...state.closeDuration,
          [state.selectedCloseOperationTab.title]:
            state.closeDuration[state.selectedCloseOperationTab.title] - 0.5,
        },
      };

    case UPDATE_CLOSED_DAYS:
      return { ...state, closedDays: [...state.closedDays, ...action.days] };
    case SET_CLOSED_DAYS_LOADING_STATUS:
      return { ...state, isClosedDaysLoading: action.status };

    case SET_UTILIZATION_DATA:
      return { ...state, utilization: action.data };
    case SET_UTILIZATION_DATA_LODING_STATUS:
      return { ...state, isUtilizationLoading: action.status };

    case SET_SELECTED_TIME:
      return {
        ...state,
        selectedTime: action.time,
      };

    case SET_SELECTED_CLOSE_TIME:
      return {
        ...state,
        selectedCloseTime: {
          ...state.selectedCloseTime,
          ...action.obj,
        },
      };

    case SET_DESCRIPTION:
      return { ...state, description: action.description };

    case CLOSE_RUNWAY:
      return { ...state, closeRunway: action.value, selectedOperation: false };
    case SET_SELECTED_AREAS:
      return {
        ...state,
        selectedAreas: state.selectedAreas.includes(action.areas)
          ? state.selectedAreas.filter(item => item !== action.areas)
          : [...state.selectedAreas, action.areas],
      };
    case CLOSE_WHOLE_DAY:
      return { ...state, closeWholeDayVal: !state.closeWholeDayVal };

    case SET_AVERAGE_LEVEL:
      return { ...state, averageLevel: action.level };

    default:
      return state;
  }
};

export default mainReducer;
