import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Loader from '../../../../components/common/Loader';
import Button from '../../../../components/common/Button';
import Terms from '../../../../components/common/Terms'

import './index.scss';

const MySwitch = withStyles({
  root: {
    height: '42px',
    width: '62px',
    borderRadius: '20px',
    padding: 0,
    border: '1px solid rgba(0,0,0,0.2)'
  },
  switchBase: {
    color: 'background-color: rgba(0,0,0,0.2);',
    padding: 1,
    '&$checked': {
      color: '#512479',
    },
    '&$checked + $track': {
      backgroundColor: '#512479',
    },
  },
  checked: {},
  track: {},
  thumb: {
    boxShadow: '1px 1px 2px 0 rgba(0,0,0,0.5)',
    height: '37px',
    borderRadius: '18.5px',
    width: '37px',
    padding: 0,
  },

})(Switch);

class TermsOfUse extends Component {
  state = {
    checked: false,
  }

  handleChange = () =>
    this.setState(prev => ({ checked: !prev.checked }));

  render() {
    const {
      handleChange,
      props: {
        onConfirm,
        isLoading
      },
      state: {
        checked
      }
    } = this;

    return (
      <div className="terms-page-container">

        <div className="terms-subtitle-text">TERMS OF USE:</div>

        <div className="terms-page-content">
          <Terms />
        </div>

        <div className="agreement-block">
          <FormControlLabel
            control={
              <MySwitch
                checked={checked}
                onChange={handleChange}
                value="checked"
              />
            }
            label="I agree"
            labelPlacement="start"
          />

          <div>
            <Button
              disabled={!checked}
              className="confirm-button"
              onClick={onConfirm}
            >
              {
                isLoading ? (<Loader />) : 'Confirm'
              }
            </Button>
          </div>
        </div>
      </div>
    )
  }
}


export default TermsOfUse;
