import React, { Component } from 'react';
import { func } from 'prop-types';

import Button from '../../../../components/common/Button';
import Loader from '../../../../components/common/Loader'
import Input from '../../../../components/common/Input';
import TextArea from '../../../../components/common/TextArea';

import userService from '../../../../services/user';

import notifications from '../../../../utils/notifications';

import './index.scss';

const DEFAULT_MESSAGE = `Hi,

You are invited to use LARSA, a new platform which allows you to manage your upcoming flight operations at the Lelystad Airport.

Please download the app for [IOS] or [Android] to sign up.

Alternatively, you can access LARSA via https://larsaweb.net/pilot.

For more information about LARSA, please refer to https://larsaweb.net/pilot/about.

With kind regards,
Lelystad Airport`;

class InviteUser extends Component {
  state = {
    email: '',
    subject: '',
    message: DEFAULT_MESSAGE,
    isSending: false
  };

  handleChange = key => e => this.setState({ [key]: e.target.value });

  submit = async () => {
    const {
      props: {
        onSuccessInvite
      },
      state: {
        email,
        subject,
        message
      }
    } = this;

    this.setState({ isSending: true });

    try {
      await userService.invite({ email, subject, message });

      notifications.success({ msg: 'The mail was sent successfully.' })

      onSuccessInvite();
    } catch (err) {
      notifications.error({
        msg: (err.response && err.response.data)
          ? Object.values(err.response.data).join(', ')
          : err.message
      });
    } finally {
      this.setState({ isSending: false });
    }
  }

  render() {
    const {
      handleChange,
      submit,
      state: {
        email,
        subject,
        message,
        isSending
      }
    } = this;

    return (
      <div className="invite-user-container">
        <h6 className="title">INVITE NEW USER:</h6>

        <div className="send-block">
          <div className="top-block">
            <p className="send-text">Send to:</p>

            <Input
              placeholder="EMAIL"
              value={email}
              className="send-email"
              onChange={handleChange('email')}
            />

            <Button invert className="import-button">IMPORT LIST</Button>
          </div>

          <Input
            placeholder="SUBJECT"
            value={subject}
            className="send-subject"
            onChange={handleChange('subject')}
          />

          <TextArea
            placeholder="YOUR MESSAGE..."
            rows={15}
            value={message}
            className="custom-textarea send-message"
            onChange={handleChange('message')}
          >
            {message}
          </TextArea>

          <div className="button-container">
            <Button className="send-button" onClick={submit}>
              {
                isSending ? <Loader /> : 'SEND'
              }
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

InviteUser.propTypes = {
  onSuccessInvite: func
};

InviteUser.defaultProps = {
  onSuccessInvite: () => false
};

export default InviteUser;
