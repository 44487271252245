import React from 'react';
import { string } from 'prop-types';

import Modal from '../../Modal';

import './index.scss';

const BackWayModal = ({
  operationID,
  onResolve,
  onReject
}) => {
  let id;

  switch (operationID) {
    case 'vfr_arrival':
      id = 'vfr_departure';
      break;
    case 'vfr_departure':
      id = 'vfr_arrival';
      break;
    case 'ifr_arrival':
      id = 'ifr_departure';
      break;
    case 'ifr_departure':
      id = 'ifr_arrival';
      break;
    case 'helex':
      id = 'fato'
      break;
    case 'fato':
      id = 'helex';
      break
    default: id = operationID;
  }

  return (

    <Modal className="confirm-modal-back-way" withCloseBtn={false} onClose={() => false}>
      {['helex', 'fato'].includes(operationID) && (<h6 className="title">{`Continue to ${operationID === 'helex' ? 'FATO' : 'HELEX'} area, or leave?`}</h6>)}

      {['vfr_arrival', 'ifr_arrival', 'vfr_departure', 'ifr_departure'].includes(operationID) && (<h6 className="title">{`Do you also want to schedule ${['vfr_arrival', 'ifr_arrival'].includes(operationID) ? 'a departure' : 'an arrival'} time?`}</h6>)}
      <div className="action-buttons">
        <div className="btn" onClick={() => onResolve(id)}>
          <p>{['helex', 'fato'].includes(operationID) ? (operationID === 'helex' ? 'FATO' : 'HELEX') : 'Yes'}</p>
        </div>

        <div className="btn" onClick={onReject}> <p>{['helex', 'fato'].includes(operationID) ? 'Leave' : 'No'}</p> </div>
      </div>
    </Modal>
  )
};

BackWayModal.propTypes = {
  operationType: string,

};

BackWayModal.defaultProps = {
  operationType: '',

}

export default BackWayModal;