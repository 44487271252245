import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';

import App from './containers/App';

import {
  getAccessTokenFromCookies,
  createAxiosResponseInterceptor
} from './helpers/auth';

import store from './redux/store';
import history from './utils/history';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/v1`;

const accessToken = getAccessTokenFromCookies();

if (accessToken) {
  axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

createAxiosResponseInterceptor();

toast.configure();


ReactDOM.render((
  <Provider store={store}> 
    <Router history={history}>
      <App />
    </Router>
  </Provider>
), document.getElementById('root'));
