import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import { arrayOf, shape, string, func, bool } from 'prop-types';
import arrowDown from '../../../images/chevron-down.svg'

import './index.scss'
import classnames from 'classnames';


class Dropdown extends React.Component {
  state = {
    showItems: false,
    selectedItem: null,
  }

  toggleDropdownMenu = () => {
    this.setState({ showItems: !this.state.showItems })
  }

  selectItem = (item) => {
    const { selectedItem } = this.state

    if (selectedItem !== null && selectedItem.value === item.value && selectedItem.label === item.label) {
      this.setState({ selectedItem: null });
      this.props.onTimeChange(null)
    } else {
      this.setState({ selectedItem: item });
      this.props.onTimeChange(item)
    }
    this.toggleDropdownMenu();
  }

  render() {
    const { selectedItem } = this.state

    return (
      <div className="dropdown"  >
        <div className="dropdown-header" onClick={this.toggleDropdownMenu}>
          <div className="arrow" >
            <img src={arrowDown} alt="arrow" />
          </div>
          <div className="selected" ><span className="selected-text">{this.props.selectedItem}</span> </div>
        </div>


        {this.state.showItems && (
          <ul className="dropdown-content">
            {this.props.items.map((item, index) => {
              let icon = item.value ? faArrowUp : faArrowDown

              if (!this.props.withBookingTimes) {
                if (item.label === 'Booking Time') {
                  return false
                }
              }
              return (<li key={index}
                onClick={() => this.selectItem(item)}
                className="dropdown-item">
                <span className={classnames({ "dropdown-item-text": true, 'selected-item': selectedItem !== null && selectedItem.label === item.label && selectedItem.value === item.value })}>{item.label}</span>
                <FontAwesomeIcon icon={icon} pull="right" style={{ height: 10 }} />
              </li>)
            }
            )}
          </ul>
        )
        }
      </div>
    );
  }
}

Dropdown.propTypes = {
  items: arrayOf(shape({
    value: string,
    label: string
  })),
  selectedItem: string,
  onTimeChange: func,
  withBookingTimes: bool
};

Dropdown.defaultProps = {
  items: [],
  selectedItem: null,
  onTimeChange: () => false,
  withBookingTimes: false
};

export default Dropdown;
