import axios from 'axios';

import {
  makeApiWithQuery
} from '../helpers/services';

const getAirportNotification = async ({
  limit = 10,
  offset = 0
} = {}) => {
  const {
    data
  } = await axios.get(makeApiWithQuery('/notification-templates/', {
    limit,
    offset
  }));

  return data;
}

const getPilotNotifications = async ({
  limit = 10,
  offset = 0
} = {}) => {
  const {
    data
  } = await axios.get(makeApiWithQuery('/notifications/', {
    limit,
    offset
  }));

  return data;
}

const addNotifications = async data => {
  const res = await axios.post('/notification-templates/', data);
  return res.data;
}

const removeNotification = async id => {
  const res = await axios.delete(`/notification-templates/${id}`);
  return res.data;
}

const sendNotification = async id => {
  const res = await axios.post(`notification-templates/${id}/send-bulk-push/`);
  return res.data;
}

const readNotification = async id => {
  const {
    data
  } = await axios.get(`notifications/${id}/read`);
  return data;
}

export {
  getAirportNotification,
  removeNotification,
  sendNotification,
  addNotifications,
  getPilotNotifications,
  readNotification
}

export default {
  getAirportNotification,
  removeNotification,
  sendNotification,
  addNotifications,
  getPilotNotifications,
  readNotification
};