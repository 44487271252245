import Cookies from 'js-cookie';
import axios from 'axios';

import history from '../utils/history';

import accountService from '../services/account';

const DAY = 1;
const HOUR = DAY / 24;
const MINUTE = HOUR / 60;

const getAccessTokenFromCookies = () => Cookies.get('accessToken');

const setAccessTokenToCookies = accessToken =>
  Cookies.set('accessToken', accessToken, { expires: 1 * MINUTE });

const removeAccessTokenFromCookies = () => Cookies.remove('accessToken');


const getRefreshTokenFromCookies = () => Cookies.get('refreshToken');

const setRefreshTokenToCookies = refreshToken =>
  Cookies.set('refreshToken', refreshToken, { expires: DAY });

const removeRefreshTokenFromCookies = () => Cookies.remove('refreshToken');

const setAccessTokenToAxios = accessToken =>
  (axios.defaults.headers.Authorization = `Bearer ${accessToken}`);

const removeAccessTokenFromAxios = () =>
  (axios.defaults.headers.Authorization = '');

const getUserRoleFromCookies = () => Cookies.get('userRole');

const setUserRoleToCookies = userRole => Cookies.set('userRole', userRole);

const removeUserRoleFromCookies = () => Cookies.remove('userRole');

const removeAllTokens = () => {
  removeAccessTokenFromAxios();
  removeAccessTokenFromCookies();
  removeRefreshTokenFromCookies();
  removeUserRoleFromCookies();
};

const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    response => response, // on success
    error => {
      const accessToken = getAccessTokenFromCookies();
      const refreshToken = getRefreshTokenFromCookies();
      const userRole = getUserRoleFromCookies();

      const logInLink = `/${userRole}/login`;  

      if (accessToken) {
        return Promise.reject(error);
      }

      if (!refreshToken) {
        removeAllTokens();
        history.push(logInLink);
        return Promise.reject(error);
      }

      axios.interceptors.response.eject(interceptor);

      return accountService
        .updateToken(refreshToken)
        .then(({ access: newAccessToken }) => {
          setAccessTokenToCookies(newAccessToken);
          error.response.config.headers['Authorization'] = 'Bearer ' + newAccessToken;
          return axios(error.response.config);
        })
        .catch(err => {
          if (!getAccessTokenFromCookies()) {
            removeAllTokens();
            history.push(logInLink);  
          }

          return Promise.reject(err);
        })
        .finally(createAxiosResponseInterceptor)
    }
  )
}

const getCurrentUser = async ({ history, setCurrentUser, role, onSuccess }) => {
  const logInLink = `/${role}/login`;

  const userRole = getUserRoleFromCookies();

  if (userRole !== role) return history.push(logInLink);

  const accessToken = getAccessTokenFromCookies();
  const refreshToken = getRefreshTokenFromCookies();

  if (!accessToken) {
    if (refreshToken) {
      const { access: newAccessToken } = await accountService.updateToken(refreshToken);

      setAccessTokenToCookies(newAccessToken);
      setAccessTokenToAxios(newAccessToken);
    } else {
      removeAllTokens();
      return history.push(logInLink);
    }
  }

  try {
    const user = await accountService.getCurrentUser();

    setCurrentUser({ ...user, role });

    onSuccess();
  } catch (err) {
    removeAllTokens();

    history.push(logInLink);
  }
}

export {
  getAccessTokenFromCookies,
  setAccessTokenToCookies,
  removeAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  setRefreshTokenToCookies,
  removeRefreshTokenFromCookies,
  setAccessTokenToAxios,
  removeAccessTokenFromAxios,
  removeUserRoleFromCookies,
  getUserRoleFromCookies,
  setUserRoleToCookies,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor
};

export default {
  getAccessTokenFromCookies,
  setAccessTokenToCookies,
  removeAccessTokenFromCookies,
  getRefreshTokenFromCookies,
  setRefreshTokenToCookies,
  removeRefreshTokenFromCookies,
  setAccessTokenToAxios,
  removeAccessTokenFromAxios,
  removeUserRoleFromCookies,
  getUserRoleFromCookies,
  setUserRoleToCookies,
  removeAllTokens,
  getCurrentUser,
  createAxiosResponseInterceptor
};
