import React from 'react'
import icon from '../../../../images/thank-you.png'
import wifiIcon from '../../../../images/wifi.svg'
import Button from '../../Button'
import './index.scss'

class ErrorPopup extends React.Component {
  render() {
    const { click, err } = this.props
    return (
      <div className="error-popup-container">
        <h1 className="error-popup-title">Something went wrong</h1>

        <p className="error-popup-subtitle">{err ? err : 'Please check your internet connection, and try again later!'}</p>

        <div className="icons">
          <img className="bg-icon" src={icon} alt="icon" />
          <img className="wifi-icon" src={wifiIcon} alt="wifi-icon" />
        </div>

        <Button onClick={click}>TRY AGAIN</Button>

      </div>
    )
  }
}

export default ErrorPopup