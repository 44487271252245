import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import { Switch, Route, Redirect } from 'react-router-dom';

import Sidebar from '../../components/common/Sidebar';
import Loader from '../../components/common/Loader';
import Terms from '../../components/common/Terms';

import Dashboard from './Dashboard';
import Account from './Account';
import Notifications from './Notifications';
import Help from './Help';
import Reservation from '../../components/common/Reservation';

import DashboardIcon from '../../images/ic_Dashboard.svg';
import AccountIcon from '../../images/ic_account.svg';
import NotificationsIcon from '../../images/ic_notificstion.svg';
import TermsIcon from '../../images/ic_termsofuse.svg';
import HelpIcon from '../../images/ic_help.svg';

import { getCurrentUser } from '../../helpers/auth';

import accountActions from '../../redux/actions/account';

import './index.scss';

const tabs = [
  {
    label: 'Dashboard',
    link: '/pilot/dashboard',
    icon: DashboardIcon
  },
  {
    label: 'My Account',
    link: '/pilot/account',
    icon: AccountIcon
  },
  {
    label: 'Notifications',
    link: '/pilot/notifications',
    icon: NotificationsIcon
  },
  {
    label: 'Terms',
    link: '/pilot/terms',
    icon: TermsIcon
  },
  {
    label: 'Help',
    link: '/pilot/help',
    icon: HelpIcon
  }
];

class Pilot extends Component {
  state = {
    isCurrentUserLoading: true
  };

  componentDidMount() {
    const {
      props: {
        history,
        setCurrentUser
      }
    } = this;

    getCurrentUser({
      history,
      setCurrentUser,
      role: 'pilot',
      onSuccess: () => this.setState({ isCurrentUserLoading: false })
    });
  }

  render() {
    const {
      props: {
        currentUser,
        isMainContentBlurred
      },
      state: {
        isCurrentUserLoading
      }
    } = this;


    if (isCurrentUserLoading) return <Loader />;

    return currentUser && currentUser.role === 'pilot' && (
      <div className="pilot-container">
        <Sidebar
          tabs={tabs}
          reserveLink="/pilot/reserve"
          logInLink="/pilot/login"
        />

        <div
          className={
            classnames({
              'pilot-main-content': true,
              blur: !!isMainContentBlurred
            })
          }
        >
          <Switch>
            <Route path="/pilot/dashboard" component={Dashboard} />
            <Route path="/pilot/account" component={Account} />
            <Route path="/pilot/notifications" component={Notifications} />
            <Route path="/pilot/terms" component={Terms} />
            <Route path="/pilot/help" component={Help} />
            <Route path="/pilot/reserve" component={Reservation} />
            <Redirect to="/pilot/login" />
          </Switch>
        </div>
      </div>
    )
  }
}

Pilot.propTypes = {
  currentUser: shape({
    role: string
  }).isRequired,
  isMainContentBlurred: bool.isRequired,
  setCurrentUser: func
};

Pilot.defaultProps = {
  setCurrentUser: () => false
}

const mapStateToProps = ({
  account: { currentUser },
  modal: { isMainContentBlurred }
}) => ({
  currentUser,
  isMainContentBlurred
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setCurrentUser: accountActions.setCurrentUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Pilot);
