import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import arrowDown from '../../../images/chevron-down.svg'

import { getUserRoleFromCookies } from '../../../helpers/auth';

import classnames from 'classnames'
import './index.scss'


class Multiselect extends React.Component {
  state = {
    showItems: false,
    selectedItems: [],
    userRole: null
  }

  componentDidMount() {
    const userRole = getUserRoleFromCookies()

    console.log(userRole)
    this.setState({ userRole })
  }

  toggleDropdownMenu = () => {
    this.setState({ showItems: !this.state.showItems })
  }

  selectItem = async (item) => {
    const { selectedItems } = this.state

    const index = selectedItems.findIndex(i => i === item.value)

    let updatedSelectedItems;
    if (index !== -1) {
      updatedSelectedItems = selectedItems.filter((e, i) => i !== index)
    } else {
      updatedSelectedItems = [...selectedItems, item.value]
    }
    this.setState({ selectedItems: updatedSelectedItems });
    this.props.onTypeChange(updatedSelectedItems);
    this.props.onStatusChange(updatedSelectedItems);
    this.toggleDropdownMenu();
  }

  render() {
    const { userRole } = this.state

    return (
      <div className="multiselect"  >
        <div className="multiselect-header" onClick={this.toggleDropdownMenu}>
          <div className="arrow" >
            <img src={arrowDown} alt="arrowDown" />
          </div>
          <div className="selected" ><span className="selected-text">{this.props.selectedItem}</span> </div>
        </div>


        {this.state.showItems && (
          <ul className="multiselect-content">
            {this.props.items.map(item => {

              if (userRole !== null && userRole === 'pilot' && item.value === 'larsa-op-9') {
                return false
              }

              return (<li key={item.label}
                onClick={() => this.selectItem(item)}
                className="multiselect-item"><span className={classnames({ "multiselect-item-text": true, 'selected-item': this.state.selectedItems.includes(item.value) })}>{item.label}</span></li>)
            })}
          </ul>
        )
        }
      </div>
    );
  }
}

Multiselect.propTypes = {
  items: arrayOf(shape({
    value: string,
    label: string
  })),
  selectedItem: string,
  onTypeChange: func,
  onStatusChange: func
};

Multiselect.defaultProps = {
  items: [],
  selectedItem: null,
  onTypeChange: () => false,
  onStatusChange: () => false
};

export default Multiselect;
