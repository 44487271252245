const TYPES = {
  vfr_arrival: 'vfr_arrival',
  vfr_departure: 'vfr_departure',
  vfr_circuit: 'vfr_circuit',
  ifr_arrival: 'ifr_arrival',
  ifr_departure: 'ifr_departure',
  ifr_training: 'ifr_training',
  fato: 'fato',
  helex: 'helex',
  maintenance: 'maintenance',
};

const IDS = {
  vfr_arrival: 'larsa-op-1',
  vfr_departure: 'larsa-op-2',
  vfr_circuit: 'larsa-op-3',
  ifr_arrival: 'larsa-op-4',
  ifr_departure: 'larsa-op-5',
  ifr_training: 'larsa-op-6',
  fato: 'larsa-op-7',
  helex: 'larsa-op-8',
  maintenance: 'larsa-op-9',
};

const reservationTypes = {
  'larsa-op-1': 'VFR ARRIVAL',
  'larsa-op-2': 'VFR DEPARTURE',
  'larsa-op-3': 'VFR CIRCUIT',
  'larsa-op-4': 'IFR ARRIVAL',
  'larsa-op-5': 'IFR DEPARTURE',
  'larsa-op-6': 'IFR TRAINING',
  'larsa-op-7': 'FATO',
  'larsa-op-8': 'HELEX',
  'larsa-op-9': 'MAINTENANCE',
};

const closeOperations = {
  close_runway: 'Close Runway',
  close_helex: 'Close HELEX',
  close_fato: 'Close FATO',
};

const closeTitlesTabs = {
  close_runway: 'RUNWAY',
  close_helex: 'HELEX',
  close_fato: 'FATO',
};

const reservationTypesHandler = {
  get: function(target, prop) {
    return target.hasOwnProperty(prop) ? target[prop] : 'UKNOWN TYPE';
  },
};

const RESERVATIONS_TYPES = new Proxy(reservationTypes, reservationTypesHandler);

const getLandingAreaId = type => {
  switch (type) {
    case TYPES.fato:
      return 'Lelystad-2';
    case TYPES.helex:
      return 'Lelystad-3';
    default:
      return 'Lelystad-1';
  }
};

export {
  RESERVATIONS_TYPES,
  TYPES,
  IDS,
  getLandingAreaId,
  closeOperations,
  closeTitlesTabs,
  reservationTypes,
};

export default {
  RESERVATIONS_TYPES,
  TYPES,
  IDS,
  getLandingAreaId,
  closeOperations,
  closeTitlesTabs,
  reservationTypes,
};
