import React, { Component } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import { Switch, Route, Redirect } from 'react-router-dom';

import Reservation from '../../components/common/Reservation';
import Sidebar from '../../components/common/Sidebar';
import Loader from '../../components/common/Loader';
import Terms from '../../components/common/Terms';

import Dashboard from './Dashboard';
import Notifications from './Notifications';
import Operations from './Operations';
import Users from './Users';

import { getCurrentUser } from '../../helpers/auth';

import accountActions from '../../redux/actions/account';

import DashboardIcon from '../../images/ic_Dashboard.svg';
import NotificationsIcon from '../../images/ic_notificstion.svg';
import OperationsIcon from '../../images/ic_operations.svg';
import TermsIcon from '../../images/ic_termsofuse.svg';
import UsersIcon from '../../images/ic_users.svg';

import './index.scss';

const tabs = [
  {
    label: 'Dashboard',
    link: '/airport/dashboard',
    icon: DashboardIcon
  },
  {
    label: 'Operations',
    link: '/airport/operations',
    icon: OperationsIcon
  },
  {
    label: 'Notifications',
    link: '/airport/notifications',
    icon: NotificationsIcon
  },
  {
    label: 'Users',
    link: '/airport/users',
    icon: UsersIcon
  },
  {
    label: 'Terms',
    link: '/airport/terms',
    icon: TermsIcon
  }
];

class Airport extends Component {
  state = {
    isCurrentUserLoading: true
  };

  componentDidMount() {
    const {
      props: {
        history,
        setCurrentUser
      }
    } = this;

    getCurrentUser({
      history,
      setCurrentUser,
      role: 'airport',
      onSuccess: () => this.setState({ isCurrentUserLoading: false })
    });
  }

  render() {
    const {
      props: {
        currentUser,
        isMainContentBlurred
      },
      state: {
        isCurrentUserLoading
      }
    } = this;

    if (isCurrentUserLoading) return <Loader />;

    return currentUser && currentUser.role === 'airport' && (
      <div className="airport-container">
        <Sidebar
          tabs={tabs}
          role="airport user"
          reserveLink="/airport/reserve"
          logInLink="/airport/login"
        />

        <div
          className={
            classnames({
              'main-airport-content': true,
              blur: !!isMainContentBlurred
            })
          }
        >
          <Switch>
            <Route path="/airport/reserve" component={Reservation} />
            <Route path="/airport/dashboard" component={Dashboard} />
            <Route path="/airport/notifications" component={Notifications} />
            <Route path="/airport/operations" component={Operations} />
            <Route path="/airport/terms" component={Terms} />
            <Route path="/airport/users" component={Users} />
            <Redirect to="/airport/login" />
          </Switch>
        </div>
      </div>
    );
  }
}

Airport.propTypes = {
  history: shape({}),
  currentUser: shape({
    role: string
  }).isRequired,
  isMainContentBlurred: bool.isRequired,
  setCurrentUser: func
}

const mapStateToProps = ({
  account: { currentUser },
  modal: { isMainContentBlurred }
}) => ({
  currentUser,
  isMainContentBlurred
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setCurrentUser: accountActions.setCurrentUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Airport);

