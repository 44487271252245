import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Link } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';

import ErrorPopup from '../../../components/common/modals/ErrorPopup'
import Modal from '../../../components/common/Modal'
import BackWayModal from '../modals/BackWayModal';

import Button from '../Button';
import Operation from './Operation';
import ChosenDay from './ChosenDay';
import ChoseTime from './ChoseTime';
import ReservationInfo from './ReservationInfo'
import ThankYou from '../../../components/common/ThankYou';

import reservationActions from '../../../redux/actions/reservation';
import reservationService from '../../../services/reservations';

import { TYPES, IDS, getLandingAreaId, closeTitlesTabs, RESERVATIONS_TYPES } from '../../../helpers/reservations';

import { hoursToSeconds } from '../../../helpers/time'

import './index.scss';

const STEPS = [1, 2, 3, 4];

class Reservation extends Component {
  state = {
    isConfirmModalShow: false,
    err: null,
    isAskModalShow: false,
    backWay: false
  }

  componentDidMount() {
    const { activeStep, dropActiveStep } = this.props

    if (activeStep === 5) dropActiveStep()
  }


  getStepContent = (stepIndex, overlapDisabled) => {
    switch (stepIndex) {
      case 1:
        return <Operation />;
      case 2:
        return <ChosenDay />;
      case 3:
        return <ChoseTime overlapDisabled={overlapDisabled} />;
      case 4:
        return <ReservationInfo />;
      default:
        return 'Uknown stepIndex';
    }
  }

  handleNextStep = async () => {
    const {
      state: {
        backWay
      },
      props: {
        activeStep,
        selectedOperation,
        selectedDay,
        selectedTime,
        selectedCloseTime,
        numberOfTouchAndGo,
        closeRunway,
        closeWholeDayVal,
        closeDuration,
        customDuration,
        utilization,
        selectedAreas,
        description,
        nextStep,
        rescheduleReservationId
      }
    } = this;

    if (activeStep === 4) {
      this.closeModal()

      let result;

      if (!closeWholeDayVal) {
        let datetime = `${moment(selectedDay).format('YYYY-MM-DD')} ${selectedTime}`;

        if (!closeRunway) {
          const withCustomDuration = [TYPES.helex, TYPES.fato].includes(selectedOperation.id);
          const withNr_repeats = ['larsa-op-3', 'larsa-op-6'].includes(IDS[selectedOperation.id])
          const withDuration = ['larsa-op-7', 'larsa-op-8', 'larsa-op-9'].includes(IDS[selectedOperation.id])

          result = await reservationService.createReservation({
            rescheduleReservationId: rescheduleReservationId !== null ? rescheduleReservationId : undefined,
            landingAreaId: getLandingAreaId(selectedOperation.id),
            operationId: selectedOperation.id,
            datetime,
            duration: withDuration ? (withCustomDuration ? customDuration * 60 : utilization.operation_duration) : undefined,
            numberOfTouchAndGo: withNr_repeats ? numberOfTouchAndGo : undefined,
            description
          });
        } else {
          for (const a of selectedAreas) {
            datetime = `${moment(selectedDay).format('YYYY-MM-DD')} ${selectedCloseTime[closeTitlesTabs[a]]}`

            result = await reservationService.createReservation({
              landingAreaId: a !== 'close_runway' ? (a === 'close_helex' ? getLandingAreaId('helex') : getLandingAreaId('fato')) : getLandingAreaId(a),
              operationId: 'maintenance',
              datetime,
              duration: hoursToSeconds(closeDuration[closeTitlesTabs[a]]),
              description
            });
          }
        }
      } else {
        let landingAreaIds = selectedAreas.map(area => {
          if (area === 'close_helex') return getLandingAreaId('helex')
          if (area === 'close_fato') return getLandingAreaId('fato');

          return getLandingAreaId(area);
        });

        result = await reservationService.closeLandingAreas({ landingAreaIds, date: moment(selectedDay).format('YYYY-MM-DD') })
      }

      if (result.data.status === 'ok') {
        if (rescheduleReservationId === null && !closeRunway && !['vfr_circuit', 'ifr_training', 'maintenance'].includes(selectedOperation.id) && !backWay) {
          this.setState({ isAskModalShow: true })
          return
        }
        nextStep();
      } else {
        this.setState({ isConfirmModalShow: true, err: result.data.error })
      }

    } else {
      nextStep();
    }
  }

  closeModal = () => {
    this.setState({ isConfirmModalShow: false, err: null })
  }

  handleBackWay = (id) => {
    const { setActiveStep, setSelectedOperation } = this.props

    const operation = {
      id: id,
      text: RESERVATIONS_TYPES[IDS[id]]
    }

    setSelectedOperation(operation)
    setActiveStep(2)
    this.setState({ isAskModalShow: false, backWay: true })
  }

  closeBackWay = () => {
    const { nextStep } = this.props

    this.setState({ isAskModalShow: false }, nextStep)
  }

  render() {
    let {
      getStepContent,
      handleNextStep,
      closeModal,
      closeBackWay,
      handleBackWay,
      props: {
        activeStep,
        selectedOperation,
        customDuration,
        isClosedDaysLoading,
        closeRunway,
        selectedAreas,
        selectedCloseTime,
        closeDuration,
        selectedTime,
        selectedDay,
        selectedCloseOperationTab,
        closedDays,
        utilization,
        isUtilizationLoading,
      },
      state: {
        isConfirmModalShow,
        err,
        isAskModalShow,
      }
    } = this;


    const withCustomDuration = selectedOperation !== null && [TYPES.helex, TYPES.fato].includes(selectedOperation.id);

    const isCustomDurationCorrect = customDuration >= 5 && customDuration <= 120 && customDuration % 5 === 0;

    let isNextDisabled = !selectedOperation && !selectedAreas.length;

    if (withCustomDuration && !isCustomDurationCorrect) isNextDisabled = true;

    if (isClosedDaysLoading || isUtilizationLoading) isNextDisabled = true;

    let overlapDisabled = false;

    if (activeStep === 3 && utilization.maintenance_times && utilization.maintenance_times.length > 0) {
      const withCustomDuration = selectedOperation !== null && [TYPES.helex, TYPES.fato].includes(selectedOperation.id);

      let duration = withCustomDuration ? customDuration : Math.ceil(utilization.operation_duration / 60)

      if (closeRunway) {
        selectedTime = selectedCloseTime[selectedCloseOperationTab.title]

        duration = hoursToSeconds(closeDuration[selectedCloseOperationTab.title]) / 60
      }

      let [hours, mins] = selectedTime.slice(0, 5).split(':')

      mins = parseInt(mins) + (parseInt(hours) * 60)

      let endReservationMinutes = parseInt(mins) + parseInt(duration)

      let endHours = Math.floor(endReservationMinutes / 60);
      let endMinutes = endReservationMinutes % 60;

      let endReservationTime = moment().hours(endHours).minutes(endMinutes).format('kk:mm')

      const disabledTimesArray = [...utilization.maintenance_times.sort((a, b) => {
        let [aHours, aMins] = moment(a.from).format('kk:mm').slice(0, 5).split(':')
        const aTime = parseInt(aMins) + (parseInt(aHours) * 60)
        let [bHours, bMins] = moment(b.from).format('kk:mm').slice(0, 5).split(':')
        const bTime = parseInt(bMins) + (parseInt(bHours) * 60)
        return aTime - bTime
      })]

      const nearDisabledTime = disabledTimesArray.find(t => {
        const startTime = moment(t.from).utc().format('kk:mm')
        const endTime = moment(t.to).utc().format('kk:mm')

        if (moment(startTime, 'HH:mm').isAfter(moment(selectedTime, 'HH:mm')) || moment(selectedTime, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm')) || moment(selectedTime, 'HH:mm').isSame(moment(startTime, 'HH:mm'))) return t

        return undefined
      }
      )

      if (nearDisabledTime !== undefined) {
        const startDisabledTime = moment(nearDisabledTime.from).utc().format('kk:mm')
        const endDisabledTime = moment(nearDisabledTime.to).utc().format('kk:mm')

        console.log(startDisabledTime, selectedTime, endDisabledTime, endReservationTime)

        if (moment(selectedTime, 'HH:mm').isBetween(moment(startDisabledTime, 'HH:mm'), moment(endDisabledTime, 'HH:mm')) || moment(endReservationTime, 'HH:mm').isBetween(moment(startDisabledTime, 'HH:mm'), moment(endDisabledTime, 'HH:mm'))) {
          isNextDisabled = true
          overlapDisabled = true
          console.log('start time between closed')
        }
        if (moment(selectedTime, 'HH:mm').isBefore(moment(startDisabledTime, 'HH:mm')) && moment(endReservationTime, 'HH:mm').isAfter(moment(endDisabledTime, 'HH:mm'))) {
          isNextDisabled = true
          overlapDisabled = true
          console.log('start time overlap closed')

        }
        if (moment(selectedTime, 'HH:mm').isSame(moment(startDisabledTime, 'HH:mm')) || moment(endReservationTime, 'HH:mm').isSame(moment(endDisabledTime, 'HH:mm'))) {
          isNextDisabled = true
          overlapDisabled = true
          console.log('start time is same closed')

        }
      }
    }

    if (selectedOperation !== null) {
      closedDays.forEach(d => {
        if (getLandingAreaId(selectedOperation.id) === d.landing_area_id && moment(d.date).isSame(moment(selectedDay).format('YYYY-MM-DD'))) {
          isNextDisabled = true
        }
      })
    }
    return (
      <Grid container className="reserve-container">
        <Grid item xs={12}>
          <Stepper>
            {
              STEPS.map(step => (
                <Step
                  key={step}
                  active={step <= activeStep}
                  completed={false}
                  disabled={false}
                >
                  <StepLabel icon={step} />
                </Step>
              ))
            }
          </Stepper>
        </Grid>

        {isConfirmModalShow && (

          <Modal
            withCloseBtn={false}
            onClose={() => closeModal()}
          >
            <div className="popup-wrapper" >
              <ErrorPopup err={err} click={() => handleNextStep()} />
            </div>
          </Modal>

        )
        }

        {!closeRunway && isAskModalShow && (
          <BackWayModal className="popup" operationID={selectedOperation.id} onReject={closeBackWay} onResolve={(id) => handleBackWay(id)} />
        )}

        <Grid item xs={12} className="main-content">
          {
            activeStep === STEPS.length + 1 ? (
              <div className="reservation-back-dashboard-block">
                <ThankYou
                  title='THANK YOU!'
                  subtitle='Successful reservation'
                  text='Please start the operation within 5 minutes of the indicated time.'
                />

                <Link to="dashboard">
                  <Button>BACK TO DASHBOARD</Button>
                </Link>
              </div>
            ) : (
                <div>
                  {getStepContent(activeStep, overlapDisabled)}

                  <div className="reserve-next-btn">
                    <Button
                      disabled={isNextDisabled}
                      variant="contained"
                      color="primary"
                      onClick={() => handleNextStep()}
                    >
                      {activeStep === STEPS.length ? 'RESERVE' : 'NEXT'}
                    </Button>
                  </div>
                </div>
              )}
        </Grid>
      </Grid>
    )
  }
};

const mapStateToProps = ({
  reservation: {
    activeStep,
    selectedOperation,
    customDuration,
    isClosedDaysLoading,
    closeWholeDayVal,
    selectedDay,
    selectedTime,
    selectedCloseTime,
    selectedCloseOperationTab,
    closedDays,
    numberOfTouchAndGo,
    closeDuration,
    closeRunway,
    utilization,
    description,
    selectedAreas,
    isUtilizationLoading,
    rescheduleReservationId
  }
}) => ({
  activeStep,
  selectedOperation,
  selectedAreas,
  customDuration,
  selectedCloseOperationTab,
  isClosedDaysLoading,
  closeDuration,
  closeWholeDayVal,
  selectedDay,
  closedDays,
  selectedCloseTime,
  selectedTime,
  numberOfTouchAndGo,
  closeRunway,
  utilization,
  description,
  isUtilizationLoading,
  rescheduleReservationId
});

const mapDispatchToProps = dispatch => ({
  nextStep: () => dispatch(reservationActions.nextStep()),
  dropActiveStep: () => dispatch(reservationActions.dropActiveStep()),
  setActiveStep: step => dispatch(reservationActions.setActiveStep(step)),
  setSelectedOperation: operation => dispatch(reservationActions.setSelectedOperation(operation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reservation);
