import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import Button from '../Button';
import Menu from './Menu';

import { removeAllTokens } from '../../../helpers/auth';

import LarsaDummyIcon from '../../../images/larsadummy.png';
import LarsaIcon from '../../../images/larsa.png';
import LarsaLogo from '../../../images/LA_logo.svg';

import './index.scss';

const Sidebar = ({
  role,
  tabs,
  reserveLink,
  logInLink,
  history,
  currentUser
}) => {
  const logOut = () => {
    removeAllTokens();

    history.push(logInLink);
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="top-block">
          {
            (currentUser.first_name && currentUser.last_name) ? (
              <h2 className="username">
                {currentUser.first_name} {currentUser.last_name}
              </h2>
            ) : (
              <h2 className="email">{currentUser.email}</h2>
            )
          }

          {role && (<span className="role">airport user</span>)}

          <div className="icon">
            <img src={LarsaDummyIcon} alt="dummy-icon"/>
          </div>
        </div>

        <Link to={reserveLink}>
          <Button className="reserve-button">
            { currentUser && currentUser.role === "airport" ? 'CLOSE RUNWAY' : 'RESERVE' }
        </Button>
        </Link>

        <Menu tabs={tabs} />

        <Button className="logout-button" onClick={logOut}>LOG OUT</Button>

        <div className="logo">
          <img alt="logo" src={LarsaLogo} />
        </div>

        <p className="presented-header">Lelystad Airport</p>
        <p className="presented-text">presents</p>

        <div className="bottom-block">
          <p className="bottom-block-text">LARSA</p>
          <img src={LarsaIcon} className="bottom-block-image" alt="larsa-icon"/>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  role: string,
  tabs: arrayOf(shape({
    link: string,
    label: string,
    icon: string
  })),
  reserveLink: string.isRequired,
  logInLink: string.isRequired,
  currentUser: shape({
    first_name: string,
    last_name: string,
    email: string
  })
};

Sidebar.defaultProps = {
  role: null,
  tabs: [],
  currentUser: {}
};

const mapStateToProps = ({ account: { currentUser } }) => ({ currentUser });

export default compose(
  connect(mapStateToProps),
  withRouter
)(Sidebar);