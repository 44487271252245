const tToTT = t => {
  let stringT = t.toString();

  if (!stringT.length) {
    return '00';
  }

  if (stringT.length === 1) {
    return `0${stringT}`;
  }

  return stringT;
};

const secondsToMinutes = seconds => {
  const minutes = Math.floor(seconds / 60);

  const leftSeconds = seconds % 60;

  return `${tToTT(minutes)}:${tToTT(leftSeconds)}`;
};

const secondsToHours = seconds => {
  const hours = Math.floor(seconds / 3600);

  const minutes =  Math.floor(seconds / 60) % 60

  return `${tToTT(hours)}:${tToTT(minutes)}`;
};

const hoursToSeconds = hours => {
  const seconds = Math.floor(hours * 3600);

  return `${tToTT(seconds)}`;
};

export { tToTT, secondsToMinutes, hoursToSeconds, secondsToHours };

export default {
  tToTT,
  secondsToMinutes,
  hoursToSeconds,
  secondsToHours
};
