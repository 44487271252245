import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../LogIn'
import AboutUs from '../../../components/common/AboutUs'
import SplashSidebar from '../../../components/SplashSidebar';

import { getUserRoleFromCookies } from '../../../helpers/auth';

import './index.scss'

const tabs = [
  {
    label: 'Log In',
    link: '/airport/login'
  },
  {
    label: 'About Us',
    link: '/airport/about'
  }
];

class AirportSplash extends React.Component {
  state = {
    isFull: true
  };

  componentDidMount() {
    const userRole = getUserRoleFromCookies();

    if (userRole === 'airport') {
      return this.props.history.push('/airport/dashboard');
    }
  }


  closeFull = () =>
    this.setState({ isFull: false });

  render() {
    const {
      closeFull,
      state: {
        isFull
      }
    } = this;

    return (
      <div className="airport-splash">
        <SplashSidebar
          isFull={isFull}
          tabs={tabs}
          closeFull={closeFull}
        />

        {
          !isFull && (
            <Switch>
              <Route path="/airport/login" component={Login} />
              <Route path="/airport/about" component={AboutUs} />
            </Switch>
          )
        }
      </div>
    )
  }
}


export default AirportSplash;
