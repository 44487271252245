import React from 'react';
import { func, number, oneOfType, string } from 'prop-types';

import './index.scss';

const ArrowNumber = ({
  text,
  value,
  readOnly = false,
  min = null,
  max = null,
  decrease,
  increase,
  onChange
}) => (
    <div className="quantity-block">
      {
        !!text && (
          <p className="quantity-block-title">{text}</p>
        )
      }

      <div className="quantity-input">
        <button
          className="quantity-input__modifier quantity-input__modifier--left"
          disabled={min !== null && value <= min}
          onClick={decrease}
        >
          &mdash;
      </button>

        <input
          className="quantity-input__screen"
          type="text"
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />

        <button
          className="quantity-input__modifier quantity-input__modifier--right"
          disabled={max !== null && value >= max}
          onClick={increase}
        >
          &#xff0b;
        </button>
      </div>
    </div>
  );

ArrowNumber.propTypes = {
  text: string,
  value: oneOfType([string, number]).isRequired,
  min: number,
  increase: func,
  decrease: func
};

ArrowNumber.defaultProps = {
  text: null,
  increase: () => false,
  decrease: () => false
}

export default ArrowNumber;