import React from 'react';
import moment from 'moment'

import temperature from '../../../images/thermometer.svg'
import humidity from '../../../images/humidity.svg'
import wind from '../../../images/wind.svg'

import weatherService from '../../../services/weather'
import { ICONS_TYPES } from '../../../helpers/getBackground'
import './index.scss'


let backImage;
let weatherIndicator;
class WeatherBlock extends React.Component {
  state = {
    checked: 'today',
    date: moment().format('dddd MMMM Do YYYY'),
    weather: null,
  }

  componentDidMount() {
    this.getWeather()
  }

  handleChange = (changeEvent) => {
    const newDate = changeEvent.target.value === 'tomorrow' ?
      moment().add('day', 1).format('dddd MMMM Do YYYY')
      : moment().format('dddd MMMM Do YYYY')

    this.setState({ checked: changeEvent.target.value, date: newDate })
    this.getWeather(changeEvent.target.value)
  }

  getWeather = async (day) => {
    const data = await weatherService.getWeather()
    const weather = (day && day === 'tomorrow') ? data[1] : data[0]

    let icon = ICONS_TYPES[weather.description]

    weatherIndicator = `https://openweathermap.org/img/wn/${weather.icon}@2x.png`
    backImage = require(`../../../images/${icon}`)

    this.setState({
      weather
    });
  }

  render() {

    const {
      handleChange,
      state: {
        checked,
        date,
        weather,
      },
    } = this
    return (
      <div className="weather-block" style={{
        backgroundImage: `url(${backImage})`,
        backgroundSize: 'cover'
      }}>
        <div className="weather-daysRadio">
          <div className="days">
            <div className="today">
              <input type="radio" value="today" id="todayRadio" checked={checked === 'today'} onChange={handleChange} />
              <label className="today-str" style={checked === 'today' ? styles.currentDay : styles.unActiveDay} htmlFor="todayRadio">
                TODAY
              </label>
            </div>
            <div className="tomorrow">
              <input type="radio" value="tomorrow" id="tomorrowRadio" checked={checked === 'tomorrow'} onChange={handleChange} />
              <label className="tomorrow-str" style={checked === 'tomorrow' ? styles.currentDay : styles.unActiveDay} htmlFor="tomorrowRadio">
                TOMORROW
               </label>
            </div>
          </div>

        </div>
        <div className="weather-date">{date}</div>
        {weather &&
          <div className="weather-indicators">
            <div className="indicators-content">
              <div className="indicator temperature">
                <img className="indicator-icon" src={temperature} alt="temperature" />
                <p className="indicator-value">{weather.temperature}</p>
              </div>
              <div className="indicator humidity">
                <img src={humidity} className="indicator-icon" style={{ height: 53 }} alt="humidity" />
                <p className="indicator-value">{weather.humidity} </p>
              </div>
              <div className="indicator wind">
                <img src={wind} className="indicator-icon" alt="wind" />
                <p className="indicator-value">{weather.wind} </p>
              </div>
              <div className="indicator weatherIndicator">
                <img className="indicator-icon" src={weatherIndicator} alt="weatherIndicator" />
                <p className="indicator-value">{weather.description}</p>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}

const styles = {
  currentDay: {
    fontFamily: "Montserrat",
    fontWeight: 'bold',
    fontSize: 15
  },
  unActiveDay: {
    fontFamily: "Montserrat",
    fontWeight: 300,
  }
}
export default WeatherBlock;
