import React from 'react';
import moment from 'moment';

import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';
import './index.scss'

const Calendar = ({ selectedDay, disabledDays = [], handleDayClick, className = '' }) => (
  <div>
    <DayPicker
      selectedDays={moment(selectedDay).toDate()}
      disabledDays={[...disabledDays, {
        after: moment().add(3, 'months').toDate(),
        before: moment().toDate()
      }]}
      onDayClick={handleDayClick}
      numberOfMonths={3}
      fromMonth={moment().toDate()}
      toMonth={moment().add(3, 'months').toDate()}
      className={className}
    />
  </div>
);


export default Calendar;
